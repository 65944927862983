import React, { useState } from "react";
import {
  Card,
  Box,
  CardActions,
  CardContent,
  Button,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { makeStyles } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { useDispatch } from "react-redux";
import authApi from "../../services/auth";
import { showLoader, hideLoader } from "../../redux/loader/actions";
import { showSnackbar } from "../../redux/snackbar/actions";
import { setAuth } from "../../redux/common/actions";

import { useFormik } from "formik";
import * as Yup from "yup";
const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#f06666 !important",
  },
  cardRadius: {
    borderRadius: "0 15px 15px 0 !important",
    borderLeft: "3px solid #f06666",
  },
}));

const Settings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showCurrPassword, setShowCurrPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const handleSubmit = (formData) => {
    dispatch(showLoader());
    const data = {
      password: formData?.currpass,
      newPassword: formData?.newpass,
    };
    const onSuccess = (res) => {
      dispatch(hideLoader());
      if (res?.data?.status === true) {
        dispatch(
          showSnackbar({
            message: res?.data?.msg || "Info updated successfully",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
          })
        );
      }
      formik.resetForm();
      setShowCurrPassword(false);
      setShowNewPassword(false);
      setShowConfPassword(false);
    };
    const onFailure = (err) => {
      dispatch(hideLoader());
      dispatch(
        showSnackbar({
          message: err?.response?.data?.msg || "Failed to update data",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        })
      );
    };
    authApi.changePassword(data).then(onSuccess, onFailure);
  };
  const validationSchema = Yup.object({
    currpass: Yup.string().required("Current Password Required"),
    newpass: Yup.string()
      .required("New Password Required")
      .notOneOf(
        [Yup.ref("currpass")],
        "New Password cannot be the same as Current Password"
      )
      .min(8, "Password must be at least 8 characters")
      .max(12, "Password must be at most 12 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,12}$/,
        "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character"
      ),
    confpass: Yup.string()
      .required("Confirm New Password Required")
      .oneOf([Yup.ref("newpass")], "Passwords must match")
      .min(8, "Password must be at least 8 characters")
      .max(12, "Password must be at most 12 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,12}$/,
        "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character"
      ),
  });

  const formik = useFormik({
    initialValues: {
      currpass: "",
      newpass: "",
      confpass: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const toggleCurrPasswordVisibility = () => {
    setShowCurrPassword((prevShowCurrPassword) => !prevShowCurrPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword((prevShowNewPassword) => !prevShowNewPassword);
  };
  const toggleConfPasswordVisibility = () => {
    setShowConfPassword((prevShowConfPassword) => !prevShowConfPassword);
  };

  return (
    <>
      <Card elevation={5} fullWidth className={classes.cardRadius}>
        <CardContent>
          <Grid container alignItems="center" my={2}>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ marginTop: "auto", marginBottom: "auto" }}
            >
              <Typography variant="h5" gutterBottom>
                Change Password
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="currpass"
                label="Current Password"
                name="currpass"
                type={showCurrPassword ? "text" : "password"}
                autoComplete="off"
                value={formik.values.currpass}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.currpass && Boolean(formik.errors.currpass)
                }
                helperText={formik.touched.currpass && formik.errors.currpass}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleCurrPasswordVisibility}
                        edge="end"
                      >
                        <VisibilityOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="newpass"
                label="New Password"
                name="newpass"
                type={showNewPassword ? "text" : "password"}
                autoComplete="off"
                value={formik.values.newpass}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.newpass && Boolean(formik.errors.newpass)}
                helperText={formik.touched.newpass && formik.errors.newpass}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleNewPasswordVisibility}
                        edge="end"
                      >
                        <VisibilityOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="confpass"
                label="Confirm New Password"
                name="confpass"
                type={showConfPassword ? "text" : "password"}
                autoComplete="off"
                value={formik.values.confpass}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.confpass && Boolean(formik.errors.confpass)
                }
                helperText={formik.touched.confpass && formik.errors.confpass}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleConfPasswordVisibility}
                        edge="end"
                      >
                        <VisibilityOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginRight: "10px",
            }}
          >
            <Button
              variant="contained"
              size="medium"
              className={classes.btn}
              onClick={formik.handleSubmit}
            >
              <DoneOutlinedIcon />
              Submit
            </Button>
          </Box>
        </CardActions>
      </Card>
    </>
  );
};

export default Settings;
