import axios from "axios";
import { API_URL } from "../../constants";
import moment from "moment";
import _ from "lodash";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // getAllInvoice: (
  //   page,
  //   pageSize,
  //   sortBy,
  //   sort,
  //   startDate,
  //   endDate,
  //   datetax
  // ) => {
  //   return axios.get(API_URL.INVOICE_GETALL, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       isAuthRequired: true,
  //     },
  //     params: {
  //       invoice_generated: true,
  //       pagination: true,
  //       page: page,
  //       pageSize: pageSize,
  //       sortBy: sortBy,
  //       sortOrder: sort,
  //       startDate: moment(startDate).format("YYYY-MM-DD"),
  //       endDate: moment(endDate).format("YYYY-MM-DD"),
  //       date_filter: datetax,
  //     },
  //   });
  // },
  getAllInvoiceExport: (
    cancelled,
    searchParamsbill,
    rowCountbill,
    startDatebil,
    endDatebil
  ) => {
    // Function to check if a date is valid
    const isValidDate = (date) => {
      return date instanceof Date && !isNaN(date.getTime());
    };

    // Filter out null, false, or empty string values from searchParamstax
    const filteredParams = _.pickBy(
      searchParamsbill,
      (value) => !_.isNil(value) && value !== false && value !== ""
    );

    // Create base params object
    const params = {
      filter: JSON.stringify(filteredParams),
      pagination: true,
      pageSize: rowCountbill,
      cancelled,
      date_filter: true,
    };

    // Conditionally add startDate and endDate to params if they are valid dates
    if (isValidDate(new Date(startDatebil))) {
      params.startDate = startDatebil;
    }
    if (isValidDate(new Date(endDatebil))) {
      params.endDate = endDatebil;
    }

    // Return the axios request
    return axios.get(API_URL.INVOICE_GETALL, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
      params,
    });
  },
  getAllInvoiceExports: (
    cancelled,
    searchParamstax,
    rowCounttax,
    startDatetax,
    endDatetax
  ) => {
    // Function to check if a date is valid
    const isValidDate = (date) => {
      return date instanceof Date && !isNaN(date.getTime());
    };

    // Filter out null, false, or empty string values from searchParamstax
    const filteredParams = _.pickBy(
      searchParamstax,
      (value) => !_.isNil(value) && value !== false && value !== ""
    );

    // Create base params object
    const params = {
      filter: JSON.stringify(filteredParams),
      pagination: true,
      pageSize: rowCounttax,
      cancelled,
      date_filter: true,
    };

    // Conditionally add startDate and endDate to params if they are valid dates
    if (isValidDate(new Date(startDatetax))) {
      params.startDate = startDatetax;
    }
    if (isValidDate(new Date(endDatetax))) {
      params.endDate = endDatetax;
    }

    // Return the axios request
    return axios.get(API_URL.INVOICE_GETALL, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
      params,
    });
  },

  getAllInvoice: (
    params,
    startDate,
    endDate,
    date_filter,
    searchParamsbill,
    searchParamstax,
    cancelled
  ) => {
    const filteredParams = _.pickBy(
      searchParamsbill,
      (value) => !_.isNil(value) && value !== false && value !== ""
    );
    const filteredParamstax = _.pickBy(
      searchParamstax,
      (value) => !_.isNil(value) && value !== false && value !== ""
    );
    // 1console.log(searchParamsbill);
    return axios.get(API_URL.INVOICE_GETALL, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
      params: {
        ...params,
        startDate,
        endDate,
        date_filter,
        filter:
          JSON.stringify(filteredParams) || JSON.stringify(filteredParamstax),
        cancelled,
      },
    });
  },
  invoiceCreate: (data) => {
    return axios.post(API_URL.INVOICE_CREATE, data, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
    });
  },
  getInvoice: (id) => {
    return axios.patch(`${API_URL.INVOICE_GET}/${id}`, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
    });
  },
  generatedInvoice: (id) => {
    return axios.post(
      API_URL.INVOICE_GENERATED,
      {},
      {
        headers: {
          "Content-Type": "multipart/form-data",
          isAuthRequired: true,
        },
        params: {
          order_id: id,
        },
      }
    );
  },
  generatedInvoiceCopy: (id) => {
    return axios.get(`${API_URL.INVOICE_GENERATED_COPY}/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};
