import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  IconButton,
  InputAdornment,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useFormik } from "formik";
import * as yup from "yup";
import LoginBanner from "../../assets/img/loginBanner.png";
import { makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/img/logo1.png";
import Icon from "../../assets/img/logo.png";
import Cookies from "js-cookie";
import authApi from "../../services/auth";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../../redux/loader/actions";
import { showSnackbar } from "../../redux/snackbar/actions";
import { setAuth } from "../../redux/common/actions";
import Bannerone from "../../assets/img/banner1.png";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  bannerImage: {
    backgroundSize: "cover",
    backgroundPosition: "right",
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${Bannerone})`,
      backgroundPosition: "left",
      backgroundSize: "cover",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
}));

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="" sx={{ textDecoration: "none" }}>
        Lakshitha The Designer Studio
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const SignIn = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = (values) => {
    dispatch(showLoader());
    const onSuccess = (res) => {
      dispatch(hideLoader());
      // 1console.log("data", res?.data);
      if (res?.data?.status === true) {
        dispatch(
          showSnackbar({
            message: res?.data?.msg || "Info updated successfully",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
          })
        );
        dispatch(
          setAuth({
            isAuthenticated: true,
            token: res?.data?.token,
          })
        );
        Cookies.set("token", res?.data?.token, { expires: 7 });
        Cookies.set("shop_id", res?.data?.userRole?.shop_id, { expires: 7 });
        Cookies.set("created_by", res?.data?.userRole?.created_by, {
          expires: 7,
        });
        if (values.remember_me) {
          Cookies.set("email", values.email, { expires: 30 });
          Cookies.set("password", values.password, { expires: 30 });
          Cookies.set("token", res?.data?.token, { expires: 30 });
          Cookies.set("shop_id", res?.data?.userRole?.shop_id, { expires: 30 });
          Cookies.set("created_by", res?.data?.userRole?.created_by, {
            expires: 30,
          });
        }
        formik.resetForm();
        navigate("/home");
      }
    };
    const onFailure = (err) => {
      dispatch(hideLoader());
      dispatch(
        showSnackbar({
          message: err?.response?.data?.msg || "Invalid Credentials",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        })
      );
      Cookies.remove("email");
      Cookies.remove("password");
      Cookies.remove("shop_id");
      formik.resetForm();
    };
    authApi.login(values).then(onSuccess).catch(onFailure);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember_me: false,
      role: "admin",
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      email: yup.string().email("Invalid email").required("Email required"),
      password: yup
        .string()
        .matches(/^\S*$/, "Password must not contain spaces")
        .required("Required")
        .min(6, "Password must be at least 6 characters"),
    }),
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    const savedEmail = Cookies.get("email");
    const savedPassword = Cookies.get("password");
    const savedShopId = Cookies.get("shop_id");
    const savedCreatedBy = Cookies.get("created_by");

    if (savedEmail) {
      formik.setFieldValue("email", savedEmail);
    }
    if (savedPassword) {
      formik.setFieldValue("password", savedPassword);
    }
    if (savedShopId) {
      formik.setFieldValue("shop_id", savedShopId);
    }
    if (savedCreatedBy) {
      formik.setFieldValue("created_by", savedCreatedBy);
    }

    formik.setFieldValue(
      "remember_me",
      !!(savedEmail && savedPassword && savedShopId && savedCreatedBy)
    );
  }, []);

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid item xs={false} sm={null} md={8} className={classes.bannerImage} />
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        pt={5}
        component={Paper}
        elevation={6}
        square
      >
        <Box
          sx={{
            my: 10,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={Icon}
            alt="logo"
            sx={{
              my: 2,
              width: "35px",
              height: "auto",
              maxWidth: "100%",
            }}
          />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              type="text"
              autoComplete="off"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              type={showPassword ? "text" : "password"}
              autoComplete="off"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      <VisibilityOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  name="remember_me"
                  checked={formik.values.remember_me}
                  onChange={formik.handleChange}
                />
              }
              label="Remember Me"
            />
            <Button
              onClick={formik.handleSubmit}
              type="submit"
              fullWidth
              variant="contained"
              className={classes.btn}
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#192c54",
                "&:hover": {
                  backgroundColor: "#0e1c3a",
                },
              }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  href="/reset"
                  variant="body2"
                  sx={{ textDecoration: "none", color: "#f06666" }}
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 10 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SignIn;
