import axios from "axios";
import _ from "lodash";
import Cookies from "js-cookie";
import { showSnackbar } from "../../redux/snackbar/actions";

function bindPath(url, pathVal) {
  var newUrl = url;
  var pathExpression = /:[a-z0-9]+/gi;
  var pathVar;
  while (((pathVar = pathExpression.exec(url)), pathVar != null)) {
    let pathVarName = pathVar[0];
    newUrl = newUrl.replace(
      pathVarName,
      pathVal[pathVarName.substring(1, pathVarName.length)]
    );
  }
  return newUrl;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  setupInterceptors: (store) => {
    axios.interceptors.request.use(
      function (config) {
        if (config.headers.isAuthRequired) {
          const token =
            typeof window !== "undefined"
              ? Cookies.get("token")
              : store.getState()?.AuthReducer?.token;
          if (token) config.headers.Authorization = `Bearer ${token}`;
        }

        delete config.headers.isAuthRequired;
        delete config.headers.authKey;

        if (config.headers.path) {
          try {
            config.url = bindPath(config.url, config.headers.path);
          } catch (e) {
            console.log("ERROR OCCURED WHEN REPLACING PATH VARIABLES", e);
          }
        }

        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (
          !axios.isCancel(error) &&
          (_.get(error, "response.status", "") === 401 ||
            _.get(error, "response.status", "") === 403)
        ) {
          store.dispatch(
            showSnackbar({
              message: "Session Expired Please login again",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
            })
          );
        }
        return Promise.reject(error);
      }
    );
  },
};
