import React from "react";
import { writeFile, utils } from "xlsx";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

const ExcelExport = ({ fetchData, filename, sheetname, hideMobileNumber }) => {
  const handleExport = async () => {
    let data = await fetchData(hideMobileNumber);
    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, sheetname || "Sheet1");
    writeFile(wb, filename || "data.xlsx");
  };

  return (
    <Button variant="contained" size="small" onClick={handleExport}>
      Export
    </Button>
  );
};

ExcelExport.propTypes = {
  fetchData: PropTypes.func.isRequired, // Accept a callback to fetch data
  filename: PropTypes.string,
  sheetname: PropTypes.string,
  hideMobileNumber: PropTypes.bool,
};

export default ExcelExport;
