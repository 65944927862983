import React, { useState, useEffect, useRef } from "react";
import { Box, LinearProgress, Typography, Button, Chip } from "@mui/material";
import { styled } from "@mui/system";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../redux/snackbar/actions";
import userManual from "../../assets/pdf/LDS_TAGCS-PO00010_User_Manual_v1.pdf";
import storageApi from "../../services/customer";
import Cookies from "js-cookie";

// Function to convert bytes to gigabytes
const bytesToGB = (bytes) => bytes / (1024 * 1024 * 1024);

// Function to format GB value to 2 decimal places
const formatGB = (gb) => Math.floor(gb * 100) / 100;

const getColor = (value) => {
  if (value <= 50) {
    return "green";
  } else if (value <= 80) {
    return "orange";
  } else {
    return "red";
  }
};

const RoundedLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: 6,
  borderRadius: 5,
  "& .MuiLinearProgress-bar": {
    borderRadius: 5,
    backgroundColor: getColor(value),
  },
}));

export default function LinearBuffer() {
  const [progress, setProgress] = useState(0);
  const [totalSpace, setTotalSpace] = useState(0);
  const [availableSpace, setAvailableSpace] = useState(0);
  const dispatch = useDispatch();

  // Function to open the user manual PDF
  const handleOpenPdf = () => {
    window.open(userManual);
  };

  // Function to handle the storage button click
  const handleStorage = () => {
    dispatch(
      showSnackbar({
        message: "Option under maintenance",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        variant: "warning",
      })
    );
  };

  // Get the shop ID from cookies
  const id = Cookies.get("shop_id");

  // Fetch storage data from API
  const getStorage = () => {
    if (id) {
      storageApi.storage(id).then(
        (res) => {
          // console.log("Storage data received:", res.data);
          const { data } = res.data;
          if (data && data.available_space && data.total_space) {
            // Convert bytes to gigabytes
            const totalGB = bytesToGB(Number(data.total_space));
            const availableGB = bytesToGB(Number(data.available_space));
            const usedGB = totalGB - availableGB;
            const usagePercentage = (usedGB / totalGB) * 100;

            // console.log("Available space (GB):", formatGB(availableGB));
            // console.log("Total space (GB):", formatGB(totalGB));
            // console.log("Progress Percentage:", usagePercentage.toFixed(2));

            setTotalSpace(formatGB(totalGB).toFixed(2));
            setAvailableSpace(formatGB(availableGB).toFixed(2));
            setProgress(Math.min(usagePercentage, 100)); // Ensure progress doesn't exceed 100%
          } else {
            console.error("API response data is missing expected properties.");
          }
        },
        (err) => {
          console.error("Failed to fetch storage data", err);
        }
      );
    } else {
      console.warn("No shop ID found in cookies.");
    }
  };

  useEffect(() => {
    // console.log("Fetching storage data...");
    getStorage();
  }, [id]);

  // useEffect(() => {
  //   console.log(
  //     "Total space updated:",
  //     totalSpace,
  //     "Available space:",
  //     availableSpace
  //   );
  // }, [totalSpace, availableSpace]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center", color: "#192c54" }}>
        <Typography variant="subtitle2">Storage Details</Typography>
        <Chip
          onClick={handleOpenPdf}
          label="User Manual"
          variant="outlined"
          size="small"
          sx={{ ml: "auto" }}
          color="primary"
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <RoundedLinearProgress variant="determinate" value={progress} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            progress
          )}%`}</Typography>
        </Box>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="subtitle2" color="textSecondary">
          {formatGB(totalSpace - availableSpace)}GB of {formatGB(totalSpace)}
          GB used
        </Typography>
        {/* <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={handleStorage}
        >
          Buy Storage
        </Button> */}
      </Box>
    </Box>
  );
}
