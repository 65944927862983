import { ACTION_TYPES } from "../../../constants/index";
import Cookies from "js-cookie";

const initialState = {
  isAuthenticated: Cookies.get("token") ? true : false,
  token: Cookies.get("token"),
  printData: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.AUTH:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        token: action.payload.token,
      };
    case ACTION_TYPES.PRINT_DATA:
      return {
        ...state,
        printData: action.payload.printData,
      };
    default:
      return state;
  }
};

export default authReducer;
