import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  Box,
  CardActions,
  CardContent,
  Button,
  Typography,
  Grid,
  TextField,
  MenuItem,
} from "@mui/material";
import CustomerDatagrid from "../../component/datagrid/customGrid";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { makeStyles } from "@material-ui/core";
import customerApi from "../../services/customer/index";
import Create from "./create/index";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../../redux/loader/actions/index";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#f06666 !important",
  },
  cardRadius: {
    borderRadius: "0 15px 15px 0 !important",
    borderLeft: "3px solid #f06666",
  },
}));
const defaultPaginationModel = { page: 0, pageSize: 10 };
const defaultSortOrder = [{ field: "id", sort: "asc" }];
const defaultSearchParams = {
  cust_id: null,
  "user.name": null,
  "user.mobile_number": null,
  ref_name: null,
  location: null,
};
const Customer_Registration = () => {
  const [getcustomerlist, setGetCustomerList] = useState([]);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState();
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [filterDel, setFilterDel] = useState(false);
  const [filterCus, setFilterCus] = useState(false);
  const [filterCol, setFilterCol] = useState(false);
  const [filterBill, setFilterBill] = useState(false);
  const [filterTax, setFilterTax] = useState(false);
  const [filterOrder, setFilterOrder] = useState(false);
  const [filterTypecol, setFilterTypecol] = useState("");
  const [filteredSearchParams, setFilteredSearchParams] = useState({});
  const [searchParams, setSearchParams] = useState({
    cust_id: null,
    "user.name": null,
    "user.mobile_number": null,
    ref_name: null,
    location: null,
  });
  const [triggerOrdersList, setTriggerOrdersList] = useState(null);

  // 1console.log("gggggg", sortOrder);

  const customerListRows = getcustomerlist?.map((data, index) => ({
    sno_id: data?.id,
    id: data?.cust_id,
    "user.name": data?.user?.name,
    referenceName: data?.ref_name,
    "user.mobile_number": data?.user?.mobile_number,
    alternativeMobileNumber: data?.alt_mobile_number,
    "user.email": data?.user?.email,
    location: data?.location,
  }));
  const handleFieldChange = (field, value) => {
    setSearchParams((prev) => ({
      ...prev,
      [field]: value || undefined,
    }));
  };
  const handleSearch = () => {
    const filteredSearchParams = Object.fromEntries(
      Object.entries(searchParams).filter(([_, value]) => value !== undefined)
    );
    setFilteredSearchParams(filteredSearchParams);
    setTriggerOrdersList(Math.random());

    // 1console.log("Params to send:", filteredSearchParams);
    setFilterCus(true);
    // Trigger API call when search button is clicked
    // getAllCustomersList();
  };

  const dispatch = useDispatch();

  const getAllCustomersList = useCallback(() => {
    dispatch(showLoader());
    customerApi
      .getAllCustomer(
        paginationModel?.page,
        paginationModel?.pageSize,
        sortOrder?.[0]?.field,
        sortOrder?.[0]?.sort,
        searchParams
      )
      .then(
        (res) => {
          setGetCustomerList(res.data.data);
          setRowCount(res.data?.pagination?.totalRecord);
          dispatch(hideLoader());
        },
        (err) => {
          dispatch(hideLoader());
        }
      );
  }, [sortBy, sortOrder, paginationModel, triggerOrdersList]);

  useEffect(() => {
    getAllCustomersList();
  }, [
    getAllCustomersList,
    paginationModel,
    sortBy,
    sortOrder,
    triggerOrdersList,
  ]);

  const getAllCustomersListExport = useCallback(() => {
    dispatch(showLoader());

    return customerApi.getAllCustomerExport(searchParams, rowCount).then(
      (res) => {
        dispatch(hideLoader());
        const data = res.data.data;
        const processedData = data.map((item, index) => {
          const {} = item;

          return {
            customer_id: item?.cust_id,
            name: item?.user?.name,
            ref_name: item?.ref_name,
            mobile_number: item?.user?.mobile_number,
            alt_mobile_number: item?.alt_mobile_number,
            email: item?.user?.email,
            location: item?.location,
          };
        });
        return processedData;
      },
      (err) => {
        dispatch(hideLoader());
        return [];
      }
    );
  }, [dispatch, searchParams, rowCount]);

  const classes = useStyles();

  const handleOpen = () => {
    setMode("new");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const actionClick = (row) => {
    setMode("edit");
    setCustomerId(row.sno_id);
    setOpen(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "Customer ID",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "user.name",
      headerName: "Customer Name",
      width: 150,
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "referenceName",
      headerName: "Ref Name",
      width: 150,
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "user.mobile_number",
      headerName: "Mobile Number",
      width: 200,
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "alternativeMobileNumber",
      headerName: "Alternate Number",
      width: 200,
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "user.email",
      headerName: "Email",
      width: 150,
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "location",
      headerName: "Location",
      width: 150,
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          <strong>
            <EditOutlinedIcon
              sx={{ cursor: "pointer" }}
              onClick={() => actionClick(params.row)}
            />
          </strong>
        </div>
      ),
    },
  ];
  const resetAlldel = () => {};
  const resetAllOrder = () => {};
  const resetAllcus = () => {
    setPaginationModel(defaultPaginationModel);
    setPageSize(defaultPaginationModel.pageSize);
    setSortOrder(defaultSortOrder);
    setSearchParams(defaultSearchParams);
    setTriggerOrdersList(Math.random());
    setFilterCus(false);
  };
  const resetAllcol = () => {};
  const resetAllbill = () => {};
  const resetAlltax = () => {};
  const exportName = "Customer";

  return (
    <Card elevation={5} fullWidth className={classes.cardRadius}>
      <CardContent>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" gutterBottom>
              Customer List
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} container justifyContent="flex-end">
            <Button
              variant="contained"
              className={classes.btn}
              onClick={handleOpen}
              size="medium"
            >
              <AddOutlinedIcon />
              Create
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2} my={1}>
            <Grid item xs={12} md={2} sm={12}>
              <TextField
                id="order-id"
                fullWidth
                label="Customer ID"
                variant="outlined"
                value={searchParams.cust_id || ""}
                onChange={(e) => handleFieldChange("cust_id", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={2} sm={12}>
              <TextField
                id="customer-name"
                label="Customer Name"
                fullWidth
                variant="outlined"
                value={searchParams["user.name"] || ""}
                onChange={(e) => handleFieldChange("user.name", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={2} sm={12}>
              <TextField
                id="ref-name"
                fullWidth
                label="Ref Name"
                variant="outlined"
                value={searchParams["ref_name"] || ""}
                onChange={(e) => handleFieldChange("ref_name", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={2} sm={12}>
              <TextField
                id="customer-mobile"
                fullWidth
                label="Mobile Number"
                variant="outlined"
                value={searchParams["user.mobile_number"] || ""}
                onChange={(e) =>
                  handleFieldChange("user.mobile_number", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} md={2} sm={12}>
              <TextField
                id="customer-location"
                fullWidth
                label="Location"
                variant="outlined"
                value={searchParams["location"] || ""}
                onChange={(e) => handleFieldChange("location", e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              sm={12}
              sx={{
                margin: "auto",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button variant="contained" size="medium" onClick={handleSearch}>
                Search
              </Button>
              {filterCus && (
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ ml: 1 }}
                  onClick={resetAllcus}
                >
                  Reset
                </Button>
              )}
            </Grid>
          </Grid>
          <CustomerDatagrid
            rows={customerListRows}
            getRowId={(row) => row.sno_id}
            exportName={exportName}
            columns={columns}
            pagination
            paginationMode="server"
            rowCount={rowCount}
            page={page}
            pageSize={pageSize}
            pageSizeOptions={[10, 25, 50, 100]}
            paginationModel={paginationModel}
            onPaginationModelChange={(params) => setPaginationModel(params)}
            onPageSizeChange={(params) => setPageSize(params.pageSize)}
            onSortModelChange={(params) => {
              setSortOrder(params);
            }}
            setFiltersApplied={setFiltersApplied}
            filtersApplied={filtersApplied}
            filterDel={filterDel}
            setFilterDel={setFilterDel}
            filterOrder={filterOrder}
            setFilterOrder={setFilterOrder}
            filterCol={filterCol}
            setFilterCol={setFilterCol}
            filterCus={filterCus}
            setFilterCus={setFilterCus}
            filterBill={filterBill}
            setFilterBill={setFilterBill}
            filterTax={filterTax}
            setFilterTax={setFilterTax}
            resetAlldel={resetAlldel}
            resetAllOrder={resetAllOrder}
            resetAllcus={resetAllcus}
            resetAllcol={resetAllcol}
            resetAllbill={resetAllbill}
            resetAlltax={resetAlltax}
            exportCallback={getAllCustomersListExport}
          />
        </Box>
      </CardActions>
      <Create
        open={open}
        setOpen={setOpen}
        getAllCustomersList={getAllCustomersList}
        setMode={setMode}
        mode={mode}
        setGetCustomerList={setGetCustomerList}
        customerData={customerData}
        setCustomerData={setCustomerData}
        customerId={customerId}
        setCustomerId={setCustomerId}
      />
    </Card>
  );
};

export default Customer_Registration;
