import axios from "axios";
import { API_URL } from "../../constants";
import _ from "lodash";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllorder: (searchParams, rowCount, startDate, endDate) => {
    const filteredParams = _.pickBy(
      searchParams,
      (value) => !_.isNil(value) && value !== false && value !== ""
    );
    return axios.get(API_URL.ORDER_GETALL, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
      params: {
        filter: JSON.stringify(filteredParams),
        pagination: true,
        pageSize: rowCount,
        startDate,
        endDate,
        // sortBy: "order_date",
      },
    });
  },
  getOrder: (id) => {
    return axios.get(`${API_URL.ORDER_GET}/${id}`, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
    });
  },
  getOrderCopy: (id) => {
    return axios.get(`${API_URL.ORDER_COPY}/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  createOrder: (data) => {
    return axios.post(API_URL.ORDER_CREATE, data, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
    });
  },
  updateOrder: (id, data) => {
    return axios.patch(`${API_URL.ORDER_UPDATE}/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
    });
  },
  getAllDressType: () => {
    return axios.get(API_URL.DRESSTYPE_GETALL, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
    });
  },

  getOrderDate: () => {
    return axios.get(API_URL.ORDER_DATE, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
    });
  },

  orderHistory: (data) => {
    const customerId = Number(data);
    return axios.post(
      API_URL.ORDER_HISTORY,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          isAuthRequired: true,
        },
        params: {
          cust_id: customerId,
        },
      }
    );
  },
  // getAllOrderList: (params, startDate, endDate, searchParams) => {
  //   // Filter out falsy values including false, null, undefined, 0, NaN, and empty strings
  //   const filteredParams = _.pickBy(
  //     params,
  //     (value) => !_.isNil(value) && value !== false && value !== ""
  //   );

  //   return axios.get(API_URL.ORDER_GETALL, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       isAuthRequired: true,
  //     },
  //     params: {
  //       filter: JSON.stringify(searchParams),
  //       ...filteredParams,
  //       startDate,
  //       endDate,
  //     },
  //   });
  // },
  getAllOrderList: (params, startDate, endDate, searchParams) => {
    // Default values for filters and pagination
    const defaultParams = {
      filter: JSON.stringify(searchParams || {}),
      page: params.page || 0, // Default page to 0 if not provided
      pageSize: params.pageSize || 10, // Default pageSize to 10 if not provided
      sortBy: params.sortBy || "id", // Default sort field
      sortOrder: params.sortOrder || "desc", // Default sort order
      date_filter: params.date_filter || null, // Default date filter
    };

    // Filter out falsy values from params
    const filteredParams = _.pickBy(
      params,
      (value) => !_.isNil(value) && value !== false && value !== ""
    );

    // Merge default parameters with user-provided params
    const finalParams = { ...defaultParams, ...filteredParams };

    return axios.get(API_URL.ORDER_GETALL, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
      params: {
        ...finalParams,
        startDate,
        endDate,
      },
    });
  },

  dressTypeCreate: (data) => {
    return axios.post(API_URL.DRESSTYPE_CREATE, data, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
    });
  },
  getAllMaster: () => {
    return axios.get(API_URL.MASTER_GETALL, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
    });
  },
  masterCreate: (data) => {
    return axios.post(API_URL.MASTER_CREATE, data, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
    });
  },
  getAllTailor: () => {
    return axios.get(API_URL.TAILOR_GETALL, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
    });
  },
  tailorCreate: (data) => {
    return axios.post(API_URL.TAILOR_CREATE, data, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
    });
  },
  roughImage: (data, shopId) => {
    return axios.post(`${API_URL.ROUGH_IMAGE}/${shopId}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        isAuthRequired: true,
      },
    });
  },
};
