import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Modal,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../../redux/snackbar/actions";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#f06666 !important",
  },
  icon: {
    fontSize: "17px !important",
    position: "relative",
    top: "3px",
    cursor: "pointer",
  },
  cardRadius: {
    borderRadius: "0 15px 15px 0 !important",
    borderLeft: "3px solid #f06666",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  maxHeight: "90vh",
  overflowY: "auto",
};

function BreakUpModal({
  breakupDetail = [],
  setBreakupDetail,
  setSelectedOrderDetail,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {}, [open]);
  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditId(null);
    setSelectedOrderDetail(null);
    // setBreakupDetail([]);
  };

  const handleEdit = (id) => {
    const selectedBreakup = breakupDetail.find((item) => item.id === id);
    if (selectedBreakup) {
      breakupFormik.setValues({
        title: selectedBreakup.title,
        price: selectedBreakup.price,
      });
      setEditId(id);
    }
  };

  const handleDelete = (id) => {
    const updatedBreakupDetail = breakupDetail.filter((item) => item.id !== id);

    const updatedDetailWithSno = updatedBreakupDetail.map((item, index) => ({
      ...item,
      sno: (index + 1).toString(),
    }));

    setBreakupDetail(updatedDetailWithSno);
  };

  const handleSubmit = (values) => {
    if (editId !== null) {
      // Edit existing detail
      setBreakupDetail((prev) =>
        prev.map((item) =>
          item.id === editId
            ? { ...item, title: values.title, price: values.price }
            : item
        )
      );
      setEditId(null);
    } else {
      // Add new detail
      if (breakupDetail?.length >= 5) {
        dispatch(
          showSnackbar({
            message: "Only 5 Breakups Details allowed",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "warning",
          })
        );
        breakupFormik.resetForm();
        return;
      }
      const newDetail = {
        id:
          breakupDetail?.length > 0
            ? breakupDetail[breakupDetail.length - 1].id + 1
            : 1,
        sno: (breakupDetail?.length + 1).toString(),
        title: values.title,
        price: values.price,
      };

      setBreakupDetail((prev) => [...prev, newDetail]);
    }

    breakupFormik.resetForm();
  };

  const breakupFormik = useFormik({
    initialValues: {
      title: "",
      price: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Description Required"),
      price: Yup.string()
        .matches(/^\d+$/, "Price must be a number")
        .required("Price Required"),
    }),
    onSubmit: handleSubmit,
  });

  const breakupHead = [
    { id: 1, title: "S.No" },
    { id: 2, title: "title" },
    { id: 3, title: "Price" },
    { id: 4, title: "Action" },
  ];

  return (
    <>
      <Chip label="Breakups" sx={{ mt: 1 }} onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-description"
        aria-describedby="child-modal-description"
        disableBackdropClick={true}
        BackdropProps={{
          onClick: (event) => {
            event.stopPropagation();
          },
        }}
      >
        <Box sx={{ ...style, width: { xs: "95%", sm: "50%" } }}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h6" component="h2" gutterBottom>
                Charges Breakups
              </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="outlined-multiline-flexible"
                label="Description"
                fullWidth
                name="title"
                onChange={breakupFormik.handleChange}
                onBlur={breakupFormik.handleBlur}
                error={
                  breakupFormik.touched.title &&
                  Boolean(breakupFormik.errors.title)
                }
                helperText={
                  breakupFormik.touched.title && breakupFormik.errors.title
                }
                value={breakupFormik.values.title}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="outlined-multiline-flexible"
                label="Price"
                fullWidth
                name="price"
                onChange={breakupFormik.handleChange}
                onBlur={breakupFormik.handleBlur}
                error={
                  breakupFormik.touched.price &&
                  Boolean(breakupFormik.errors.price)
                }
                helperText={
                  breakupFormik.touched.price && breakupFormik.errors.price
                }
                value={breakupFormik.values.price}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Button
                variant="contained"
                className={classes.btn}
                size="medium"
                onClick={breakupFormik.handleSubmit}
              >
                <AddOutlinedIcon />
                {editId !== null ? "Update" : "Add"}
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ overflow: "auto" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }}>
                    <strong>S.NO</strong>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <strong>Description</strong>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <strong>Price</strong>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <strong>Actions</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {breakupDetail.map((detail) => (
                  <TableRow key={detail.id}>
                    <TableCell sx={{ textAlign: "center" }}>
                      {detail.sno}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {detail.title}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {detail.price}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <IconButton onClick={() => handleEdit(detail.id)}>
                        <EditOutlinedIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(detail.id)}>
                        <DeleteForeverOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default BreakUpModal;
