import axios from "axios";
import { API_URL } from "../../constants";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login: (data) => {
    return axios.post(API_URL.LOGIN, data, {
      headers: { "Content-Type": "application/json" },
    });
  },
  logout: (data) => {
    return axios.post(API_URL.LOGOUT, data, {
      headers: { "Content-Type": "application/json" },
    });
  },
  forgot: (data) => {
    return axios.post(API_URL.FORGOT, data, {
      headers: { "Content-Type": "application/json" },
    });
  },
  verifyOtp: (data) => {
    return axios.post(API_URL.VERIFY_OTP, data, {
      headers: { "Content-Type": "application/json" },
    });
  },
  resetPassword: (data) => {
    return axios.post(API_URL.RESET_PASSWORD, data, {
      headers: { "Content-Type": "application/json" },
    });
  },
  changePassword: (data) => {
    return axios.post(API_URL.CHANGE_PASSWORD, data, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
    });
  },
};
