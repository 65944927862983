import { ACTION_TYPES } from "../../../constants/index";
import Cookies from "js-cookie";

export const setAuth = (payload) => ({
  type: ACTION_TYPES.AUTH,
  payload: payload,
});

export const setPrintData = (payload) => ({
  type: ACTION_TYPES.PRINT_DATA,
  payload: payload,
});
