import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import { Box, Container } from "@mui/material";
import Header from "../pages/header/index";
import Sidebar from "../pages/sidebar";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie"

const Layout = ({ children }) => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setOpen(window.innerWidth >= 900);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setOpen(!open);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header toggleSidebar={toggleSidebar} />
        <Box sx={{ display: "flex" }} px={1} pt={10}>
          <Sidebar open={open} setOpen={setOpen} />
          <Box
            component="main"
            sx={{
              mx: 1,
              mb: 3,
              width: open ? "calc(100% - 280px)" : "80%",
              width: {
                xs: open ? "calc(100% - 280px)" : "75%", 
                sm: open ? "calc(100% - 280px)" : "90%", 
                md: open ? "calc(100% - 280px)" : "90%", 
                // lg: open ? "calc(100% - 280px)" : "80%", 
                xl: open ? "calc(100% - 280px)" : "95%", 
              },
            }}
          >
            {children}
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Layout;
