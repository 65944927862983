import React, { useState, useEffect } from "react";
import {
  Divider,
  Box,
  Button,
  Typography,
  Grid,
  Modal,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from "@mui/material";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip from "../../../component/tooltip/index";
import { format } from "date-fns";
import _ from "lodash";
import OrderCreate from "../create/index";
import { IMG_URL } from "../../../constants";
import AttachCopy from "./attachcopy";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#f06666 !important",
  },
  icon: {
    fontSize: "17px !important",
    position: "relative",
    top: "3px",
    cursor: "pointer",
  },
  cardRadius: {
    borderRadius: "0 15px 15px 0 !important",
    borderLeft: "3px solid #f06666",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  maxHeight: "90vh",
  width: {
    xs: "90%",
    sm: "90%",
    lg: "80%",
  },
  overflowY: "auto",
};

// function AttachCopy({ data }) {
//   const classes = useStyles();
//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//   };
//   return (
//     <>
//       <Chip label="Attachment" sx={{ mt: 1 }} onClick={handleOpen} />
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="child-modal-title"
//         aria-describedby="child-modal-description"
//         disableBackdropClick={true}
//         BackdropProps={{
//           onClick: (event) => {
//             event.stopPropagation();
//           },
//         }}
//       >
//         <Box sx={{ ...style, width: { xs: "95%", sm: "50%" } }}>
//           <Grid container alignItems="center">
//             <Grid item xs={6}>
//               <Typography variant="h6" component="h2" gutterBottom>
//                 Attachments
//               </Typography>
//             </Grid>
//             <Grid item xs={6} container justifyContent="flex-end">
//               <IconButton onClick={handleClose}>
//                 <CloseIcon />
//               </IconButton>
//             </Grid>
//           </Grid>
//           <Grid container alignItems="center" spacing={2}>
//             <Grid item xs={12} sm={6} lg={4} align="center">
//               <img
//                 src={`${IMG_URL}/${data}`}
//                 alt={data}
//                 sx={{ width: "150px" }}
//               />
//             </Grid>
//           </Grid>
//           <Box></Box>
//         </Box>
//       </Modal>
//     </>
//   );
// }
const OrderHistory = ({
  hisopen,
  setHisopen,
  handlehisClose,
  setMode,
  mode,
  setLatestOrder,
  latestOrder,
  customerData,
  customerNameOptions,
  orderData,
  setOrderData,
  orderHistoryList,
}) => {
  const classes = useStyles();
  const mobnum = [];
  const assignedperson = [];
  const history = [];
  const orderstatus = [];
  const paymentMode = [];
  const size1 = [
    { id: 1, title: "Bl", name: "blouse_bi" },
    { id: 2, title: "Sh", name: "blouse_sh" },
    { id: 3, title: "Sl", name: "blouse_si" },
    { id: 4, title: "R", name: "blouse_r" },
    { id: 5, title: "Arm", name: "blouse_arm" },
    { id: 6, title: "Up", name: "blouse_up" },
    { id: 7, title: "Ch", name: "blouse_ch" },
  ];
  const size2 = [
    { id: 1, title: "Yoke", name: "blouse_yoke" },
    { id: 2, title: "Waist", name: "blouse_waist" },
    { id: 3, title: "Bust", name: "blouse_bust" },
    { id: 4, title: "F", name: "blouse_f" },
    { id: 5, title: "B", name: "blouse_b" },
    { id: 6, title: "Cut", name: "blouse_cut" },
  ];
  const size3 = [
    { id: 1, title: "Toplen", name: "set_toplen" },
    { id: 2, title: "Sh", name: "set_sh" },
    { id: 3, title: "Sl", name: "set_si" },
    { id: 4, title: "R", name: "set_r" },
    { id: 5, title: "Arm", name: "set_arm" },
    { id: 6, title: "Up", name: "set_up" },
    { id: 7, title: "Ch", name: "set_ch" },
  ];
  const size4 = [
    { id: 1, title: "Waist", name: "set_waist" },
    { id: 2, title: "Seat", name: "set_seat" },
    { id: 3, title: "F", name: "set_f" },
    { id: 4, title: "B", name: "set_b" },
  ];
  const size5 = [
    { id: 1, title: "Kneelen", name: "kneelen" },
    { id: 2, title: "Bol", name: "boi" },
    { id: 3, title: "Round", name: "round" },
    { id: 4, title: "K.Round", name: "kround" },
    { id: 5, title: "Thigh", name: "thigh" },
    { id: 6, title: "Waist", name: "waist" },
    { id: 7, title: "Seat", name: "seat" },
  ];
  const orderHead = [
    { id: 1, title: "S.No" },
    { id: 2, title: "Particulars" },
    { id: 3, title: "Colors" },
    { id: 4, title: "Lining" },
    { id: 5, title: "Quantity" },
    { id: 6, title: "Attachment" },
    { id: 7, title: "Price" },
    { id: 9, title: "Amount" },
    { id: 8, title: "Delivery Date" },
  ];
  const historyDetail = [{ id: 1, title: "07-06-2024/₹500/Cash" }];
  const breakupDes = [{ id: 1, title: "Stitching-550 + Designing-250" }];
  const orderDetails = [
    {
      id: 1,
      particular: "Blouse",
      color: "Red",
      lining: "1 m",
      qty: "1",
      attachment: "1",
      price: "550+250",
      deliverydate: "7-Jan-2024",
      amount: "800",
    },
    {
      id: 2,
      particular: "Kurti",
      color: "Red",
      lining: "1 m",
      qty: "1",
      attachment: "1",
      price: "550+250",
      deliverydate: "7-Jan-2024",
      amount: "800",
    },
  ];
  const [breakopen, setBreakopen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleSubmit = () => {
    formik.resetForm();
  };
  const handleHistory = () => {
    setMode("");
    setMode("history");
  };
  const formik = useFormik({
    initialValues: {
      mobilenumber: "",
      dresstype: "",
      billtype: "",
      blouse_bi: "",
      blouse_sh: "",
      blouse_si: "",
      blouse_r: "",
      blouse_arm: "",
      blouse_up: "",
      blouse_ch: "",
      blouse_yoke: "",
      blouse_waist: "",
      blouse_bust: "",
      blouse_f: "",
      blouse_b: "",
      blouse_cut: "",
      set_toplen: "",
      set_sh: "",
      set_si: "",
      set_r: "",
      set_arm: "",
      set_up: "",
      set_ch: "",
      set_waist: "",
      set_seat: "",
      set_f: "",
      set_b: "",
      kneelen: "",
      boi: "",
      round: "",
      kround: "",
      thigh: "",
      waist: "",
      seat: "",
      notes: "",
      lining: "",
      quantity: 0,
      bill_amount: 0,
      advance: 0,
      payment_mode: "",
      payment_date: "",
      delivery_date: "",
      second_delivery_date: "",
      discount: "",
      due_amount: "",
      order_status: "pending",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      mobilenumber: Yup.string()
        .required("Mobile number is required")
        .matches(/^[0-9]{10}$/, "Mobile number is not valid"),
      history: Yup.string().required("Order History Required"),
      billtype: Yup.string().required("Bill Type Required"),
      notes: Yup.string().required("Notes Are Required"),
      deldate: Yup.string().required("Delivery Date Required"),
      del2date: Yup.string().required("2nd Delivery Date Required"),
      advance: Yup.number()
        .required("Advance is Required")
        .max(7, "Advance must be at 7 Digits"),
      bi: Yup.string()
        .required("BL is Required")
        .max(5, "BL must be at 5 characters"),
      sh: Yup.string()
        .required("SH is Required")
        .max(5, "Sh must be at 5 characters"),
      si: Yup.string()
        .required("SL is Required")
        .max(5, "SL must be at 5 characters"),
      r1: Yup.string()
        .required("R is Required")
        .max(5, "R must be at 5 characters"),
      arm1: Yup.string()
        .required("ARM is Required")
        .max(5, "Arm must be at 5 characters"),
      up1: Yup.string()
        .required("Up is Required")
        .max(5, "Up must be at 5 characters"),
      ch1: Yup.string()
        .required("CH is Required")
        .max(5, "Ch must be at 5 characters"),
      yoke: Yup.string()
        .required("YOKE is Required")
        .max(5, "Yoke must be at 5 characters"),
      waist1: Yup.string()
        .required("WAIST is Required")
        .max(5, "Waist must be at 5 characters"),
      bust: Yup.string()
        .required("BUST is Required")
        .max(5, "Bust must be at 5 characters"),
      f1: Yup.string()
        .required("F is Required")
        .max(5, "F must be at 5 characters"),
      b1: Yup.string()
        .required("B is Required")
        .max(5, "B must be at 5 characters"),
      cut: Yup.string()
        .required("CUT is Required")
        .max(5, "Cut must be at 5 characters"),
      toplen: Yup.string()
        .required("TOPLEN is Required")
        .max(5, "Toplen must be at 5 characters"),
      sh2: Yup.string()
        .required("SH is Required")
        .max(5, "Sh must be at 5 characters"),
      sl2: Yup.string()
        .required("SL is Required")
        .max(5, "Sl must be at 5 characters"),
      r2: Yup.string()
        .required("R is Required")
        .max(5, "R must be at 5 characters"),
      arm2: Yup.string()
        .required("ARM is Required")
        .max(5, "Arm must be at 5 characters"),
      up2: Yup.string()
        .required("UP is Required")
        .max(5, "Up must be at 5 characters"),
      ch2: Yup.string()
        .required("CH is Required")
        .max(5, "Ch must be at 5 characters"),
      waist2: Yup.string()
        .required("WAIST is Required")
        .max(5, "Waist must be at 5 characters"),
      seat1: Yup.string()
        .required("SEAT is Required")
        .max(5, "Seat must be at 5 characters"),
      f2: Yup.string()
        .required("F is Required")
        .max(5, "F must be at 5 characters"),
      b2: Yup.string()
        .required("B is Required")
        .max(5, "B must be at 5 characters"),
      kneelen: Yup.string()
        .required("KNEELEN is Required")
        .max(5, "Kneelen must be at 5 characters"),
      bol: Yup.string()
        .required("BOL is Required")
        .max(5, "Bol must be at 5 characters"),
      round: Yup.string()
        .required("ROUND is Required")
        .max(5, "Round must be at 5 characters"),
      kround: Yup.string()
        .required("K.ROUND is Required")
        .max(5, "K.Round must be at 5 characters"),
      thigh: Yup.string()
        .required("THIGH is Required")
        .max(5, "Thigh must be at 5 characters"),
      waist3: Yup.string()
        .required("WAIST is Required")
        .max(5, "Waist must be at 5 characters"),
      seat2: Yup.string()
        .required("SEAT is Required")
        .max(5, "Seat must be at 5 characters"),
    }),

    onSubmit: handleSubmit,
  });
  const [inputValue, setInputValue] = useState("");
  const [isCustomNumber, setIsCustomNumber] = useState(false);
  const handleInputChange = (event, value) => {
    setInputValue(value);
    setIsCustomNumber(
      value.length === 7 && !mobnum.some((contact) => contact.value === value)
    );
  };

  const handleClose = () => {
    setHisopen(false);
    setMode("");
  };

  const formatDate = (date) => {
    try {
      return format(new Date(date), "dd-MM-yyyy");
    } catch {
      return "";
    }
  };

  useEffect(() => {
    if (!_.isEmpty(selectedCustomer)) {
      const parsedParticulars = selectedCustomer?.particulars || [];
      formik.setFieldValue("blouse_bi", selectedCustomer?.blouse_bi);
      formik.setFieldValue("blouse_sh", selectedCustomer?.blouse_sh);
      formik.setFieldValue("blouse_si", selectedCustomer?.blouse_si);
      formik.setFieldValue("blouse_r", selectedCustomer?.blouse_r);
      formik.setFieldValue("blouse_arm", selectedCustomer?.blouse_arm);
      formik.setFieldValue("blouse_up", selectedCustomer?.blouse_up);
      formik.setFieldValue("blouse_ch", selectedCustomer?.blouse_ch);
      formik.setFieldValue("blouse_yoke", selectedCustomer?.blouse_yoke);
      formik.setFieldValue("blouse_waist", selectedCustomer?.blouse_waist);
      formik.setFieldValue("blouse_bust", selectedCustomer?.blouse_bust);
      formik.setFieldValue("blouse_f", selectedCustomer?.blouse_f);
      formik.setFieldValue("blouse_b", selectedCustomer?.blouse_b);
      formik.setFieldValue("set_toplen", selectedCustomer?.set_toplen);
      formik.setFieldValue("set_sh", selectedCustomer?.set_sh);
      formik.setFieldValue("set_si", selectedCustomer?.set_si);
      formik.setFieldValue("set_r", selectedCustomer?.set_r);
      formik.setFieldValue("set_arm", selectedCustomer?.set_arm);
      formik.setFieldValue("set_up", selectedCustomer?.set_up);
      formik.setFieldValue("set_ch", selectedCustomer?.set_ch);
      formik.setFieldValue("set_waist", selectedCustomer?.set_waist);
      formik.setFieldValue("set_seat", selectedCustomer?.set_seat);
      formik.setFieldValue("set_f", selectedCustomer?.set_f);
      formik.setFieldValue("set_b", selectedCustomer?.set_b);
      formik.setFieldValue("kneelen", selectedCustomer?.kneelen);
      formik.setFieldValue("boi", selectedCustomer?.boi);
      formik.setFieldValue("round", selectedCustomer?.round);
      formik.setFieldValue("kround", selectedCustomer?.kround);
      formik.setFieldValue("thigh", selectedCustomer?.thigh);
      formik.setFieldValue("waist", selectedCustomer?.waist);
      formik.setFieldValue("seat", selectedCustomer?.seat);
      formik.setFieldValue("notes", selectedCustomer?.notes);
      formik.setFieldValue("lining", selectedCustomer?.lining);
      formik.setFieldValue("quantity", selectedCustomer?.quantity);
      formik.setFieldValue("bill_amount", selectedCustomer?.bill_amount);
      formik.setFieldValue("advance", selectedCustomer?.advance);
      formik.setFieldValue("payment_mode", selectedCustomer?.payment_mode);
      formik.setFieldValue("payment_date", selectedCustomer?.payment_date);
      formik.setFieldValue("delivery_date", selectedCustomer?.delivery_date);
      formik.setFieldValue(
        "second_delivery_date",
        selectedCustomer?.second_delivery_date
      );
      formik.setFieldValue("discount", selectedCustomer?.discount);
      formik.setFieldValue("due_amount", selectedCustomer?.due_amount);
      formik.setFieldValue("order_status", selectedCustomer?.order_status);
      formik.setFieldValue(
        "payment_details",
        selectedCustomer?.payment_details
      );
    }
  }, [selectedCustomer]);

  const parsedOrderData = selectedCustomer?.particulars
    ? selectedCustomer.particulars
    : [];
  const handleMeasurement = () => {
    setLatestOrder(selectedCustomer);
    handleClose();
  };
  // orderHistoryList.forEach((order) => {
  //   console.log(order?.customer?.user?.name);
  // });

  return (
    <>
      <Modal
        open={hisopen}
        onClose={handlehisClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableBackdropClick={true}
        BackdropProps={{
          onClick: (event) => {
            event.stopPropagation();
          },
        }}
      >
        <Box sx={{ ...style, display: "flex", flexDirection: "column", mb: 2 }}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h6" component="h2" gutterBottom>
                Order History
              </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              <IconButton onClick={handlehisClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} sm={6} lg={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} lg={6}>
                    <Typography variant="subtitle2" component="p">
                      <strong>Customer Name:</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} lg={6}>
                    <Typography variant="subtitle2" component="p">
                      {orderHistoryList[0]?.customer?.user?.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} lg={6}>
                    <Typography variant="subtitle2" component="p">
                      <strong>Ref Name:</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} lg={6}>
                    <Typography variant="subtitle2" component="p">
                      <Grid item xs={6} sm={6} lg={6}>
                        <Typography variant="subtitle2" component="p">
                          {orderHistoryList[0]?.customer?.ref_name}
                        </Typography>
                      </Grid>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider variant="middle" />
            <Grid container mt={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <Autocomplete
                  options={orderData || []}
                  getOptionLabel={(option) => {
                    const orderId = option?.order_id
                      ? String(option.order_id)
                      : "";
                    const orderDate = option?.order_date
                      ? formatDate(option.order_date)
                      : "";
                    return `${orderId} - ${orderDate}`;
                  }}
                  name="order_id"
                  id="order_id"
                  value={selectedCustomer}
                  onChange={(event, newValue) => {
                    setSelectedCustomer(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="orderId"
                      label="Order Id"
                      variant="outlined"
                      error={
                        formik.touched.orderId && Boolean(formik.errors.orderId)
                      }
                      helperText={
                        formik.touched.orderId && formik.errors.orderId
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                >
                  <Button
                    variant="contained"
                    size="medium"
                    className={classes.btn}
                    onClick={handleMeasurement}
                  >
                    <DoneOutlinedIcon />
                    Apply Measurement
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Box mt={2}>
              <Accordion className={classes.cardRadius}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  Order Details
                </AccordionSummary>
                <AccordionDetails sx={{ overflow: "auto !important" }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ textAlign: "center" }}>ID</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          Particular
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          Color
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          Lining
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          Quantity
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          Attachment
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          Price
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          Amount
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {parsedOrderData.map((head, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ textAlign: "center" }}>
                            {head.id}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {head.particular}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {head.color}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {head.lining}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {head.qty}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {head.attachment ? (
                              <AttachCopy detail={head.attachment} />
                            ) : (
                              "No image"
                            )}
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {head.price}{" "}
                            {head.breakupDetails &&
                              head.breakupDetails.map((item, idx) => (
                                <React.Fragment key={idx}>
                                  {idx > 0 && " + "} {item.price}
                                </React.Fragment>
                              ))}
                            <Tooltip
                              historyDetail={head.breakupDetails.map(
                                (breakup, index) => (
                                  <React.Fragment key={index}>
                                    {index > 0 && " / "}
                                    {breakup.title}: {breakup.price}
                                  </React.Fragment>
                                )
                              )}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {head.amount}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
          <Box mt={2}>
            <Typography variant="subtitle2" component="h2" sx={{ mt: 2 }}>
              Blouses
            </Typography>
            <TableContainer component={Paper}>
              <Table
                aria-label="simple table"
                sx={{ border: "1px solid #efefef" }}
              >
                <TableBody>
                  <TableRow sx={{ border: "1px solid #efefef" }}>
                    {size1.map((title) => (
                      <TableCell component="th" scope="row">
                        <TextField
                          sx={{
                            width: {
                              xs: "50px",
                              sm: "100%",
                            },
                          }}
                          label={title.title}
                          variant="outlined"
                          id={title.id}
                          name={title.name}
                          placeholder={title.title}
                          onChange={formik.handleChange}
                          onBlur={formik?.handleBlur}
                          error={
                            formik?.touched?.[title.name] &&
                            Boolean(formik?.errors?.[title.name])
                          }
                          helperText={
                            formik?.touched?.[title.name] &&
                            formik?.errors?.[title.name]
                          }
                          value={formik.values[title.name]}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableRow sx={{ border: "1px solid #efefef" }}>
                    {size2.map((title) => (
                      <TableCell component="th" scope="row">
                        <TextField
                          sx={{
                            width: {
                              xs: "50px",
                              sm: "100%",
                            },
                          }}
                          label={title.title}
                          variant="outlined"
                          id={title.id}
                          name={title.name}
                          placeholder={title.title}
                          onChange={formik.handleChange}
                          onBlur={formik?.handleBlur}
                          error={
                            formik?.touched?.[title.name] &&
                            Boolean(formik?.errors?.[title.name])
                          }
                          helperText={
                            formik?.touched?.[title.name] &&
                            formik?.errors?.[title.name]
                          }
                          value={formik.values[title.name]}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="subtitle2" component="h2" sx={{ mt: 2 }}>
              Set
            </Typography>
            <TableContainer component={Paper}>
              <Table
                aria-label="simple table"
                sx={{ border: "1px solid #efefef" }}
              >
                <TableBody>
                  <TableRow sx={{ border: "1px solid #efefef" }}>
                    {size3.map((title) => (
                      <TableCell component="th" scope="row">
                        <TextField
                          sx={{
                            width: {
                              xs: "50px",
                              sm: "100%",
                            },
                          }}
                          label={title.title}
                          variant="outlined"
                          id={title.id}
                          name={title.name}
                          placeholder={title.title}
                          onChange={formik.handleChange}
                          onBlur={formik?.handleBlur}
                          error={
                            formik?.touched?.[title.name] &&
                            Boolean(formik?.errors?.[title.name])
                          }
                          helperText={
                            formik?.touched?.[title.name] &&
                            formik?.errors?.[title.name]
                          }
                          value={formik.values[title.name]}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableRow sx={{ border: "1px solid #efefef" }}>
                    {size4.map((title) => (
                      <TableCell component="th" scope="row">
                        <TextField
                          sx={{
                            width: {
                              xs: "50px",
                              sm: "100%",
                            },
                          }}
                          label={title.title}
                          variant="outlined"
                          id={title.id}
                          name={title.name}
                          placeholder={title.title}
                          onChange={formik.handleChange}
                          onBlur={formik?.handleBlur}
                          error={
                            formik?.touched?.[title.name] &&
                            Boolean(formik?.errors?.[title.name])
                          }
                          helperText={
                            formik?.touched?.[title.name] &&
                            formik?.errors?.[title.name]
                          }
                          value={formik.values[title.name]}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer sx={{ mt: 2 }} component={Paper}>
              <Table
                aria-label="simple table"
                sx={{ border: "1px solid #efefef" }}
              >
                <TableBody>
                  <TableRow sx={{ border: "1px solid #efefef" }}>
                    {size5.map((title) => (
                      <TableCell component="th" scope="row">
                        <TextField
                          sx={{
                            width: {
                              xs: "50px",
                              sm: "100%",
                            },
                          }}
                          label={title.title}
                          variant="outlined"
                          id={title.id}
                          name={title.name}
                          placeholder={title.title}
                          onChange={formik.handleChange}
                          onBlur={formik?.handleBlur}
                          error={
                            formik?.touched?.[title.name] &&
                            Boolean(formik?.errors?.[title.name])
                          }
                          helperText={
                            formik?.touched?.[title.name] &&
                            formik?.errors?.[title.name]
                          }
                          value={formik.values[title.name]}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Notes"
                  fullWidth
                  multiline
                  maxRows={10}
                  name="notes"
                  onChange={formik.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.notes && Boolean(formik?.errors?.notes)
                  }
                  helperText={formik?.touched?.notes && formik?.errors?.notes}
                  value={formik.values.notes}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Lining"
                  fullWidth
                  name="lining"
                  onChange={formik.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.lining && Boolean(formik?.errors?.lining)
                  }
                  helperText={formik?.touched?.lining && formik?.errors?.lining}
                  value={formik.values.lining}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Quantity"
                  fullWidth
                  name="quantity"
                  onChange={formik.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.quantity &&
                    Boolean(formik?.errors?.quantity)
                  }
                  helperText={
                    formik?.touched?.quantity && formik?.errors?.quantity
                  }
                  value={formik.values.quantity}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Bill Amount"
                  fullWidth
                  name="bill_amount"
                  onChange={formik.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.bill_amount &&
                    Boolean(formik?.errors?.bill_amount)
                  }
                  helperText={
                    formik?.touched?.bill_amount && formik?.errors?.bill_amount
                  }
                  value={formik.values.bill_amount}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Payment Mode"
                  fullWidth
                  name="payment_mode"
                  onChange={formik.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.payment_mode &&
                    Boolean(formik?.errors?.payment_mode)
                  }
                  helperText={
                    formik?.touched?.payment_mode &&
                    formik?.errors?.payment_mode
                  }
                  value={formik.values.payment_mode}
                />
              </Grid> */}
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default OrderHistory;
