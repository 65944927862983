import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  Grid,
  IconButton,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../../redux/snackbar/actions/index";
import { showLoader, hideLoader } from "../../../../redux/loader/actions/index";
import orderApi from "../../../../services/order";
import CameraCapture from "../../../../component/camera/index";
import { styled } from "@mui/system";
import { makeStyles } from "@material-ui/core";
import Cookies from "js-cookie";
const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#f06666 !important",
  },
  icon: {
    fontSize: "17px !important",
    position: "relative",
    top: "3px",
    cursor: "pointer",
  },
  cardRadius: {
    borderRadius: "0 15px 15px 0 !important",
    borderLeft: "3px solid #f06666",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  maxHeight: "90vh",
  overflowY: "auto",
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "auto",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AttachmentModal = ({ setRoughDiagram }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [shopId, setShopId] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    if (open) {
      setShopId(parseFloat(Cookies.get("shop_id")));
    }
  }, [open]);

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    setUploadedFile(file);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUploadedFile();
  };
  const handleSubmit = () => {
    dispatch(showLoader());
    const formData = new FormData();
    formData.append("FILE", uploadedFile);

    const onSuccess = (res) => {
      dispatch(hideLoader());
      if (res?.data?.status) {
        dispatch(
          showSnackbar({
            message: res?.data?.msg || "Info updated successfully",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
          })
        );
      }
      setRoughDiagram(res?.data?.data?.path);
      setUploadedFile();
    };

    const onFailure = (err) => {
      dispatch(hideLoader());
      dispatch(
        showSnackbar({
          message: err?.response?.data?.msg || "Failed to fetch data",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        })
      );
    };

    orderApi.roughImage(formData, shopId).then(onSuccess, onFailure);
  };

  return (
    <>
      <Chip label="Attachment" sx={{ mt: 1 }} onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-title"
        disableBackdropClick={true}
        BackdropProps={{
          onClick: (event) => {
            event.stopPropagation();
          },
        }}
      >
        <Box sx={{ ...style, p: 2, width: { xs: "95%", sm: "50%" } }}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h6" component="h2" gutterBottom>
                Add Attachment
              </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6} lg={4} align="center">
              <Button
                component="label"
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} align="center">
              <CameraCapture setUploadedFile={setUploadedFile} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} align="center">
              <Button
                variant="contained"
                className={classes.btn}
                size="medium"
                onClick={handleSubmit}
                disabled={!uploadedFile}
              >
                <DoneOutlinedIcon />
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default AttachmentModal;
