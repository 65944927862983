import axios from "axios";
import { API_URL } from "../../constants";
import moment from "moment";
import _ from "lodash";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  filterOrder: (data) => {
    return axios.post(API_URL.FILTER_ORDER, data, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
    });
  },
  deliverFollowUpReportsExport: (
    searchParams,
    rowCountdel,
    date_filter,
    startDatedel,
    endDatedel
  ) => {
    // Helper function to check if a date is valid
    const isValidDate = (date) => {
      return date instanceof Date && !isNaN(date.getTime());
    };

    // Filter out invalid or empty searchParams
    const filteredParams = _.pickBy(
      searchParams,
      (value) => !_.isNil(value) && value !== false && value !== ""
    );

    // Construct the params object
    const params = {
      filter: JSON.stringify(filteredParams),
      pagination: true,
      pageSize: rowCountdel,
      date_filter,
    };

    // Only add startDate and endDate if they are valid and not empty
    const parsedStartDate = new Date(startDatedel);
    const parsedEndDate = new Date(endDatedel);

    if (startDatedel && isValidDate(parsedStartDate)) {
      params.startDate = startDatedel;
    }
    if (endDatedel && isValidDate(parsedEndDate)) {
      params.endDate = endDatedel;
    }

    // Make the API request
    return axios.get(API_URL.FILTER_ORDER_FOLLOWUPS, {
      headers: {
        "Content-Type": "multipart/form-data",
        isAuthRequired: true,
      },
      params,
    });
  },

  collectionReportsExport: (
    searchParams,
    rowCountcol,
    date_filter,
    startDate,
    endDate
  ) => {
    const filteredParams = _.pickBy(
      searchParams,
      (value) => !_.isNil(value) && value !== false && value !== ""
    );
    return axios.get(API_URL.COLLECTION_REPORTS, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
      params: {
        filter: JSON.stringify(filteredParams),
        pagination: true,
        pageSize: rowCountcol,
        date_filter,
        startDate,
        endDate,
      },
    });
  },
  deliverFollowUpReports: (
    params,
    startDate,
    endDate,
    date_filter,
    searchParamsdel
  ) => {
    const filteredParams = _.pickBy(
      searchParamsdel,
      (value) => !_.isNil(value) && value !== false && value !== ""
    );
    return axios.get(API_URL.FILTER_ORDER_FOLLOWUPS, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
      params: {
        ...params,
        startDate,
        endDate,
        date_filter,
        filter: JSON.stringify(filteredParams),
      },
    });
  },
  collectionReports: (
    params,
    startDate,
    endDate,
    date_filter,
    searchParamscol
  ) => {
    const filteredParams = _.pickBy(
      searchParamscol,
      (value) => !_.isNil(value) && value !== false && value !== ""
    );
    return axios.get(API_URL.COLLECTION_REPORTS, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
      params: {
        ...params,
        startDate,
        endDate,
        date_filter,
        filter: JSON.stringify(filteredParams),
      },
    });
  },

  billingReports: (
    params,
    startDate,
    endDate,
    date_filter,
    searchParamsbill
  ) => {
    console.log(searchParamsbill);
    const filteredParams = _.pickBy(
      searchParamsbill,
      (value) => !_.isNil(value) && value !== false && value !== ""
    );
    return axios.post(API_URL.FILTER_ORDER, {
      headers: {
        "Content-Type": "application/json",
        isAuthRequired: true,
      },
      params: {
        billing: true,
        ...params,
        startDate,
        endDate,
        date_filter,
        filter: JSON.stringify(filteredParams),
      },
    });
  },

  taxReports: (
    page,
    pageSize,
    sortBy,
    sort,
    startDate,
    endDate,
    datetax,
    searchParamsbill
  ) => {
    console.log("tax", searchParamsbill);
    const filteredParams = _.pickBy(
      searchParamsbill,
      (value) => !_.isNil(value) && value !== false && value !== ""
    );
    return axios.post(API_URL.INVOICE_GETALL, {
      headers: {
        "Content-Type": "multipart/form-data",
        isAuthRequired: true,
      },
      params: {
        invoice_generated: true,
        pagination: true,
        page: page,
        pageSize: pageSize,
        sortBy: sortBy,
        sortOrder: sort,
        startDate: startDate,
        endDate: endDate,
        date_filter: datetax,
        filter: JSON.stringify(filteredParams),
      },
    });
  },
};
