import React, { useState } from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  Container,
  IconButton,
  InputAdornment,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useFormik } from "formik";
import * as yup from "yup";
import LoginBanner from "../../assets/img/loginBanner.png";
import { useNavigate } from "react-router-dom";
import Icon from "../../assets/img/logo.png";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../../redux/loader/actions";
import { showSnackbar } from "../../redux/snackbar/actions";
import authApi from "../../services/auth";
import Cookies from "js-cookie";
import Bannerone from "../../assets/img/banner1.png";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  bannerImage: {
    backgroundImage: `url(${LoginBanner})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "right",
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${Bannerone})`,
      backgroundPosition: "left",
      backgroundSize: "cover",
    },
    [theme.breakpoints.between("sm", "md")]: {
      display: "block !important",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
}));

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="" sx={{ textDecoration: "none" }}>
        Lakshitha The Designer Studio
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const ForgotPassword = () => {
  const classes = useStyles();
  const [reset, setReset] = useState(false);
  const [forgot, setForgot] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword((prevShowNewPassword) => !prevShowNewPassword);
  };
  const toggleConfPasswordVisibility = () => {
    setShowConfPassword((prevShowConfPassword) => !prevShowConfPassword);
  };

  const sendOtp = (values) => {
    dispatch(showLoader());

    const onSucces = (res) => {
      dispatch(hideLoader());
      if (res?.data?.status === true) {
        dispatch(
          showSnackbar({
            message: res?.data?.msg || "OTP Send successfully",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
          })
        );
        setReset(true);
        setForgot(false);
      }
    };

    const onFailure = (err) => {
      dispatch(hideLoader());
      dispatch(
        showSnackbar({
          message: err?.response?.data?.msg || "Failed to fetch data",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        })
      );
      forgotFormik.resetForm();
    };

    authApi.forgot(values).then(onSucces, onFailure);
  };

  const handleVerifyOtp = () => {
    const data = {
      email: forgotFormik.values.email,
      otp: resetFormik.values.otp,
    };
    dispatch(showLoader());

    const onSuccess = (res) => {
      dispatch(hideLoader());
      if (res?.data?.status === true) {
        dispatch(
          showSnackbar({
            message: res?.data?.msg || "OTP Verified successfully",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
          })
        );
      }
    };
    const onFailure = (err) => {
      dispatch(hideLoader());
      dispatch(
        showSnackbar({
          message: err?.response?.data?.msg || "Failed to fetch data",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        })
      );
      resetFormik.resetForm();
    };
    authApi.verifyOtp(data).then(onSuccess, onFailure);
  };

  const resetPassword = () => {
    const data = {
      email: forgotFormik.values.email,
      otp: resetFormik.values.otp,
      newPassword: resetFormik.values.password,
    };
    dispatch(showLoader());
    const onSuccess = (res) => {
      dispatch(hideLoader());
      if (res?.data?.status === true) {
        dispatch(
          showSnackbar({
            message: res?.data?.msg || "Password Changed successfully",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
          })
        );
      }
      resetFormik.resetForm();
      navigate("/");
    };
    const onFailure = (err) => {
      dispatch(hideLoader());
      dispatch(
        showSnackbar({
          message: err?.response?.data?.msg || "Failed to Reset",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        })
      );
      resetFormik.resetForm();
    };

    if (resetFormik.values.password === resetFormik.values.cfrmpassword) {
      authApi.resetPassword(data).then(onSuccess, onFailure);
    } else {
      dispatch(hideLoader());
      dispatch(
        showSnackbar({
          message: "New Password and Confirm Password not matched",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        })
      );
    }
  };

  const forgotFormik = useFormik({
    initialValues: {
      email: "",
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      email: yup.string().email("Invalid email").required("Email required"),
    }),
    onSubmit: sendOtp,
  });

  const handleResendOtp = () => {
    if (forgotFormik.isValid) {
      sendOtp(forgotFormik.values);
      resetFormik.resetForm();
    } else {
      forgotFormik.setTouched({ email: true });
    }
  };

  const resetPass = () => {
    resetFormik.resetForm();
    setReset(true);
    setForgot(false);
    navigate("/");
  };

  const resetFormik = useFormik({
    initialValues: {
      otp: "",
      password: "",
      cfrmpassword: "",
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      otp: yup
        .string()
        .matches(/^\d{6}$/, "OTP must be 6 digits only")
        .min(6, "OTP must be 6 digits")
        .max(6, "OTP must be 6 digits")
        .required("Required"),
      password: yup
        .string()
        .required("Required")
        .min(8, "Password must be at least 8 characters")
        .max(12, "Password must be at most 12 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&])[A-Za-z\d@$!%?&]{8,12}$/,
          "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character"
        )
        .matches(
          /([@#$%^&+=])/,
          "Password must contain at least 2 special characters"
        ),
      cfrmpassword: yup
        .string()
        .required("Required")
        .min(8, "Password must be at least 8 characters")
        .max(12, "Password must be at most 12 characters")
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&])[A-Za-z\d@$!%?&]{8,12}$/,
          "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character"
        )
        .matches(
          /([@#$%^&+=])/,
          "Password must contain at least 2 special characters"
        ),
    }),
    onSubmit: resetPassword,
  });
  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid item xs={false} sm={null} md={8} className={classes.bannerImage} />
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        pt={5}
        component={Paper}
        elevation={6}
        square
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={Icon}
            alt="logo"
            sx={{
              my: 2,
              width: "35px",
              height: "auto",
              maxWidth: "100%",
            }}
          />
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <Container sx={{ mt: 1 }}>
            {forgot && (
              <>
                <TextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  type="text"
                  autoComplete="off"
                  value={forgotFormik?.values?.email}
                  onChange={forgotFormik.handleChange}
                  onBlur={forgotFormik?.handleBlur}
                  error={
                    forgotFormik?.touched?.email &&
                    Boolean(forgotFormik?.errors?.email)
                  }
                  helperText={
                    forgotFormik?.touched?.email && forgotFormik?.errors?.email
                  }
                />
                <Button
                  onClick={forgotFormik?.handleSubmit}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: "#192c54",
                    "&:hover": {
                      backgroundColor: "#0e1c3a",
                    },
                  }}
                >
                  Send OTP
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link
                      href="/"
                      variant="body2"
                      sx={{ textDecoration: "none", color: "#f06666" }}
                    >
                      Back to Sign In
                    </Link>
                  </Grid>
                </Grid>
              </>
            )}
            {reset && (
              <Box>
                <TextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  id="otp"
                  label="Enter OTP"
                  name="otp"
                  type="otp"
                  autoComplete="off"
                  value={resetFormik?.values?.otp}
                  onChange={resetFormik.handleChange}
                  onBlur={resetFormik?.handleBlur}
                  error={
                    resetFormik?.touched?.otp &&
                    Boolean(resetFormik?.errors?.otp)
                  }
                  helperText={
                    resetFormik?.touched?.otp && resetFormik?.errors?.otp
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleVerifyOtp}
                          sx={{ paddingRight: 0 }}
                        >
                          <CheckIcon sx={{ color: "green" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* <TextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  id="password"
                  label="New Password"
                  name="password"
                  type={showNewPassword ? "text" : "password"}
                  autoComplete="off"
                  value={resetFormik?.values?.password}
                  onChange={resetFormik?.handleChange}
                  onBlur={resetFormik?.handleBlur}
                  error={
                    resetFormik?.touched?.password &&
                    Boolean(resetFormik?.errors?.password)
                  }
                  helperText={
                    resetFormik?.touched?.password &&
                    resetFormik?.errors?.password
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleNewPasswordVisibility}
                          edge="end"
                        >
                          <VisibilityOutlinedIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                /> */}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="New Password"
                  name="password"
                  type={showNewPassword ? "text" : "password"}
                  autoComplete="off"
                  value={resetFormik.values.password}
                  onChange={resetFormik.handleChange}
                  onBlur={resetFormik.handleBlur}
                  error={
                    resetFormik.touched.password &&
                    Boolean(resetFormik.errors.password)
                  }
                  helperText={
                    resetFormik.touched.password && resetFormik.errors.password
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleNewPasswordVisibility}
                          edge="end"
                        >
                          <VisibilityOutlinedIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* <TextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  id="cfrmpassword"
                  label="Confirm New Password"
                  name="cfrmpassword"
                  type={showConfPassword ? "text" : "password"}
                  autoComplete="off"
                  value={resetFormik?.values?.cfrmpassword}
                  onChange={resetFormik?.handleChange}
                  onBlur={resetFormik?.handleBlur}
                  error={
                    resetFormik?.touched?.cfrmpassword &&
                    Boolean(resetFormik?.errors?.cfrmpassword)
                  }
                  helperText={
                    resetFormik?.touched?.cfrmpassword &&
                    resetFormik?.errors?.cfrmpassword
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleConfPasswordVisibility}
                          edge="end"
                        >
                          <VisibilityOutlinedIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                /> */}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="cfrmpassword"
                  label="Confirm New Password"
                  name="cfrmpassword"
                  type={showConfPassword ? "text" : "password"}
                  autoComplete="off"
                  value={resetFormik.values.cfrmpassword}
                  onChange={resetFormik.handleChange}
                  onBlur={resetFormik.handleBlur}
                  error={
                    resetFormik.touched.cfrmpassword &&
                    Boolean(resetFormik.errors.cfrmpassword)
                  }
                  helperText={
                    resetFormik.touched.cfrmpassword &&
                    resetFormik.errors.cfrmpassword
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleConfPasswordVisibility}
                          edge="end"
                        >
                          <VisibilityOutlinedIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  onClick={resetFormik?.handleSubmit}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: "#192c54",
                    "&:hover": {
                      backgroundColor: "#0e1c3a",
                    },
                  }}
                >
                  Submit
                </Button>
                <Grid container>
                  <Grid item xs={6} sm={6}>
                    <Link
                      onClick={handleResendOtp}
                      variant="body2"
                      sx={{
                        textDecoration: "none",
                        color: "#f06666",
                        cursor: "pointer",
                      }}
                    >
                      Resend OTP
                    </Link>
                  </Grid>
                  <Grid item xs={6} sm={6} sx={{ textAlign: "end" }}>
                    <Link
                      href="/"
                      variant="body2"
                      sx={{ textDecoration: "none", color: "#f06666" }}
                    >
                      Back to Sign In
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            )}
            <Copyright sx={{ mt: 10 }} />
          </Container>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
