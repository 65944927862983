import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  useTheme,
  Tab,
  Tabs,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomerDatagrid from "../../component/datagrid/customGrid";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../../redux/loader/actions";
import { showSnackbar } from "../../redux/snackbar/actions";
import orderApi from "../../services/reports/index";
import invoiceApi from "../../services/invoice/index";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  cardRadius: {
    borderRadius: "0 15px 15px 0 !important",
    borderLeft: "3px solid #f06666 !important",
    backgroundColor: "#ffffff !important",
  },
  customTabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#ffffff !important",
    },
    "& .Mui-selected": {
      backgroundColor: "#f06666 !important",
      color: "#ffffff !important !important",
      borderRadius: "10px 10px 0 0 !important",
    },
    "& .MuiTab-root": {
      color: "#000000 !important",
      borderRadius: "10px 10px 0 0 !important",
      minHeight: "auto !important",
    },
  },
  tabsGrid: {
    display: "flex !important",
    flexWrap: "wrap !important",
  },
  tabItem: {
    flex: "1 1 50%",
    [theme.breakpoints.up("sm")]: {
      flex: "1 1 25% !important",
    },
  },
  tabBox: {
    cursor: "pointer !important",
  },
  activeTab: {
    borderBottom: "2px solid #f06666 !important",
    backgroundColor: "#f06666 !important",
    color: "#ffffff !important",
    borderRadius: "10px 10px 0 0 !important",
  },
}));
const defaultPaginationModel = { page: 0, pageSize: 10 };
const defaultSortOrder = [{ field: "id", sort: "asc" }];
const defaultSearchParamsdel = {
  order_id: null,
  "user.name": null,
  "user.mobile_number": null,
  "customer.ref_name": null,
};
const defaultSearchParamsbill = {
  invoice_id: null,
  "user.name": null,
  "user.mobile_number": null,
  "customer.ref_name": null,
};
const defaultSearchParamstax = {
  invoice_id: null,
  "user.name": null,
  "user.mobile_number": null,
  "customer.ref_name": null,
};
const defaultSearchParamscol = {
  order_id: null,
  "user.name": null,
  "user.mobile_number": null,
  "customer.ref_name": null,
  "customer.location": null,
  "payment.payment_mode": null,
};
const Billing = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = useState(0);
  const [deliveryFollowUp, setDeliveryFollowUp] = useState([]);
  const [collection, setCollection] = useState([]);
  const [billing, setBilling] = useState([]);
  const [tax, setTax] = useState([]);
  const [hideMobileNumber, setHideMobileNumber] = useState(false);
  const [filterTypedel, setFilterTypedel] = useState("");
  const [startDatedel, setStartDatedel] = useState(null);
  const [endDatedel, setEndDatedel] = useState(null);
  const [filterTypecol, setFilterTypecol] = useState("");
  const [startDatecol, setStartDatecol] = useState(null);
  const [endDatecol, setEndDatecol] = useState(null);
  const [filterTypebil, setFilterTypebil] = useState("");
  const [startDatebil, setStartDatebil] = useState(null);
  const [endDatebil, setEndDatebil] = useState(null);
  const [filterTypetax, setFilterTypetax] = useState("");
  const [startDatetax, setStartDatetax] = useState(null);
  const [endDatetax, setEndDatetax] = useState(null);
  const [filterDel, setFilterDel] = useState(false);
  const [filterOrder, setFilterOrder] = useState(false);
  const [filterCol, setFilterCol] = useState(false);
  const [filterBill, setFilterBill] = useState(false);
  const [filterTax, setFilterTax] = useState(false);
  const [filterCus, setFilterCus] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [paginationModeldel, setPaginationModeldel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [paginationModelcol, setPaginationModelcol] = useState({
    page: 0,
    pageSize: 10,
  });
  const [paginationModelbill, setPaginationModelbill] = useState({
    page: 0,
    pageSize: 10,
  });
  const [paginationModeltax, setPaginationModeltax] = useState({
    page: 0,
    pageSize: 10,
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState();
  const [rowCountdel, setRowCountdel] = useState();
  const [rowCountcol, setRowCountcol] = useState();
  const [rowCountbill, setRowCountbill] = useState();
  const [rowCounttax, setRowCounttax] = useState();
  const [sortBy, setSortBy] = useState("order_date");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [datedel, setDatedel] = useState("false");
  const [datecol, setDatecol] = useState("false");
  const [datebill, setDatebill] = useState("false");
  const [datetax, setDatetax] = useState("false");
  const [filteredSearchParamsdel, setFilteredSearchParamsdel] = useState({});
  const [searchParamsdel, setSearchParamsdel] = useState({
    order_id: null,
    "user.name": null,
    "user.mobile_number": null,
    "customer.ref_name": null,
  });
  const [triggerOrdersListdel, setTriggerOrdersListdel] = useState(null);
  const [filteredSearchParamscol, setFilteredSearchParamscol] = useState({});
  const [searchParamscol, setSearchParamscol] = useState({
    order_id: null,
    "user.name": null,
    "user.mobile_number": null,
    "customer.ref_name": null,
    "customer.location": null,
    "payment.payment_mode": null,
  });
  const [triggerOrdersListcol, setTriggerOrdersListcol] = useState(null);
  const [filteredSearchParamsbill, setFilteredSearchParamsbill] = useState({});
  const [searchParamsbill, setSearchParamsbill] = useState({
    invoice_id: null,
    "user.name": null,
    "user.mobile_number": null,
    "customer.ref_name": null,
  });
  const [triggerOrdersListbill, setTriggerOrdersListbill] = useState(null);
  const [filteredSearchParamstax, setFilteredSearchParamstax] = useState({});
  const [searchParamstax, setSearchParamstax] = useState({
    invoice_id: null,
    "user.name": null,
    "user.mobile_number": null,
    "customer.ref_name": null,
  });
  const [triggerOrdersListtax, setTriggerOrdersListtax] = useState(null);

  const handleFieldChangedel = (field, value) => {
    // 1console.log("Updating field:", field, "with value:", value);
    setSearchParamsdel((prev) => {
      const updatedParams = {
        ...prev,
        [field]: value || undefined,
      };
      // 1console.log("Updated searchParams:", updatedParams);
      return updatedParams;
    });
  };
  const handleFieldChangecol = (field, value) => {
    // 1console.log("Updating field:", field, "with value:", value);
    setSearchParamscol((prev) => {
      const updatedParams = {
        ...prev,
        [field]: value || undefined,
      };
      // 1console.log("Updated searchParams:", updatedParams);
      return updatedParams;
    });
  };
  const handleFieldChangebill = (field, value) => {
    // 1console.log("Updating field:", field, "with value:", value);
    setSearchParamsbill((prev) => {
      const updatedParams = {
        ...prev,
        [field]: value || undefined,
      };
      // 1console.log("Updated searchParams:", updatedParams);
      return updatedParams;
    });
  };
  const handleFieldChangetax = (field, value) => {
    // 1console.log("Updating field:", field, "with value:", value);
    setSearchParamstax((prev) => {
      const updatedParams = {
        ...prev,
        [field]: value || undefined,
      };
      // 1console.log("Updated searchParams:", updatedParams);
      return updatedParams;
    });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    const dateObj = new Date(dateStr);
    const formattedDate = `${dateObj.getDate().toString().padStart(2, "0")}-${(
      dateObj.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${dateObj.getFullYear()}`;
    return formattedDate;
  };
  const deliveryfollowupRows = deliveryFollowUp
    // .filter((row) => row && row.particulars) // Ensure row and row.particulars are defined
    .map((data, index) => {
      if (!data) return null;

      let particularsArray = [];

      // Check if data.particulars is an array, if not convert it to an array
      if (Array.isArray(data.particulars)) {
        particularsArray = data.particulars;
      } else if (data.particulars) {
        particularsArray = [data.particulars]; // Wrap single object in an array
      } else {
        return null; // Skip if data.particulars is undefined or null
      }

      // Filter out particulars where 'checked' is true
      // particularsArray = particularsArray.filter(
      //   (particular) => !particular.checked
      // );

      // Check if all particulars are checked, skip the row if they are
      // if (particularsArray.length === 0) {
      //   return null; // Skip this row
      // }

      // const deliveryDatesSet = new Set();

      // particularsArray.forEach((particular) => {
      //   if (particular.deltype === "1st Delivery Date" && data.delivery_date) {
      //     deliveryDatesSet.add(data.delivery_date);
      //   } else if (
      //     particular.deltype === "2nd Delivery Date" &&
      //     data.second_delivery_date
      //   ) {
      //     deliveryDatesSet.add(data.second_delivery_date);
      //   }
      // });

      // const deliveryDates = Array.from(deliveryDatesSet).join(" | ");

      return {
        id: index + 1,
        ids: index + 1,
        orderUUID: data.uuid,
        order_id: data.order_id,
        customerName: `${data.customer_name || "Unknown"} / ${
          data.cust_id || "N/A"
        }`,
        referenceName: data.customer_ref_name || "N/A",
        mobileNumber: data.customer_mobile_number || "N/A",
        particulars: particularsArray
          .map((item) => item.particular || "N/A")
          .join(" | "),
        master: particularsArray
          .map((particular) => particular.master)
          .filter((master) => master)
          .join(" | "),
        tailor: particularsArray
          .map((particular) => particular.tailor)
          .filter((tailor) => tailor)
          .join(" | "),
        color: particularsArray
          .map((particular) => particular.color || "N/A")
          .join(" | "),
        deliverydate:
          data?.delivery_type === "1st Delivery Date"
            ? moment(data?.delivery_date).format("DD-MM-YYYY")
            : data?.delivery_type === "2nd Delivery Date"
            ? moment(data?.second_delivery_date).format("DD-MM-YYYY")
            : "-",
      };
    });

  const collectionRows = collection
    ?.filter(
      (data) =>
        !(
          data?.payment_detail?.discount === 0 &&
          data?.payment_detail?.amount === 0
        )
    )
    .map((data, index) => {
      let paymentDetails = [];

      // Handle payment_details
      if (Array.isArray(data.payment_details)) {
        paymentDetails = data.payment_details;
      } else if (typeof data.payment_details === "string") {
        try {
          paymentDetails = JSON.parse(data.payment_details);
        } catch (error) {
          paymentDetails = []; // Fallback to empty array on parse error
        }
      } else if (
        typeof data.payment_details === "object" &&
        data.payment_details !== null
      ) {
        paymentDetails = [data.payment_details];
      }

      return {
        id: index + 1,
        ids: index + 1,
        orderUUID: data?.uuid,
        order_id: data?.order_id,
        order_date: moment(data?.order_date).format("DD-MM-YYYY"),
        customername: `${data?.customer_name} / ${data?.cust_id}`,
        invoice_id: data?.invoice_id,
        refname: data?.customer_ref_name,
        mobileNumber: data?.customer_mobile_number,
        location: data?.customer_location,
        orderamount: data?.bill_amount,
        paymentamount: data?.payment_detail?.amount,
        discount: data?.payment_detail?.discount,
        paymentmode:
          data?.payment_detail?.payment_mode === "" ||
          data?.payment_detail?.payment_mode === null ||
          data?.payment_detail?.payment_mode === "null" ||
          data?.payment_detail?.amount === 0
            ? "-"
            : data?.payment_detail?.payment_mode,
        payment_date: data?.payment_detail?.payment_date
          ? moment(data?.payment_detail?.payment_date).format("DD-MM-YYYY")
          : "-",
        balancedue: data?.payment_detail?.due_amount,
        // ? data?.payment_detail?.due_amount
        // : data?.due_amount,
      };
    });

  const BillingRows = billing?.map((data, index) => {
    // Parse payment_details into JSON array

    return {
      id: data?.orderById?.uuid,
      ids: index + 1,
      // invoice_id: data?.invoice_id,
      mobilenumber: data?.orderById?.customer?.user?.mobile_number,

      customerName: `${data?.orderById?.customer?.user?.name} / ${data?.orderById?.customer?.cust_id}`,
      refname: data?.orderById?.customer?.ref_name,
      location: data?.orderById?.customer?.location,
      orderrefid: data?.orderById?.order_id,
      order_uuid: data?.orderById?.uuid,
      invoiceDate: moment(data?.created_at).format("DD-MM-YYYY"),
      invoiceNumber: data?.invoice_id,
      invoiceamount: data?.net_amount,
      orderStatus:
        data?.orderById?.order_status === "cancelled" ? "Cancelled" : "Active",
    };
  });

  const TaxTabRows = tax
    ?.filter((data) => data?.orderById?.order_status !== "cancelled")
    .map((data, index) => {
      return {
        id: data?.orderById?.uuid,
        ids: index + 1,
        order_Id: data?.order_id,
        invoice_id: data?.invoice_id,
        invoice_generated_on: data?.invoice_generated_on
          ? data?.invoice_generated_on
          : "",
        created_at: data?.created_at
          ? moment(data?.created_at).format("DD-MM-YYYY")
          : "",
        customerName: `${data?.orderById?.customer?.user?.name} / ${data?.orderById?.customer?.cust_id}`,
        refName: data?.orderById?.customer?.ref_name,
        c_gst: data?.c_gst,
        s_gst: data?.s_gst,
        net_amount: data?.net_amount,
        sub_total: data?.sub_total,
        gsttype: "B2C",
        igst: "-",
      };
    });

  const dispatch = useDispatch();

  const getAllDeliveryReportsList = useCallback(
    (page, pageSize, sortBy, sortOrder, startDatedel, endDatedel, datedel) => {
      dispatch(showLoader());
      const sortField =
        Array.isArray(sortOrder) && sortOrder.length > 0
          ? sortOrder[0]?.field
          : null;
      setSortBy(sortField);
      const arrange = datedel === "true" ? "delivery_date" : "delivery_date";
      const params = {
        pagination: true,
        page: page,
        pageSize: pageSize,
        sortBy: arrange,
        sortOrder: sortOrder,
      };
      const date_filter = datedel;
      const startDate = moment(startDatedel).format("YYYY-MM-DD");
      const endDate = moment(endDatedel).format("YYYY-MM-DD");

      let sort =
        sortOrder && Array.isArray(sortOrder) && sortOrder.length > 0
          ? sortOrder
          : null;

      if (sort) {
        // Ensure sortOrder[0] exists and has a sort property that is a string
        if (
          sort[0].hasOwnProperty("sort") &&
          typeof sort[0].sort === "string"
        ) {
          sort = sort.map((item) => ({
            ...item,
            sort: item.sort.toUpperCase(), // Convert sortOrder[0].sort to uppercase
          }));
        } else {
          sort = null; // Reset sort if sortOrder[0].sort is not valid
        }
      }

      const onSuccess = (res) => {
        dispatch(hideLoader());
        if (res?.data?.status === true) {
          setDeliveryFollowUp(res.data.data);
          setRowCount(res.data?.pagination?.totalRecord);
          setRowCountdel(res.data?.pagination?.totalRecord);
        }
      };

      const onFailure = (err) => {
        dispatch(hideLoader());
      };
      orderApi
        .deliverFollowUpReports(
          params,
          date_filter === "true" ? startDate : undefined,
          date_filter === "true" ? endDate : undefined,
          date_filter,
          searchParamsdel
        )
        .then(onSuccess, onFailure);
    },
    [sortBy, sortOrder, paginationModeldel, searchParamsdel]
  );

  const getAllCollection = useCallback(
    (page, pageSize, sortBy, sortOrder, startDatecol, endDatecol, datecol) => {
      dispatch(showLoader());

      // Determine sortField based on sortOrder
      const sortField =
        sortOrder && sortOrder.length > 0 ? sortOrder[0]?.field : undefined;

      // Determine arrange based on datecol
      const arrange = datecol === "true" ? filterTypecol : sortField;

      // Prepare params object
      const params = {
        pagination: true,
        page: page,
        pageSize: pageSize,
        sortBy: sortOrder[0]?.field,
        sortOrder: sortOrder[0]?.sort,
      };

      // Convert startDatecol and endDatecol to YYYY-MM-DD format
      const startDate = startDatecol
        ? moment(startDatecol).format("YYYY-MM-DD")
        : undefined;
      const endDate = endDatecol
        ? moment(endDatecol).format("YYYY-MM-DD")
        : undefined;

      // Determine date_filter based on datecol
      let date_filter;
      if (datecol === "true") {
        date_filter = filterTypecol;
      } else {
        date_filter = sortField; // Assuming sortField is appropriate here
      }

      const onSuccess = (res) => {
        dispatch(hideLoader());
        if (res?.data?.status === true) {
          setCollection(res.data.data);
          setRowCount(res.data?.pagination?.totalRecord);
          setRowCountcol(res.data?.pagination?.totalRecord);
        }
      };

      const onFailure = (err) => {
        dispatch(hideLoader());
        // Handle failure if needed
      };

      // Make API call with params, startDate, endDate, and date_filter
      orderApi
        .collectionReports(
          params,
          startDate,
          endDate,
          date_filter,
          searchParamscol
        )
        .then(onSuccess)
        .catch(onFailure);
    },
    [sortBy, sortOrder, filterTypecol, paginationModelcol, searchParamscol] // Ensure all dependencies are included here
  );

  const getAllBilling = useCallback(
    (page, pageSize, sortBy, sortOrder, startDatebil, endDatebil, datebill) => {
      dispatch(showLoader());
      const params = {
        pagination: true,
        page: page,
        pageSize: pageSize,
        sortBy: datebill === "true" ? "created_at" : "created_at",
        sortOrder: sortOrder,
      };
      const date_filter = datebill;
      const startDate = moment(startDatebil).format("YYYY-MM-DD");
      const endDate = moment(endDatebil).format("YYYY-MM-DD");

      const onSuccess = (res) => {
        dispatch(hideLoader());
        if (res?.data?.status === true) {
          setBilling(res.data.data);
          setRowCount(res.data?.pagination?.totalRecord);
          setRowCountbill(res.data?.pagination?.totalRecord);
        }
      };

      const onFailure = (err) => {
        dispatch(hideLoader());
      };

      const cancelled = true;
      invoiceApi
        .getAllInvoice(
          params,
          date_filter === "true" ? startDate : undefined,
          date_filter === "true" ? endDate : undefined,
          date_filter,
          searchParamsbill,
          searchParamstax,
          cancelled
        )
        .then(onSuccess, onFailure);
    },
    [sortBy, sortOrder, paginationModeltax, searchParamsbill, searchParamstax]
  );
  const getAllTax = useCallback(
    (page, pageSize, sortBy, sortOrder, startDatetax, endDatetax, datetax) => {
      dispatch(showLoader());
      const params = {
        pagination: true,
        page: page,
        pageSize: pageSize,
        sortBy: datebill === "true" ? "created_at" : "created_at",
        sortOrder: sortOrder,
      };
      const date_filter = datetax;
      const startDate = moment(startDatetax).format("YYYY-MM-DD");
      const endDate = moment(endDatetax).format("YYYY-MM-DD");

      const onSuccess = (res) => {
        dispatch(hideLoader());
        if (res?.data?.status === true) {
          setTax(res.data.data);
          setRowCount(res.data?.pagination?.totalRecord);
          setRowCounttax(res.data?.pagination?.totalRecord);
        }
      };

      const onFailure = (err) => {
        dispatch(hideLoader());
      };
      const cancelled = false;
      invoiceApi
        .getAllInvoice(
          params,
          date_filter === "true" ? startDate : undefined,
          date_filter === "true" ? endDate : undefined,
          date_filter,
          searchParamstax,
          cancelled
        )
        .then(onSuccess, onFailure);
    },
    [sortBy, sortOrder, paginationModeltax, searchParamstax]
  );

  useEffect(() => {
    if (value === 0) {
      getAllDeliveryReportsList(
        paginationModeldel?.page,
        paginationModeldel?.pageSize,
        sortBy,
        sortOrder,
        startDatedel,
        endDatedel,
        datedel,
        searchParamsdel
      );
      setSortBy("delivery_date");
      resetAlltax();
      resetAllcol();
      resetAllbill();
    }
  }, [value, paginationModeldel, sortBy, sortOrder, triggerOrdersListdel]);
  useEffect(() => {
    if (value === 1) {
      getAllCollection(
        paginationModelcol?.page,
        paginationModelcol?.pageSize,
        sortBy,
        sortOrder,
        startDatecol,
        endDatecol,
        datecol,
        searchParamscol
      );
      setSortBy("order_date");
      resetAlldel();
      resetAlltax();
      resetAllbill();
    }
  }, [value, paginationModelcol, sortBy, sortOrder, triggerOrdersListcol]);
  useEffect(() => {
    if (value === 2) {
      getAllBilling(
        paginationModelbill?.page,
        paginationModelbill?.pageSize,
        sortBy,
        sortOrder,
        startDatebil,
        endDatebil,
        datebill,
        searchParamsbill
      );
      setSortBy("created_at");
      resetAlldel();
      resetAllcol();
      resetAlltax();
    }
  }, [value, paginationModelbill, sortBy, sortOrder, triggerOrdersListbill]);
  useEffect(() => {
    if (value === 3) {
      getAllTax(
        paginationModeltax?.page,
        paginationModeltax?.pageSize,
        sortBy,
        sortOrder,
        startDatetax,
        endDatetax,
        datetax,
        searchParamstax
      );
      setSortBy("created_at");
      resetAlldel();
      resetAllcol();
      resetAllbill();
    }
  }, [value, paginationModeltax, sortBy, sortOrder, triggerOrdersListtax]);

  const handleCheckboxChange = (event) => {
    setHideMobileNumber(event.target.checked);
  };
  const customerPrint = (id) => {
    window.open(`/customerprint/${id}`, "_blank");
  };
  const invoicePrint = (id) => {
    window.open(`/invoiceprint/${id}`, "_blank");
  };

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      sortable: false,
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "order_id",
      headerName: "Order ID",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const handleClick = () => {
          customerPrint(params.row.orderUUID);
        };
        return (
          <div style={{ cursor: "pointer" }} onClick={handleClick}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "order_date",
      headerName: "Order Date",
      width: 150,
      headerAlign: "center",
      align: "center",
      // valueFormatter: (value) => {
      //   formatDate(value);
      // },
      // sortComparator: (a, b) => moment(a).unix() - moment(b).unix(),
      // renderCell: (params) => moment(params.row.orderdate).format("DD-MM-YYYY"),
    },
    {
      field: "customername",
      headerName: "Customer Name/ID",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "refname",
      headerName: "Ref Name",
      sortable: false,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "location",
      headerName: "Location",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "orderamount",
      headerName: "Order Amount",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "paymentamount",
      headerName: " Paid Amount",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "payment_date",
      headerName: " Paid on",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      // valueFormatter: (params) => {
      //   const value = params.value;
      //   return value && moment(value).isValid() ? formatDate(value) : "-";
      // },
      // sortComparator: (a, b) => {
      //   // Handle invalid dates by treating them as earliest possible date (e.g., epoch time)
      //   const dateA = a && moment(a).isValid() ? moment(a).unix() : 0;
      //   const dateB = b && moment(b).isValid() ? moment(b).unix() : 0;
      //   return dateA - dateB;
      // },
      // renderCell: (params) => {
      //   const date = params.row.paidon;
      //   return date && moment(date).isValid()
      //     ? moment(date).format("DD-MM-YYYY")
      //     : "-";
      // },
    },
    {
      field: "paymentmode",
      headerName: "Payment Mode",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "discount",
      headerName: " Discount",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "balancedue",
      headerName: "Balance Due",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
  ];

  const BillingColumns = [
    {
      field: "ids",
      headerName: "S.No",
      sortable: false,
      width: 100,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "customerName",
      headerName: "Customer Name/ID",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "refname",
      headerName: " Ref Name",
      sortable: false,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "mobilenumber",
      headerName: " Mobile Number",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "location",
      headerName: "Location",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "orderrefid",
      headerName: " Order Reference ID",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const handleClick = () => {
          customerPrint(params.row.order_uuid);
        };
        return (
          <div style={{ cursor: "pointer" }} onClick={handleClick}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "invoiceDate",
      headerName: "Invoice Date",
      width: 150,
      headerAlign: "center",
      align: "center",
      // valueFormatter: (value) => {
      //   formatDate(value);
      // },
      // sortComparator: (a, b) => moment(a).unix() - moment(b).unix(),
      // renderCell: (params) =>
      //   moment(params.row.invoiceDate).format("DD-MM-YYYY"),
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const handleClick = () => {
          invoicePrint(params.row.id);
        };
        return (
          <div style={{ cursor: "pointer" }} onClick={handleClick}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "invoiceamount",
      headerName: "Invoice Amount",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "orderStatus",
      headerName: "Status",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
  ];

  const TaxTabColumns = [
    {
      field: "ids",
      headerName: "S.No",
      sortable: false,
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "invoice_id",
      headerName: "Invoice Number",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const handleClick = () => {
          invoicePrint(params.row.id);
        };
        return (
          <div style={{ cursor: "pointer" }} onClick={handleClick}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: " Invoice Date",
      width: 150,
      headerAlign: "center",
      align: "center",
      // valueFormatter: (value) => {
      //   formatDate(value);
      // },
      // sortComparator: (a, b) => moment(a).unix() - moment(b).unix(),
      // renderCell: (params) =>
      //   moment(params.row.created_at).format("DD-MM-YYYY"),
    },
    {
      field: "customerName",
      headerName: "Customer Name/ID",
      sortable: false,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "refName",
      headerName: "Ref Name",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "gsttype",
      headerName: "GST Type",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "sub_total",
      headerName: "Taxable Value",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "c_gst",
      headerName: "CGST",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "s_gst",
      headerName: "SGST",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "igst",
      headerName: "IGST",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "net_amount",
      headerName: "Invoice Value",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
  ];

  const DeliveryFollowTabColumns = [
    {
      field: "ids",
      headerName: "S.No",
      sortable: false,
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "order_id",
      headerName: "Order ID",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const handleClick = () => {
          customerPrint(params.row.orderUUID);
        };
        return (
          <div style={{ cursor: "pointer" }} onClick={handleClick}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "customerName",
      headerName: " Customer Name/ID",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "referenceName",
      headerName: "Reference Name",
      sortable: false,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: hideMobileNumber ? "" : "mobileNumber",
      headerName: "Mobile Number",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      hide: hideMobileNumber,
    },
    {
      field: "particulars",
      headerName: "Particulars",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "color",
      headerName: "Color",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "master",
      headerName: "Master",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "tailor",
      headerName: "Tailor",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "deliverydate",
      headerName: "Delivery Date",
      sortable: true,
      width: 150,
      headerAlign: "center",
      align: "center",
      // renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
  ];

  const classes = useStyles();
  const exportNames = [
    "DeliveryFollowUpReport",
    "CollectionReport",
    "BillingReport",
    "TaxReport",
  ];
  const handleFilterDelivery = () => {
    setPaginationModeldel({
      page: 0,
      pageSize: 10,
    });

    // Destructure the updated pagination model
    const { page, pageSize } = paginationModeldel;
    if (!_.isEmpty(startDatedel) || (!_.isEmpty(endDatedel) && value === 0)) {
      getAllDeliveryReportsList(
        paginationModeldel?.page,
        paginationModeldel?.pageSize,
        sortBy,
        sortOrder,
        startDatedel,
        endDatedel,
        datedel
      );
    }
    // triggerOrdersList;
    const filteredSearchParamsdel = Object.fromEntries(
      Object.entries(searchParamsdel).filter(
        ([_, value]) => value !== undefined
      )
    );

    setTriggerOrdersListdel(Math.random());
    setFilteredSearchParamsdel(filteredSearchParamsdel);
    setFilterDel(true);
  };
  useEffect(() => {
    if (!_.isEmpty(startDatedel) || !_.isEmpty(endDatedel)) {
      setDatedel("true");
      setFilterDel("true");
    }
  }, [endDatedel, startDatedel]);
  const handleResetDelivery = () => {
    setFilterTypedel("");
    setStartDatedel(null);
    setEndDatedel(null);
  };

  const handleFilterCollection = () => {
    setPaginationModelcol({
      page: 0,
      pageSize: 10,
    });

    // Destructure the updated pagination model
    const { page, pageSize } = paginationModelcol;
    if (!_.isEmpty(startDatecol) || (!_.isEmpty(endDatecol) && value === 1)) {
      getAllCollection(
        paginationModelcol?.page,
        paginationModelcol?.pageSize,
        sortBy,
        sortOrder,
        startDatecol,
        endDatecol,
        datecol
      );
    }
    const filteredSearchParamscol = Object.fromEntries(
      Object.entries(searchParamscol).filter(
        ([_, value]) => value !== undefined
      )
    );

    setTriggerOrdersListcol(Math.random());
    setFilteredSearchParamscol(filteredSearchParamscol);
    setFilterCol("true");
  };

  useEffect(() => {
    if (!_.isEmpty(startDatecol) || !_.isEmpty(endDatecol)) {
      setDatecol("true");
      setFilterCol("true");
    }
  }, [endDatecol, startDatecol]);
  const handleResetCollection = () => {
    setFilterTypecol("");
    setStartDatecol(null);
    setEndDatecol(null);
  };
  const handleFilterBilling = () => {
    setPaginationModelbill({
      page: 0,
      pageSize: 10,
    });

    // Destructure the updated pagination model
    const { page, pageSize } = paginationModelbill;
    if (!_.isEmpty(startDatebil) || (!_.isEmpty(endDatebil) && value === 2)) {
      getAllBilling(
        paginationModelbill?.page,
        paginationModelbill?.pageSize,
        sortBy,
        sortOrder,
        startDatebil,
        endDatebil,
        datebill
      );
    }
    const filteredSearchParamsbill = Object.fromEntries(
      Object.entries(searchParamsbill).filter(
        ([_, value]) => value !== undefined
      )
    );

    setTriggerOrdersListbill(Math.random());
    setFilteredSearchParamsbill(filteredSearchParamsbill);
    setFilterBill("true");
  };
  useEffect(() => {
    if (!_.isEmpty(startDatebil) || !_.isEmpty(endDatebil)) {
      setDatebill("true");
      setFilterBill("true");
    }
  }, [endDatebil, startDatebil]);

  const handleResetBilling = () => {
    setFilterTypebil("");
    setStartDatebil(null);
    setEndDatebil(null);
  };
  const handleFilterTax = () => {
    setPaginationModeltax({
      page: 0,
      pageSize: 10,
    });

    // Destructure the updated pagination model
    const { page, pageSize } = paginationModeltax;
    if (!_.isEmpty(startDatetax) || (!_.isEmpty(endDatetax) && value === 3)) {
      getAllTax(
        paginationModeltax?.page,
        paginationModeltax?.pageSize,
        sortBy,
        sortOrder,
        startDatetax,
        endDatetax,
        datetax
      );
    }
    const filteredSearchParamstax = Object.fromEntries(
      Object.entries(searchParamstax).filter(
        ([_, value]) => value !== undefined
      )
    );

    setTriggerOrdersListtax(Math.random());
    setFilteredSearchParamstax(filteredSearchParamstax);
    setFilterTax("true");
  };
  useEffect(() => {
    if (!_.isEmpty(startDatetax) || !_.isEmpty(endDatetax)) {
      setDatetax("true");
      setFilterTax("true");
    }
  }, [endDatetax, startDatetax]);
  const handleResetTax = () => {
    setFilterTypetax("");
    setStartDatetax(null);
    setEndDatetax(null);
  };
  const resetAllcus = () => {};
  const resetAllOrder = () => {};
  const resetAlldel = () => {
    if (value === 0) {
      getAllDeliveryReportsList(
        paginationModeldel?.page,
        paginationModeldel?.pageSize,
        sortBy,
        sortOrder
      );
    }
    setDatedel(false);
    setFilterTypedel("");
    setStartDatedel(null);
    setEndDatedel(null);
    setFiltersApplied(false);
    setFilterDel(false);
    setSearchParamsdel(defaultSearchParamsdel);
    setTriggerOrdersListdel(Math.random());
  };
  const resetAllcol = () => {
    if (value === 1) {
      getAllCollection(
        paginationModelcol?.page,
        paginationModelcol?.pageSize,
        sortBy,
        sortOrder
      );
    }
    setDatecol(false);
    setFilterTypecol("");
    setStartDatecol(null);
    setEndDatecol(null);
    setFiltersApplied(false);
    setFilterCol(false);
    setSearchParamscol(defaultSearchParamscol);
    setTriggerOrdersListcol(Math.random());
  };
  const resetAllbill = () => {
    if (value === 2) {
      getAllBilling(
        paginationModelbill?.page,
        paginationModelbill?.pageSize,
        sortBy,
        sortOrder
      );
    }
    setDatebill(false);
    setFilterTypebil("");
    setStartDatebil(null);
    setEndDatebil(null);
    setFiltersApplied(false);
    setFilterBill(false);
    setSearchParamsbill(defaultSearchParamsbill);
    setTriggerOrdersListbill(Math.random());
  };
  const resetAlltax = () => {
    if (value === 3) {
      getAllTax(
        paginationModeltax?.page,
        paginationModeltax?.pageSize,
        sortBy,
        sortOrder
      );
    }
    setDatetax(false);
    setFilterTypetax("");
    setStartDatetax(null);
    setEndDatetax(null);
    setFiltersApplied(false);
    setFilterTax(false);
    setSearchParamstax(defaultSearchParamstax);
    setTriggerOrdersListtax(Math.random());
  };
  // 1console.log("mo", hideMobileNumber);
  const getAllDeliveryExport = useCallback(
    (hideMobileNumber) => {
      dispatch(showLoader());
      const date_filter = true;
      const startDate = moment(startDatedel).format("YYYY-MM-DD");
      const endDate = moment(endDatedel).format("YYYY-MM-DD");
      return orderApi
        .deliverFollowUpReportsExport(
          searchParamsdel,
          rowCountdel,
          date_filter,
          startDate,
          endDate
        )
        .then(
          (res) => {
            dispatch(hideLoader());
            const data = res.data.data;
            const processedData = data.map((item, index) => {
              const {
                particulars,
                cust_id,
                customer_mobile_number,
                customer_name,
                customer_ref_name,
                delivery_date,
                second_delivery_date,
              } = item;

              // Format `particulars` - assuming it is a string as per your example
              let formattedParticulars =
                typeof particulars === "string"
                  ? particulars
                  : JSON.stringify(particulars);

              let particularItem = "-";
              let masterItem = "-";
              let tailorItem = "-";
              let colorItem = "-";

              try {
                const parsedParticulars = JSON.parse(formattedParticulars);
                if (Array.isArray(parsedParticulars)) {
                  particularItem = parsedParticulars
                    .map((item) => item.particular)
                    .filter(Boolean)
                    .join(" | ");

                  masterItem = parsedParticulars
                    .map((item) => item.master)
                    .filter(Boolean)
                    .join(" | ");

                  tailorItem = parsedParticulars
                    .map((item) => item.tailor)
                    .filter(Boolean)
                    .join(" | ");

                  colorItem = parsedParticulars
                    .map((item) => item.color)
                    .filter(Boolean)
                    .join(" | ");
                }
              } catch (error) {
                console.error("Error parsing JSON:", error);
              }

              // Determine delivery dates
              const deliveryDatesSet = new Set();
              try {
                const parsedParticulars = JSON.parse(formattedParticulars);
                if (Array.isArray(parsedParticulars)) {
                  parsedParticulars.forEach((particular) => {
                    if (
                      particular.deltype === "1st Delivery Date" &&
                      delivery_date
                    ) {
                      deliveryDatesSet.add(delivery_date);
                    } else if (
                      particular.deltype === "2nd Delivery Date" &&
                      second_delivery_date
                    ) {
                      deliveryDatesSet.add(second_delivery_date);
                    }
                  });
                }
              } catch (error) {
                console.error("Error parsing JSON for delivery dates:", error);
              }

              const deliveryDates = Array.from(deliveryDatesSet).join(" | ");
              // 1console.log("date", deliveryDates);

              return {
                s_no: index + 1,
                order_id: item.order_id,
                customer_id: cust_id,
                customer_name_ref_name: `${customer_name} (${customer_ref_name})`,
                customer_mobile: hideMobileNumber ? "" : customer_mobile_number,
                particulars: particularItem,
                color: colorItem,
                master: masterItem,
                tailor: tailorItem,
                delivery_dates: moment(deliveryDates).format("DD-MM-YYYY"), // Add delivery dates here
              };
            });
            return processedData;
          },
          (err) => {
            dispatch(hideLoader());
            return [];
          }
        );
    },
    [dispatch, searchParamsdel, rowCountdel, startDatedel, endDatedel]
  );

  const getAllCollectionExport = useCallback(() => {
    dispatch(showLoader());
    const sortField =
      sortOrder && sortOrder.length > 0 ? sortOrder[0]?.field : undefined;
    let date_filter;
    if (datecol === "true") {
      date_filter = filterTypecol;
    } else {
      date_filter = sortField; // Assuming sortField is appropriate here
    }
    const startDate = moment(startDatecol).format("YYYY-MM-DD");
    const endDate = moment(endDatecol).format("YYYY-MM-DD");
    return orderApi
      .collectionReportsExport(
        searchParamscol,
        rowCountcol,
        date_filter,
        startDate,
        endDate
      )
      .then(
        (res) => {
          dispatch(hideLoader());
          const collection = res.data.data;
          const processedData = collection.map((item, index) => {
            const {
              particulars,
              payment_detail,
              cust_id,
              customer_mobile_number,
              customer_name,
              customer_ref_name,
              delivery_date,
              second_delivery_date,
              customer_location,
              bill_amount,
              order_date,
            } = item;

            // Helper function to safely parse JSON and handle errors
            const safeParseJson = (data) => {
              try {
                return JSON.parse(data);
              } catch (error) {
                console.error("Error parsing JSON:", error);
                return []; // Return an empty array in case of error
              }
            };

            // Process payment details
            let paymentItem = "-";
            let paymentdateItem = "-";
            let paymentmodeItem = "-";
            let paymentdiscountItem = "-";
            let paymentbalanceItem = "-";

            // Ensure payment_detail is an array or set to empty array if not
            const paymentArray = Array.isArray(payment_detail)
              ? payment_detail
              : [payment_detail];

            const isValidDate = (dateString) => {
              const date = new Date(dateString);
              return !isNaN(date.getTime());
            };

            if (Array.isArray(paymentArray)) {
              paymentItem = paymentArray
                .map((item) => item.amount ?? "-")
                .join(" | ");

              paymentdateItem = paymentArray
                .map((item) => {
                  const date = item.payment_date;
                  return date && isValidDate(date)
                    ? moment(date).format("DD-MM-YYYY")
                    : "-";
                })
                .join(" | ");

              paymentmodeItem = paymentArray
                .map((item) => item.payment_mode ?? "-")
                .join(" | ");

              paymentdiscountItem = paymentArray
                .map((item) => item.discount ?? "-")
                .join(" | ");

              paymentbalanceItem = paymentArray
                .map((item) => item.due_amount ?? "-")
                .join(" | ");
            }

            // Process particulars
            let particularItem = "-";
            let masterItem = "-";
            let tailorItem = "-";
            let colorItem = "-";

            // Ensure particulars is an array or set to empty array if not
            const particularsArray = Array.isArray(particulars)
              ? particulars
              : [particulars];

            if (Array.isArray(particularsArray)) {
              particularItem = particularsArray
                .map((item) => item.particular || "-")
                .join(" | ");
              masterItem = particularsArray
                .map((item) => item.master || "-")
                .join(" | ");
              tailorItem = particularsArray
                .map((item) => item.tailor || "-")
                .join(" | ");
              colorItem = particularsArray
                .map((item) => item.color || "-")
                .join(" | ");
            }

            return {
              s_no: index + 1,
              order_id: item.order_id,
              order_date: moment(order_date).format("DD-MM-YYYY"),
              customer_id: item.cust_id,
              customer_name_ref_name: `${customer_name} (${customer_ref_name})`,
              customer_mobile: customer_mobile_number,
              location: customer_location,
              order_amount: bill_amount,
              paid_amount: paymentItem,
              paid_on: paymentdateItem,
              paid_mode: paymentmodeItem,
              discount: paymentdiscountItem,
              balance: paymentbalanceItem,
            };
          });
          return processedData;
        },
        (err) => {
          dispatch(hideLoader());
          console.error("Error fetching collection reports:", err);
          return [];
        }
      );
  }, [
    dispatch,
    searchParamscol,
    rowCountcol,
    startDatecol,
    endDatecol,
    filterTypecol,
  ]);

  const getAllBillExport = useCallback(() => {
    dispatch(showLoader());

    const cancelled = true;
    const startDate = moment(startDatebil).format("YYYY-MM-DD");
    const endDate = moment(endDatebil).format("YYYY-MM-DD");
    return invoiceApi
      .getAllInvoiceExport(
        cancelled,
        searchParamsbill,
        rowCountbill,
        startDate,
        endDate
      )
      .then(
        (res) => {
          dispatch(hideLoader());
          // 1console.log(searchParamsbill);
          const data = res.data.data;
          const processedData = data.map((item, index) => {
            const {} = item;

            return {
              s_no: index + 1,
              order_id: item?.orderById?.order_id,
              customer_id: item?.orderById?.customer?.cust_id,
              customer_name_ref_name: `${item?.orderById?.customer?.user?.name} (${item?.orderById?.customer?.ref_name})`,
              invoice_date: moment(item?.created_at).format("DD-MM-YYYY"),
              invoice_number: item?.invoice_id,
              invoice_amount: item?.net_amount,
              mobile_number: item?.orderById?.customer?.user?.mobile_number,
              location: item?.orderById?.customer?.location,
              status:
                item?.orderById?.order_status === "cancelled"
                  ? "Cancelled"
                  : "Active",
            };
          });
          return processedData;
        },
        (err) => {
          dispatch(hideLoader());
          return [];
        }
      );
  }, [dispatch, searchParamsbill, rowCountbill, startDatebil, endDatebil]);
  const getAllTaxExport = useCallback(() => {
    dispatch(showLoader());
    const cancelled = false;
    const startDate = moment(startDatetax).format("YYYY-MM-DD");
    const endDate = moment(endDatetax).format("YYYY-MM-DD");
    return invoiceApi
      .getAllInvoiceExports(
        cancelled,
        searchParamstax,
        rowCounttax,
        startDate,
        endDate
      )
      .then(
        (res) => {
          dispatch(hideLoader());
          const data = res.data.data;
          const processedData = data.map((item, index) => {
            const {} = item;

            return {
              s_no: index + 1,
              invoice_number: item?.invoice_id,
              invoice_date: moment(item?.created_at).format("DD-MM-YYYY"),
              customer_id: item?.orderById?.customer?.cust_id,
              customer_name_ref_name: `${item?.orderById?.customer?.user?.name} (${item?.orderById?.customer?.ref_name})`,
              gst_type: "B2C",
              taxable: item?.sub_total,
              c_gst: item?.c_gst,
              s_gst: item?.s_gst,
              i_gst: "-",
              invoice_value: item?.net_amount,
            };
          });
          return processedData;
        },
        (err) => {
          dispatch(hideLoader());
          return [];
        }
      );
  }, [dispatch, searchParamstax, rowCounttax, startDatetax, endDatetax]);

  const handleStartDateChangedel = (date) => {
    setStartDatedel(date);
    if (endDatedel && date && date.isAfter(endDatedel)) {
      setEndDatedel(null); // Optionally reset end date if it's before start date
    }
  };
  const handleStartDateChangecol = (date) => {
    setStartDatecol(date);
    if (endDatecol && date && date.isAfter(endDatecol)) {
      setEndDatecol(null); // Optionally reset end date if it's before start date
    }
  };
  const handleStartDateChangebil = (date) => {
    setStartDatebil(date);
    if (endDatebil && date && date.isAfter(endDatebil)) {
      setEndDatebil(null); // Optionally reset end date if it's before start date
    }
  };
  const handleStartDateChangetax = (date) => {
    setStartDatetax(date);
    if (endDatetax && date && date.isAfter(endDatetax)) {
      setEndDatetax(null); // Optionally reset end date if it's before start date
    }
  };

  return (
    <Card elevation={5} className={classes.cardRadius}>
      <CardContent>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                {isMobile ? (
                  <Grid container className={classes.tabsGrid}>
                    <Grid item xs={6} className={classes.tabItem}>
                      <Box
                        component="div"
                        onClick={() => {
                          handleChange(null, 0);
                        }}
                        className={`${classes.tabBox} ${
                          value === 0 ? classes.activeTab : ""
                        }`}
                      >
                        <Tab label="Delivery Follow&nbsp;&#8209;&nbsp;Ups" />
                      </Box>
                    </Grid>
                    <Grid item xs={6} className={classes.tabItem}>
                      <Box
                        component="div"
                        onClick={() => {
                          handleChange(null, 1);
                        }}
                        className={`${classes.tabBox} ${
                          value === 1 ? classes.activeTab : ""
                        }`}
                      >
                        <Tab label="Collection" />
                      </Box>
                    </Grid>
                    <Grid item xs={6} className={classes.tabItem}>
                      <Box
                        component="div"
                        onClick={() => {
                          handleChange(null, 2);
                        }}
                        className={`${classes.tabBox} ${
                          value === 2 ? classes.activeTab : ""
                        }`}
                      >
                        <Tab label="Billing" />
                      </Box>
                    </Grid>
                    <Grid item xs={6} className={classes.tabItem}>
                      <Box
                        component="div"
                        onClick={() => {
                          handleChange(null, 3);
                        }}
                        className={`${classes.tabBox} ${
                          value === 3 ? classes.activeTab : ""
                        }`}
                      >
                        <Tab label="Tax" />
                      </Box>
                    </Grid>
                  </Grid>
                ) : (
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    classes={{ root: classes.customTabs }}
                  >
                    <Tab label="Delivery Follow-Ups" />
                    <Tab label="Collection" />
                    <Tab label="Billing" />
                    <Tab label="Tax" />
                  </Tabs>
                )}
              </Box>
              {value === 0 && (
                <div>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <FormControlLabel
                      control={<Checkbox size="small" name="rememberMe" />}
                      onChange={handleCheckboxChange}
                      name="hideMobileNumber"
                      label="Export without mobile number"
                    />
                  </Box>
                  <Grid container spacing={2} my={1}>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        id="order-id"
                        fullWidth
                        label="Order ID"
                        variant="outlined"
                        value={searchParamsdel.order_id || ""}
                        onChange={(e) =>
                          handleFieldChangedel("order_id", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        id="customer-name"
                        label="Customer Name"
                        fullWidth
                        variant="outlined"
                        value={searchParamsdel["user.name"] || ""}
                        onChange={(e) =>
                          handleFieldChangedel("user.name", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        id="ref-name"
                        fullWidth
                        label="Ref Name"
                        variant="outlined"
                        value={searchParamsdel["customer.ref_name"] || ""}
                        onChange={(e) =>
                          handleFieldChangedel(
                            "customer.ref_name",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        id="customer-mobile"
                        fullWidth
                        label="Customer Mobile Number"
                        variant="outlined"
                        value={searchParamsdel["user.mobile_number"] || ""}
                        onChange={(e) =>
                          handleFieldChangedel(
                            "user.mobile_number",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            label="Start Date"
                            name="startDatedel"
                            value={startDatedel}
                            onChange={handleStartDateChangedel}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            label="End Date"
                            name="endDatedel"
                            value={endDatedel}
                            minDate={startDatedel} // Set minDate based on start date
                            onChange={(date) => setEndDatedel(date)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sm={12}
                      sx={{ margin: "auto", mx: "auto" }}
                    >
                      <Button
                        variant="contained"
                        className={classes.btn}
                        size="medium"
                        onClick={handleFilterDelivery}
                      >
                        Search
                      </Button>
                      {filterDel && (
                        <Button
                          variant="contained"
                          size="medium"
                          sx={{ ml: 1 }}
                          onClick={resetAlldel}
                        >
                          Reset
                        </Button>
                      )}
                    </Grid>
                  </Grid>

                  <CustomerDatagrid
                    exportName={exportNames[value]}
                    rows={deliveryfollowupRows}
                    getRowId={(row) => row.id}
                    columns={DeliveryFollowTabColumns}
                    pagination
                    paginationMode="server"
                    rowCount={rowCount}
                    page={page}
                    pageSize={pageSize}
                    pageSizeOptions={[10, 25, 50, 100]}
                    filterDel={filterDel}
                    filterOrder={filterOrder}
                    setFilterOrder={setFilterOrder}
                    setFilterDel={setFilterDel}
                    filterCus={filterCus}
                    setFilterCus={setFilterCus}
                    filterCol={filterCol}
                    setFilterCol={setFilterCol}
                    filterBill={filterBill}
                    setFilterBill={setFilterBill}
                    filterTax={filterTax}
                    setFilterTax={setFilterTax}
                    resetAllOrder={resetAllOrder}
                    resetAllcus={resetAllcus}
                    resetAlldel={resetAlldel}
                    resetAllcol={resetAllcol}
                    resetAllbill={resetAllbill}
                    resetAlltax={resetAlltax}
                    filtersApplied={filtersApplied}
                    setFiltersApplied={setFiltersApplied}
                    paginationModel={paginationModeldel}
                    onPaginationModelChange={(params) =>
                      setPaginationModeldel(params)
                    }
                    onPageSizeChange={(params) => setPageSize(params.pageSize)}
                    onSortModelChange={(params) =>
                      setSortOrder(params?.[0]?.sort)
                    }
                    exportCallback={getAllDeliveryExport}
                    hideMobileNumber={hideMobileNumber}
                  />
                </div>
              )}
              {value === 1 && (
                <div>
                  <Grid container spacing={2} my={1}>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        id="order-id"
                        fullWidth
                        label="Order ID"
                        variant="outlined"
                        value={searchParamscol.order_id || ""}
                        onChange={(e) =>
                          handleFieldChangecol("order_id", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        id="customer-name"
                        label="Customer Name"
                        fullWidth
                        variant="outlined"
                        value={searchParamscol["user.name"] || ""}
                        onChange={(e) =>
                          handleFieldChangecol("user.name", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        id="ref-name"
                        fullWidth
                        label="Ref Name"
                        variant="outlined"
                        value={searchParamscol["customer.ref_name"] || ""}
                        onChange={(e) =>
                          handleFieldChangecol(
                            "customer.ref_name",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        id="customer-mobile"
                        fullWidth
                        label="Customer Mobile Number"
                        variant="outlined"
                        value={searchParamscol["user.mobile_number"] || ""}
                        onChange={(e) =>
                          handleFieldChangecol(
                            "user.mobile_number",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        id="location"
                        fullWidth
                        label="Location"
                        variant="outlined"
                        value={searchParamscol["customer.location"] || ""}
                        onChange={(e) =>
                          handleFieldChangecol(
                            "customer.location",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        select
                        fullWidth
                        label="Payment Mode"
                        value={searchParamscol["payment.payment_mode"] || ""}
                        onChange={(e) =>
                          handleFieldChangecol(
                            "payment.payment_mode",
                            e.target.value
                          )
                        }
                      >
                        <MenuItem value="cash">Cash</MenuItem>
                        <MenuItem value="bank">Bank</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        select
                        fullWidth
                        label="Filter By"
                        value={filterTypecol}
                        onChange={(e) => setFilterTypecol(e.target.value)}
                      >
                        <MenuItem value="order_date">Order Date</MenuItem>
                        <MenuItem value="payment_date">Paid on</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            label="Start Date"
                            name="startDatecol"
                            value={startDatecol}
                            onChange={handleStartDateChangecol}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            label="End Date"
                            name="endDatecol"
                            value={endDatecol}
                            minDate={startDatecol} // Set minDate based on start date
                            onChange={(date) => setEndDatecol(date)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sm={12}
                      sx={{ margin: "auto", mx: "auto" }}
                    >
                      <Button
                        variant="contained"
                        className={classes.btn}
                        size="medium"
                        onClick={handleFilterCollection}
                      >
                        Search
                      </Button>
                      {filterCol && (
                        <Button
                          variant="contained"
                          size="medium"
                          sx={{ ml: 1 }}
                          onClick={resetAllcol}
                        >
                          Reset
                        </Button>
                      )}
                    </Grid>
                  </Grid>

                  <CustomerDatagrid
                    exportName={exportNames[value]}
                    rows={collectionRows}
                    columns={columns}
                    getRowId={(row) => row.id}
                    pagination
                    paginationMode="server"
                    rowCount={rowCount}
                    page={paginationModelcol?.page}
                    pageSize={paginationModelcol?.pageSize}
                    pageSizeOptions={[10, 25, 50, 100]}
                    filterCol={filterCol}
                    setFilterCol={setFilterCol}
                    filterCus={filterCus}
                    setFilterCus={setFilterCus}
                    filterDel={filterDel}
                    setFilterDel={setFilterDel}
                    filterOrder={filterOrder}
                    setFilterOrder={setFilterOrder}
                    resetAllOrder={resetAllOrder}
                    filterBill={filterBill}
                    setFilterBill={setFilterBill}
                    filterTax={filterTax}
                    setFilterTax={setFilterTax}
                    resetAllcus={resetAllcus}
                    resetAlldel={resetAlldel}
                    resetAllcol={resetAllcol}
                    resetAllbill={resetAllbill}
                    resetAlltax={resetAlltax}
                    filtersApplied={filtersApplied}
                    setFiltersApplied={setFiltersApplied}
                    paginationModel={paginationModelcol}
                    onPaginationModelChange={(params) => {
                      setPaginationModelcol(params);
                    }}
                    onPageSizeChange={(params) => setPageSize(params.pageSize)}
                    onSortModelChange={(params) => setSortOrder(params)}
                    exportCallback={getAllCollectionExport}
                  />
                </div>
              )}
              {value === 2 && (
                <div>
                  <Grid container spacing={2} my={1}>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        id="invoice-id"
                        fullWidth
                        label="Invoice Number"
                        variant="outlined"
                        value={searchParamsbill.invoice_id || ""}
                        onChange={(e) =>
                          handleFieldChangebill("invoice_id", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        id="customer-name"
                        label="Customer Name"
                        fullWidth
                        variant="outlined"
                        value={searchParamsbill["user.name"] || ""}
                        onChange={(e) =>
                          handleFieldChangebill("user.name", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        id="ref-name"
                        fullWidth
                        label="Ref Name"
                        variant="outlined"
                        value={searchParamsbill["customer.ref_name"] || ""}
                        onChange={(e) =>
                          handleFieldChangebill(
                            "customer.ref_name",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        id="customer-mobile"
                        fullWidth
                        label="Customer Mobile Number"
                        variant="outlined"
                        value={searchParamsbill["user.mobile_number"] || ""}
                        onChange={(e) =>
                          handleFieldChangebill(
                            "user.mobile_number",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sm={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            label="Start Date"
                            name="startDatebil"
                            value={startDatebil}
                            onChange={handleStartDateChangebil}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} sm={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            label="End Date"
                            name="endDatebil"
                            value={endDatebil}
                            minDate={startDatebil} // Set minDate based on start date
                            onChange={(date) => setEndDatebil(date)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sm={12}
                      sx={{ margin: "auto", mx: "auto" }}
                    >
                      <Button
                        variant="contained"
                        className={classes.btn}
                        size="medium"
                        onClick={handleFilterBilling}
                      >
                        Search
                      </Button>
                      {filterBill && (
                        <Button
                          variant="contained"
                          size="medium"
                          sx={{ ml: 1 }}
                          onClick={resetAllbill}
                        >
                          Reset
                        </Button>
                      )}
                    </Grid>
                  </Grid>

                  <CustomerDatagrid
                    exportName={exportNames[value]}
                    rows={BillingRows}
                    getRowId={(row) => row.id}
                    columns={BillingColumns}
                    pagination
                    paginationMode="server"
                    rowCount={rowCount}
                    page={page}
                    pageSize={pageSize}
                    pageSizeOptions={[10, 25, 50, 100]}
                    filterBill={filterBill}
                    setFilterBill={setFilterBill}
                    filterCus={filterCus}
                    setFilterCus={setFilterCus}
                    resetAllOrder={resetAllOrder}
                    filterCol={filterCol}
                    setFilterCol={setFilterCol}
                    filterDel={filterDel}
                    setFilterDel={setFilterDel}
                    filterOrder={filterOrder}
                    setFilterOrder={setFilterOrder}
                    filterTax={filterTax}
                    setFilterTax={setFilterTax}
                    resetAllcus={resetAllcus}
                    resetAlldel={resetAlldel}
                    resetAllcol={resetAllcol}
                    resetAllbill={resetAllbill}
                    resetAlltax={resetAlltax}
                    filtersApplied={filtersApplied}
                    setFiltersApplied={setFiltersApplied}
                    paginationModel={paginationModelbill}
                    onPaginationModelChange={(params) =>
                      setPaginationModelbill(params)
                    }
                    onPageSizeChange={(params) => setPageSize(params.pageSize)}
                    onSortModelChange={(params) =>
                      setSortOrder(params?.[0]?.sort)
                    }
                    exportCallback={getAllBillExport}
                  />
                </div>
              )}
              {value === 3 && (
                <div>
                  <Grid container spacing={2} my={1}>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        id="invoice-id"
                        fullWidth
                        label="Invoice Number"
                        variant="outlined"
                        value={searchParamstax.invoice_id || ""}
                        onChange={(e) =>
                          handleFieldChangetax("invoice_id", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        id="customer-name"
                        label="Customer Name"
                        fullWidth
                        variant="outlined"
                        value={searchParamstax["user.name"] || ""}
                        onChange={(e) =>
                          handleFieldChangetax("user.name", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        id="ref-name"
                        fullWidth
                        label="Ref Name"
                        variant="outlined"
                        value={searchParamstax["customer.ref_name"] || ""}
                        onChange={(e) =>
                          handleFieldChangetax(
                            "customer.ref_name",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3} sm={12}>
                      <TextField
                        id="customer-mobile"
                        fullWidth
                        label="Customer Mobile Number"
                        variant="outlined"
                        value={searchParamstax["user.mobile_number"] || ""}
                        onChange={(e) =>
                          handleFieldChangetax(
                            "user.mobile_number",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sm={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            label="Start Date"
                            name="startDatetax"
                            value={startDatetax}
                            onChange={handleStartDateChangetax}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} sm={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            label="End Date"
                            name="endDatetax"
                            value={endDatetax}
                            minDate={startDatetax} // Set minDate based on start date
                            onChange={(date) => setEndDatetax(date)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sm={12}
                      sx={{ margin: "auto", mx: "auto" }}
                    >
                      <Button
                        variant="contained"
                        className={classes.btn}
                        size="medium"
                        onClick={handleFilterTax}
                      >
                        Search
                      </Button>
                      {filterTax && (
                        <Button
                          variant="contained"
                          size="medium"
                          sx={{ ml: 1 }}
                          onClick={resetAlltax}
                        >
                          Reset
                        </Button>
                      )}
                    </Grid>
                  </Grid>

                  <CustomerDatagrid
                    exportName={exportNames[value]}
                    rows={TaxTabRows}
                    getRowId={(row) => row.id}
                    columns={TaxTabColumns}
                    pagination
                    paginationMode="server"
                    rowCount={rowCount}
                    page={page}
                    pageSize={pageSize}
                    pageSizeOptions={[10, 25, 50, 100]}
                    filterTax={filterTax}
                    setFilterTax={setFilterTax}
                    filterCus={filterCus}
                    setFilterCus={setFilterCus}
                    resetAllOrder={resetAllOrder}
                    filterCol={filterCol}
                    setFilterCol={setFilterCol}
                    filterBill={filterBill}
                    setFilterBill={setFilterBill}
                    filterDel={filterDel}
                    setFilterDel={setFilterDel}
                    filterOrder={filterOrder}
                    setFilterOrder={setFilterOrder}
                    resetAllcus={resetAllcus}
                    resetAlldel={resetAlldel}
                    resetAllcol={resetAllcol}
                    resetAllbill={resetAllbill}
                    resetAlltax={resetAlltax}
                    filtersApplied={filtersApplied}
                    setFiltersApplied={setFiltersApplied}
                    paginationModel={paginationModeltax}
                    onPaginationModelChange={(params) =>
                      setPaginationModeltax(params)
                    }
                    onPageSizeChange={(params) => setPageSize(params.pageSize)}
                    onSortModelChange={(params) =>
                      setSortOrder(params?.[0]?.sort)
                    }
                    exportCallback={getAllTaxExport}
                  />
                </div>
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
};

export default Billing;
