// WhatsAppShare.js
import React from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#f06666 !important", // Adjust background color as needed
    color: theme.palette.primary.contrastText,
  },
}));

const WhatsAppShare = ({ bill, name, due, billId }) => {
  const billUrl = `${window.location.origin}/whatsappprint/${billId}`;

  const message = `Namaste Ji \n
    We are from\n
    *Lakshitha The Designer Studio*\n
    Mogappair East Branch.\n
    *${name}* Dress is ready to collect, Kindly visit our studio for a Trial\n
    *NO DEBIT / CREDITS CARDS, ( ONLY - CASH, G-PAY, PHONEPE )*\n
    And the *bill amount is ₹${bill}/-*,\n
    *balance due is ₹${due}/-*.\n
    *Delivery Times :Mon to Thu 11am to 2pm / 5pm to 7pm Only (or) Saturday 11am to 7.30pm*\n
    *PLEASE NOTE : FRIDAY'S DESIGNER AVAILABLE AT TONDIARPET BRANCH\n
    SUNDAY HOLIDAY*\n
    *Please Book your Appointment Time of Visiting us to Avoid Waiting*\n
    Thank for your order, Welcome soon !\n
    ${billUrl}`;

  const handleShare = () => {
    const shareUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(shareUrl, "_blank");
  };

  const classes = useStyles();

  return (
    <div>
      <Button
        variant="contained"
        size="medium"
        sx={{
          mx: 0.5,
        }}
        className={classes.btn}
        startIcon={<WhatsAppIcon />}
        onClick={handleShare}
      >
        Send via WhatsApp
      </Button>
    </div>
  );
};

export default WhatsAppShare;
