import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Toolbar,
  IconButton,
  InputBase,
  MenuItem,
  Menu,
  Typography,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MoreIcon from "@mui/icons-material/MoreVert";
import Logo from "../../assets/img/logo.png";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { useNavigate } from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../../redux/loader/actions";
import { showSnackbar } from "../../redux/snackbar/actions";
import authApi from "../../services/auth";
import { setAuth } from "../../redux/common/actions";

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Navbar = ({ toggleSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const settings = () => {
    navigate("/settings");
  };

  const logout = () => {
    dispatch(showLoader());
    const data = {
      token: Cookies.get("token"),
    };
    const onSuccess = (res) => {
      dispatch(hideLoader());
      if (res?.data?.status === true) {
        dispatch(
          showSnackbar({
            message: res?.data?.msg || "Logged out successfully",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
          })
        );
      }
      dispatch(
        setAuth({
          isAuthenticated: false,
        })
      );
      Cookies.remove("token");
      Cookies.remove("email");
      Cookies.remove("password");
      Cookies.remove("shop_id");
      Cookies.remove("created_by");
      navigate("/");
    };
    const onFailure = (err) => {
      dispatch(hideLoader());
      dispatch(
        showSnackbar({
          message: err?.response?.data?.msg || "Failed to log out",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        })
      );
    };
    authApi.logout(data).then(onSuccess, onFailure);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={settings} sx={{ mx: 2 }}>
        <SettingsOutlinedIcon sx={{ mr: 2 }} /> Settings
      </MenuItem>
      <MenuItem onClick={logout} sx={{ mx: 2 }}>
        <ExitToAppOutlinedIcon sx={{ mr: 2 }} /> Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        onClick={settings}
        sx={{ mx: 2, display: "flex", alignItems: "center" }}
      >
        <IconButton size="large" aria-label="settings" color="inherit">
          <SettingsOutlinedIcon />
        </IconButton>
        <Typography sx={{ ml: 2 }}>Settings</Typography>
      </MenuItem>
      <MenuItem
        onClick={logout}
        sx={{ mx: 2, display: "flex", alignItems: "center" }}
      >
        <IconButton size="large" aria-label="logout" color="inherit">
          <LogoutOutlinedIcon />
        </IconButton>
        <Typography sx={{ ml: 2 }}>Logout</Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" color="primary" elevation={4}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={toggleSidebar}
            >
              <MenuIcon />
            </IconButton>
            <Box
              component="img"
              src={Logo}
              alt="logo"
              sx={{
                width: "35px",
                height: "auto",
                maxWidth: "100%",
              }}
            />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
    </>
  );
};

export default Navbar;
