import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  CardActions,
  CardContent,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Button,
  Stack,
} from "@mui/material";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@material-ui/core";
import { styled } from "@mui/system";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Logo from "../../../assets/img/logo1.png";
import { useSelector } from "react-redux";
import orderApi from "../../../services/order";

const useStyles = makeStyles((theme) => ({
  cardRadius: {
    borderRadius: "0 15px 15px 0 !important",
    borderLeft: "3px solid #f06666",
  },
  tableContainer: {
    marginBottom: theme.spacing(2),
  },
  fullwidthDivider: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

const PrintButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#f06666",
  marginBottom: theme.spacing(2),
  "@media print": {
    display: "none",
  },
  "&:hover": {
    backgroundColor: "#f06666",
    boxShadow: "none",
  },
}));

const Workorder = () => {
  const [printData, setPrintData] = useState(null);
  const { orderId } = useParams();

  const getOrder = () => {
    const onSuccess = (res) => {
      setPrintData(res?.data?.data);
    };
    const onFailure = () => {};
    orderApi.getOrderCopy(orderId).then(onSuccess).catch(onFailure);
  };

  useEffect(() => {
    getOrder();
  }, [orderId]);

  const classes = useStyles();
  const componentRef = useRef();
  const navigate = useNavigate();

  const handlePrint = () => {
    window.print();
    window.location.reload();
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.orderId);
  const latestOrderString = queryParams.get("latestOrder");
  const latestOrder = latestOrderString;

  return (
    <>
      <CardContent ref={componentRef}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={6}>
            <img
              src={Logo}
              alt="Logo"
              style={{
                width: "50px",
                height: "auto",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} container justifyContent="flex-end">
            <PrintButton
              variant="contained"
              size="medium"
              onClick={handlePrint}
              sx={{ display: "flex", alignItems: "center", mx: 1 }}
            >
              <LocalPrintshopOutlinedIcon sx={{ marginRight: 1 }} />
              Print
            </PrintButton>
          </Grid>

          <Grid container mt={1}>
            <Grid item sm={6}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography gutterBottom>
                    <strong> Order ID:</strong>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography>{printData?.order_id}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography gutterBottom>
                    <strong> Customer ID:</strong>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography>{printData?.customer?.cust_id}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography gutterBottom>
                    <strong>Name:</strong>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography>
                    {printData?.customer?.user?.name}
                    {printData?.customer?.ref_name &&
                      `(${printData?.customer?.ref_name})`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={6}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography gutterBottom>
                    <strong>Master:</strong>
                  </Typography>
                </Grid>
                <Grid item sm={6}></Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography gutterBottom>
                    <strong>Tailor:</strong>
                  </Typography>
                </Grid>
                <Grid item sm={6}></Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.fullwidthDivider} sx={{ mt: 1 }} />

          <TableContainer
            sx={{ mt: 1 }}
            component={Paper}
            className={classes.tableContainer}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Particulars
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Colors
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Lining
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Quantity
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    "&:last-child td": {
                      borderRight: "1px solid #a0a6a859",
                    },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 2 }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 2 }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 2 }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 2 }}
                  ></TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    "&:last-child td": {
                      borderRight: "1px solid #a0a6a859",
                    },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 2 }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 2 }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 2 }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 2 }}
                  ></TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    "&:last-child td": {
                      borderRight: "1px solid #a0a6a859",
                    },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 2 }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 2 }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 2 }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 2 }}
                  ></TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    "&:last-child td": {
                      borderRight: "1px solid #a0a6a859",
                    },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 2 }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 2 }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 2 }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 2 }}
                  ></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Box>
            <Typography variant="h6">Blouse</Typography>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Bl
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Sh
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Sl
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    R
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Arm
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Up
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Ch
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.blouse_bi}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.blouse_sh}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.blouse_si}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.blouse_r}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.blouse_arm}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.blouse_up}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.blouse_ch}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer component={Paper} sx={{ mt: 1 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Yoke
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Waist
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Bust
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    F
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    B
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    P2P
                  </TableCell>
                  <TableCell align="center">Cut</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.blouse_yoke}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.blouse_waist}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.blouse_bust}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.blouse_f}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.blouse_b}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.blouse_p2p}
                  </TableCell>
                  <TableCell align="center"> {printData?.blouse_cut}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Box>
            <Typography variant="h6">Set</Typography>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Toplen
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Sh
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Sl
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    R
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Arm
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Up
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.set_toplen}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.set_sh}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.set_si}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.set_r}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.set_arm}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.set_up}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Ch
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Waist
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Seat
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    F
                  </TableCell>
                  <TableCell align="center">B</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.set_ch}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.set_waist}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.set_seat}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.set_f}
                  </TableCell>
                  <TableCell align="center"> {printData?.set_b}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer component={Paper} sx={{ mt: 3 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    KneeLen
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Bol
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Round
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    KRound
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Thigh
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    Waist
                  </TableCell>
                  <TableCell align="center">Seat</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.kneelen}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.boi}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.round}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.kround}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.thigh}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #a0a6a859", p: 1 }}
                  >
                    {printData?.waist}
                  </TableCell>
                  <TableCell align="center"> {printData?.seat}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container>
            <Typography gutterBottom>
              <strong>Notes:</strong>
            </Typography>
          </Grid>
          <Grid container>
            <Typography>{printData?.notes}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

export default Workorder;
