import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  Box,
  CardActions,
  CardContent,
  Button,
  Typography,
  Grid,
  Chip,
  useMediaQuery,
  Stack,
  TextField,
  MenuItem,
  Tooltip,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { makeStyles } from "@material-ui/core";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CustomerDatagrid from "../../component/datagrid/customGrid";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import Create from "./create/index";
import { useTheme } from "@mui/material/styles";
import Invoice from "./gstinvoice";
import { useNavigate } from "react-router-dom";
import orderApi from "../../services/order/index";
import invoiceApi from "../../services/invoice/index";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../../redux/loader/actions/index";
import Popup from "../../component/tooltip/index";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { differenceInDays } from "date-fns";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#f06666 !important",
  },
  cardRadius: {
    borderRadius: "0 15px 15px 0 !important",
    borderLeft: "3px solid #f06666",
  },
}));
const defaultPaginationModel = { page: 0, pageSize: 10 };
const defaultSortOrder = [{ field: "id", sort: "DESC" }];
const defaultSearchParams = {
  order_id: null,
  "user.name": null,
  "user.mobile_number": null,
  "customer.ref_name": null,
  order_status: null,
};
const Order = () => {
  const [rows, setRows] = useState([]);
  // const [getinvoicelist, setGetInvoiceList] = useState([]);
  const [canceldate, setCanceldate] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState();
  const [sortBy, setSortBy] = useState("order_date");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [filterDel, setFilterDel] = useState(false);
  const [filterCus, setFilterCus] = useState(false);
  const [filterCol, setFilterCol] = useState(false);
  const [filterBill, setFilterBill] = useState(false);
  const [filterTax, setFilterTax] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerMobile, setCustomerMobile] = useState("");
  const [refName, setRefName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterBy, setFilterBy] = useState("");
  const [filterOrder, setFilterOrder] = useState(false);
  const [datecol, setDatecol] = useState("false");
  const [filterTypecol, setFilterTypecol] = useState("");
  const [filteredSearchParams, setFilteredSearchParams] = useState({});
  const [searchParams, setSearchParams] = useState({
    order_id: null,
    "user.name": null,
    "user.mobile_number": null,
    "customer.ref_name": null,
    order_status: null,
  });
  const [triggerOrdersList, setTriggerOrdersList] = useState(null);
  const dispatch = useDispatch();

  const resetAlldel = () => {};
  const resetAllcus = () => {
    getAllOrderList();
  };
  const resetAllcol = () => {};
  const resetAllbill = () => {};
  const resetAlltax = () => {};

  const getAllOrderList = useCallback(() => {
    dispatch(showLoader());
    const sortField =
      sortOrder && sortOrder.length > 0 ? sortOrder[0]?.field : undefined;

    // Determine arrange based on datecol
    // const arrange = datecol === "true" ? filterTypecol : sortField;
    const arrange = sortField;
    // Prepare params object
    const params = {
      pagination: "true",
      page: paginationModel?.page,
      pageSize: paginationModel?.pageSize,
      sortBy: arrange,
      sortOrder: sortOrder.length > 0 ? sortOrder[0]?.sort : "desc",
      date_filter: filterTypecol,
    };
    const startDates = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : undefined;
    const endDates = endDate ? moment(endDate).format("YYYY-MM-DD") : undefined;
    // let date_filter;
    // if (datecol === "true") {
    //   date_filter = filterTypecol;
    // } else {
    //   date_filter = sortField; // Assuming sortField is appropriate here
    // }
    // const sortField = sortOrder[0]?.field;
    // setSortBy(sortField);
    // setSortOrder(sortDirection);
    // let sort =
    //   sortOrder && Array.isArray(sortOrder) && sortOrder.length > 0
    //     ? sortOrder
    //     : null;

    // if (sort) {
    //   // Ensure sortOrder[0] exists and has a sort property that is a string
    //   if (sort[0].hasOwnProperty("sort") && typeof sort[0].sort === "string") {
    //     sort = sort.map((item) => ({
    //       ...item,
    //       sort: item.sort.toUpperCase(), // Convert sortOrder[0].sort to uppercase
    //     }));
    //   } else {
    //     sort = null; // Reset sort if sortOrder[0].sort is not valid
    //   }
    // }

    const onSuccess = (res) => {
      setRowCount(res.data?.pagination?.totalRecord);
      dispatch(hideLoader());
      setRows(res.data.data);
    };

    const onFailure = (err) => {
      dispatch(hideLoader());
      console.error("Failed to fetch orders:", err);
    };

    orderApi
      .getAllOrderList(params, startDates, endDates, searchParams)
      .then(onSuccess)
      .catch(onFailure);
  }, [sortBy, sortOrder, paginationModel, triggerOrdersList]);

  useEffect(() => {
    getAllOrderList();
  }, [getAllOrderList, paginationModel, sortBy, sortOrder, triggerOrdersList]);
  // 1console.log(rowCount);
  const getAllOrderExport = useCallback(() => {
    dispatch(showLoader());
    const startDates = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : undefined;
    const endDates = endDate ? moment(endDate).format("YYYY-MM-DD") : undefined;
    return orderApi
      .getAllorder(searchParams, rowCount, startDates, endDates)
      .then(
        (res) => {
          dispatch(hideLoader());
          const data = res.data.data;

          const processedData = data.map((item, index) => {
            const {
              particulars, // Assuming this is the 'particular' field in your example data
              name,
              lining,
              quantity,
              bill_amount,
              delivery_date,
              second_delivery_date,
              order_status,
              payment_mode,
              particular,
              master,
              tailor,
              order_date,
              customer,
            } = item;

            // Format `particulars` - assuming it is a string as per your example
            let formattedParticulars =
              typeof particulars === "string"
                ? particulars
                : JSON.stringify(particulars);

            // Extract and format `particular` correctly
            let particularItem = "-";
            try {
              const parsedParticulars = JSON.parse(formattedParticulars);
              // Ensure parsedParticulars is an array and contains details
              if (Array.isArray(parsedParticulars)) {
                particularItem = parsedParticulars
                  .map((item) => item.particular)
                  .filter(Boolean) // Filter out any undefined or null values
                  .join(" | ");
              }
            } catch (error) {
              console.error("Error parsing JSON:", error);
            }
            let masterItem = "-";
            try {
              const parsedParticulars = JSON.parse(formattedParticulars);
              // Ensure parsedParticulars is an array and contains details
              if (Array.isArray(parsedParticulars)) {
                masterItem = parsedParticulars
                  .map((item) => item.master)
                  .filter(Boolean) // Filter out any undefined or null values
                  .join(" | ");
              }
            } catch (error) {
              console.error("Error parsing JSON:", error);
            }
            let tailorItem = "-";
            try {
              const parsedParticulars = JSON.parse(formattedParticulars);
              // Ensure parsedParticulars is an array and contains details
              if (Array.isArray(parsedParticulars)) {
                tailorItem = parsedParticulars
                  .map((item) => item.tailor)
                  .filter(Boolean) // Filter out any undefined or null values
                  .join(" | ");
              }
            } catch (error) {
              console.error("Error parsing JSON:", error);
            }

            return {
              s_no: index + 1,
              order_id: item.order_id,
              order_date: moment(order_date).format("DD-MM-YYYY"),
              customer_id: customer?.cust_id,
              customer_name: `${customer?.user?.name} (${customer?.ref_name})`,
              customer_mobile: customer?.user?.mobile_number,
              particulars: particularItem,
              lining: lining,
              quantity: quantity,
              bill_amount: bill_amount,
              master: masterItem,
              tailor: tailorItem,
              delivery_date: moment(delivery_date).format("DD-MM-YYYY"),
              second_delivery_date:
                second_delivery_date === null || second_delivery_date === "null"
                  ? "-"
                  : moment(second_delivery_date).format("DD-MM-YYYY"),
              order_status: order_status,
              payment_mode: payment_mode,
            };
          });

          return processedData;
        },
        (err) => {
          dispatch(hideLoader());
          console.error("Error fetching orders:", err);
          return [];
        }
      );
  }, [dispatch, searchParams, rowCount, startDate, endDate]);
  const handleFieldChange = (field, value) => {
    setSearchParams((prev) => ({
      ...prev,
      [field]: value || undefined,
    }));
  };
  // 1console.log("lllllll", searchParams);
  const handleSearch = () => {
    const filteredSearchParams = Object.fromEntries(
      Object.entries(searchParams).filter(([_, value]) => value !== undefined)
    );
    setDatecol("true");
    if (startDate || endDate) {
      setFilterTypecol("order_date");
    }
    setFilterOrder(true);
    setFilteredSearchParams(filteredSearchParams);
    setTriggerOrdersList(Math.random());

    // Create paramsToSend excluding startDate and endDate
    const paramsToSend = { ...filteredSearchParams };

    // Now you can use paramsToSend to send the filtered search parameters
    // 1console.log("Params to send:", paramsToSend);
  };

  const resetAllOrder = () => {
    setPaginationModel(defaultPaginationModel);
    setPageSize(defaultPaginationModel.pageSize);
    setSortOrder(defaultSortOrder);
    setSearchParams(defaultSearchParams);
    setTriggerOrdersList(Math.random());
    setFilterOrder(false);
    setStartDate(null);
    setEndDate(null);
  };

  // const getAllInvoiceList = useCallback(() => {
  //   dispatch(showLoader());

  //   const onSuccess = (res) => {
  //     dispatch(hideLoader());
  //     setGetInvoiceList(res.data.data);
  //   };

  //   const onFailure = (err) => {
  //     dispatch(hideLoader());
  //   };

  //   invoiceApi.getAllInvoice().then(onSuccess).catch(onFailure);
  // }, [dispatch]);

  // useEffect(() => {
  //   getAllInvoiceList();
  // }, [getAllInvoiceList]);

  const [cancelledDates, setCancelledDates] = useState([]);

  useEffect(() => {
    if (rows?.length > 0) {
      setCancelledDates(rows.map((data) => data?.cancelled_date));
    }
  }, [rows]);

  const validateDateWithin7Days = (providedDate, referenceDate) => {
    if (!providedDate) {
      return true; // If providedDate is null, return true
    }

    const startOfProvidedDate = moment(providedDate).startOf("day");
    const startOfReferenceDate = moment(referenceDate).startOf("day");
    const endDate = startOfProvidedDate.clone().add(90, "days").endOf("day");

    return startOfReferenceDate.isSameOrBefore(endDate);
  };

  // Example usage
  // const isWithin7Days = validateDateWithin7Days(
  //   "2024-07-10T13:41:51.938Z",
  //   "2024-07-18T13:41:51.938Z"
  // );
  const orderListRows = rows?.map((data, index) => {
    let particularsArray = [];

    if (typeof data.particulars === "string") {
      try {
        particularsArray = JSON.parse(data.particulars);
      } catch (error) {
        particularsArray = [];
      }
    } else if (Array.isArray(data.particulars)) {
      particularsArray = data.particulars;
    } else if (
      typeof data.particulars === "object" &&
      data.particulars !== null
    ) {
      particularsArray = [data.particulars];
    }

    const breakupDetails =
      particularsArray.length > 0 && particularsArray[0].breakupDetails
        ? particularsArray[0].breakupDetails
            .map((detail) => `${detail.title}: ${detail.price}`)
            .join(" | ")
        : "";

    let paymentsArray = [];

    if (typeof data.payment_details === "string") {
      try {
        paymentsArray = JSON.parse(data.payment_details);
        if (!Array.isArray(paymentsArray)) {
          throw new Error("Parsed value is not an array.");
        }
      } catch (error) {
        paymentsArray = [data.payment_details];
      }
    } else if (Array.isArray(data.payment_details)) {
      paymentsArray = data.payment_details;
    } else if (
      typeof data.payment_details === "object" &&
      data.payment_details !== null
    ) {
      paymentsArray = [data.payment_details];
    }

    const paymentModes = paymentsArray.reduce((acc, particular) => {
      if (particular.payment_mode === "cash" && !acc.includes("cash")) {
        acc.push("cash");
      } else if (particular.payment_mode === "bank" && !acc.includes("bank")) {
        acc.push("bank");
      }
      return acc;
    }, []);

    let paymentModeString;
    if (paymentModes.includes("cash") && paymentModes.includes("bank")) {
      paymentModeString = "both";
    } else {
      paymentModeString = paymentModes.join(" | ");
    }
    const capitalizedPaymentModeString =
      paymentModeString.charAt(0).toUpperCase() + paymentModeString.slice(1);

    // Capitalize the first letter of orderStatus and handle 'complete' status
    const orderStatus = data?.order_status || "";
    const capitalizedOrderStatus =
      orderStatus.toLowerCase() === "completed"
        ? "Completed"
        : orderStatus.charAt(0).toUpperCase() + orderStatus.slice(1);

    let key = 1;
    if (
      paymentModeString.toLowerCase().includes("cash") &&
      paymentModeString.toLowerCase().includes("bank")
    ) {
      key = 1; // Both "cash" and "bank" are present
    } else if (paymentModeString.toLowerCase().includes("cash")) {
      key = 0; // Only "cash" is present
    } else {
      key = 1; // Default to 1 if neither "cash" nor "bank" is present
    }

    const isWithin7Days = validateDateWithin7Days(
      data?.cancelled_date || data?.delivered_date,
      new Date()
    );

    return {
      cusid: data?.id,
      sno: index + 1,
      snid: data?.order_id,
      id: data?.order_id,
      // customerId: data?.order_id,
      cust_id: data?.customer?.cust_id,
      name: `${data?.customer?.user?.name} ${
        data?.customer?.ref_name ? `(${data?.customer?.ref_name})` : ""
      }`,
      order_date: moment(data?.order_date).format("DD-MM-YYYY"),
      mobile_number: data?.customer?.user?.mobile_number,
      generated: data?.invoice_generated,
      particulars: particularsArray.map((item) => item.particular).join(" | "),
      lining: data?.lining,
      qty: data?.quantity,
      orderamount: data?.bill_amount,
      delivery_date: moment(data?.delivery_date).format("DD-MM-YYYY"),
      second_delivery_date: data?.second_delivery_date
        ? moment(data?.second_delivery_date).format("DD-MM-YYYY")
        : "-",
      orderstatus: capitalizedOrderStatus, // Capitalized order status with 'complete' as 'Completed'
      paymentmode: capitalizedPaymentModeString,
      breakupDetails: breakupDetails,
      key: key,
      master:
        particularsArray
          .map((item) => (item.master ? item.master : null))
          .filter(Boolean).length > 0
          ? particularsArray.map((item) => item.master).join(" | ")
          : "-",
      tailor:
        particularsArray
          .map((item) => (item.tailor ? item.tailor : null))
          .filter(Boolean).length > 0
          ? particularsArray.map((item) => item.tailor).join(" | ")
          : "-",
      due_amount: data?.due_amount,
      orderId: data?.uuid,
      cancelled_msg: data?.cancelled_msg,
      cancelled_date: data?.cancelled_date,
      isWithin7Days: isWithin7Days,
    };
  });

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mode, setMode] = useState();
  const [open, setOpen] = useState(false);
  const [customerViewId, setCustomerViewId] = useState(null);

  const handleOpen = () => {
    setOpen(true);
    setMode("");
  };

  const handleView = (row) => {
    setOpen(true);
    setMode("view");
    getOrder(row.cusid);
  };

  const handleHistroy = () => {
    setOpen(true);
    setMode("orderhistory");
  };

  const actionClick = (row) => {
    setMode("edit");
    setOpen(true);
    getOrder(row.cusid);
  };
  const navigate = useNavigate();
  const invoicePrint = (snid) => {
    window.open(`/invoiceprint/${snid}`, "_blank");
  };
  const workPrint = (orderId) => {
    window.open(`/orderprint/${orderId}`, "_blank");
  };
  const customerPrint = (orderId) => {
    window.open(`/customerprint/${orderId}`, "_blank");
  };
  const isCancelDateOverOneWeek = () => {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7); // Calculate one week ago

    return new Date(canceldate) < oneWeekAgo;
  };

  const formatDate = (date) => {
    return moment(date).format("DD-MM-YYYY"); // Example format
  };

  const columns = [
    {
      field: "sno",
      headerName: "S.No",
      width: 50,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "id",
      headerName: "Order ID",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "order_date",
      headerName: "Order Date",
      sortable: true,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "cust_id",
      headerName: "Customer ID",
      sortable: true,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Name(Ref Name)",
      width: 250,
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "mobile_number",
      headerName: "Mobile Number",
      width: 150,
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "particulars",
      headerName: "Particulars",
      width: 200,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "qty",
      headerName: "Qty",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "orderamount",
      headerName: "Order Amount",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "delivery_date",
      headerName: "1st Delivery Date",
      sortable: true,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "second_delivery_date",
      headerName: "2nd Delivery Date",
      sortable: true,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "orderstatus",
      headerName: "Order Status",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          {params.row.orderstatus === "Cancelled" ? (
            <>
              {params.row.orderstatus}
              &nbsp;
              <Popup historyDetail={params.row.cancelled_msg} />
            </>
          ) : (
            params.row.orderstatus
          )}
        </div>
      ),
    },
    {
      field: "paymentmode",
      headerName: "Payment Mode",
      sortable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "internalCopy",
      headerName: "Print",
      sortable: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <div style={{ textAlign: "center", width: "100%" }}>
            <strong>
              <Tooltip title="Internal Copy" arrow placement="top">
                <LocalPrintshopOutlinedIcon
                  onClick={() => workPrint(params.row.orderId)}
                  size="small"
                  color="primary"
                  sx={{ mx: 1, mt: 1 }}
                />
              </Tooltip>
              <Tooltip title="Customer Copy" arrow placement="top">
                <LocalPrintshopOutlinedIcon
                  onClick={() => customerPrint(params.row.orderId)}
                  size="small"
                  color="success"
                  sx={{ mx: 1, mt: 1 }}
                />
              </Tooltip>
            </strong>
          </div>
        </>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div style={{ textAlign: "center", width: "100%" }}>
          <strong>
            <VisibilityOutlinedIcon onClick={() => handleView(params.row)} />
            {params?.row?.isWithin7Days ? (
              <EditOutlinedIcon
                sx={{ cursor: "pointer", mx: 1 }}
                onClick={() => actionClick(params.row)}
              />
            ) : null}
            {(params?.row?.due_amount === 0 &&
              params?.row?.generated === false) ||
            (params?.row?.generated === true &&
              params?.row?.due_amount !== 0) ||
            (params?.row?.generated === true &&
              params?.row?.due_amount === 0) ? (
              <>
                {params?.row?.key === 1 && (
                  <>
                    {params?.row?.generated === false && (
                      <Tooltip title="Generate" arrow placement="top">
                        <Chip
                          label="G"
                          variant="outlined"
                          size="small"
                          color="warning"
                          onClick={() => handleInvoiceOpen(params.row)}
                          sx={{
                            position: "relative",
                            top: "-5px",
                            mx: 1,
                          }}
                        />
                      </Tooltip>
                    )}
                    {params?.row?.generated === true && (
                      <Tooltip title="Generated" arrow placement="top">
                        <Chip
                          label="G"
                          variant="outlined"
                          size="small"
                          color="success"
                          onClick={() => invoicePrint(params?.row?.orderId)}
                          sx={{
                            position: "relative",
                            top: "-5px",
                            mx: 1,
                          }}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
              </>
            ) : null}
          </strong>
        </div>
      ),
    },
  ];

  const [orderParticularData, setOrderParticularData] = useState(null);
  const getOrder = (value) => {
    dispatch(showLoader());
    const onSuccess = (res) => {
      dispatch(hideLoader());
      setOrderParticularData(res?.data?.data);
    };
    const onFailure = (err) => {
      dispatch(hideLoader());
    };
    orderApi.getOrder(value).then(onSuccess, onFailure);
  };

  const [invoiceopen, setInvoiceopen] = useState(false);

  const handleInvoiceOpen = (row) => {
    setInvoiceopen(true);
    getOrder(row?.cusid);
  };

  const handleInvoiceClose = () => {
    setInvoiceopen(false);
  };
  const exportName = "Order";
  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (endDate && date && date.isAfter(endDate)) {
      setEndDate(null); // Optionally reset end date if it's before start date
    }
  };

  return (
    <>
      <Card elevation={5} fullWidth className={classes.cardRadius}>
        <CardContent>
          <Grid container alignItems="center">
            <Grid
              item
              xs={12}
              sm={4}
              sx={{ marginTop: "auto", marginBottom: "auto" }}
            >
              <Typography variant="h5" gutterBottom>
                Order List
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              container
              justifyContent={isMobile ? "right" : "flex-start"}
              alignItems="center"
              sx={{
                marginTop: "auto",
                marginBottom: isMobile ? 1 : "auto",
              }}
            >
              <Button
                variant="contained"
                className={classes.btn}
                onClick={handleHistroy}
                size="medium"
              >
                <ArticleOutlinedIcon />
                Order History
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              container
              justifyContent="flex-end"
              sx={{ marginTop: "auto", marginBottom: "auto" }}
            >
              <Button
                variant="contained"
                className={classes.btn}
                onClick={handleOpen}
                size="medium"
              >
                <AddOutlinedIcon />
                New Order
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={2} my={1}>
              <Grid item xs={12} md={3} sm={12}>
                <TextField
                  id="order-id"
                  fullWidth
                  label="Order ID"
                  variant="outlined"
                  value={searchParams.order_id || ""}
                  onChange={(e) =>
                    handleFieldChange("order_id", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} md={3} sm={12}>
                <TextField
                  id="customer-name"
                  label="Customer Name"
                  fullWidth
                  variant="outlined"
                  value={searchParams["user.name"] || ""}
                  onChange={(e) =>
                    handleFieldChange("user.name", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} md={3} sm={12}>
                <TextField
                  id="ref-name"
                  fullWidth
                  label="Ref Name"
                  variant="outlined"
                  value={searchParams["customer.ref_name"] || ""}
                  onChange={(e) =>
                    handleFieldChange("customer.ref_name", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} md={3} sm={12}>
                <TextField
                  id="customer-mobile"
                  fullWidth
                  label="Customer Mobile Number"
                  variant="outlined"
                  value={searchParams["user.mobile_number"] || ""}
                  // onChange={(e) =>
                  //   setSearchParams({
                  //     "user.mobile_number": e.target.value,
                  //   })
                  // }
                  onChange={(e) =>
                    handleFieldChange("user.mobile_number", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} md={3} sm={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Start Date"
                      fullWidth
                      value={startDate}
                      onChange={handleStartDateChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
              <Grid item xs={12} md={3} sm={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="End Date"
                      fullWidth
                      value={endDate}
                      minDate={startDate}
                      onChange={(newValue) => {
                        setEndDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
              <Grid item xs={12} md={3} sm={12}>
                <TextField
                  select
                  fullWidth
                  label="Order Status"
                  value={searchParams["order_status"] || ""}
                  onChange={(e) =>
                    handleFieldChange("order_status", e.target.value)
                  }
                >
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="inprogress">In-Progress</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                  <MenuItem value="delivered">Delivered</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </TextField>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                sm={12}
                sx={{
                  margin: "auto",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  size="medium"
                  onClick={handleSearch}
                >
                  Search
                </Button>
                {filterOrder && (
                  <Button
                    variant="contained"
                    size="medium"
                    sx={{ ml: 1 }}
                    onClick={resetAllOrder}
                  >
                    Reset
                  </Button>
                )}
              </Grid>
            </Grid>
            <CustomerDatagrid
              rows={orderListRows}
              getRowId={(row) => row.snid}
              exportName={exportName}
              columns={columns}
              pagination
              paginationMode="server"
              rowCount={rowCount}
              page={page}
              pageSize={pageSize}
              pageSizeOptions={[10, 25, 50, 100]}
              paginationModel={paginationModel}
              onPaginationModelChange={(params) => setPaginationModel(params)}
              onPageSizeChange={(params) => setPageSize(params.pageSize)}
              onSortModelChange={(params) => setSortOrder(params)}
              exportCallback={getAllOrderExport}
              setFiltersApplied={setFiltersApplied}
              filtersApplied={filtersApplied}
              filterDel={filterDel}
              setFilterDel={setFilterDel}
              filterCus={filterCus}
              setFilterCus={setFilterCus}
              filterCol={filterCol}
              setFilterCol={setFilterCol}
              filterBill={filterBill}
              setFilterBill={setFilterBill}
              filterTax={filterTax}
              filterOrder={filterOrder}
              setFilterOrder={setFilterOrder}
              setFilterTax={setFilterTax}
              resetAllOrder={resetAllOrder}
              resetAlldel={resetAlldel}
              resetAllcus={resetAllcus}
              resetAllcol={resetAllcol}
              resetAllbill={resetAllbill}
              resetAlltax={resetAlltax}
            />
          </Box>
        </CardActions>
      </Card>
      <Create
        open={open}
        setOpen={setOpen}
        setMode={setMode}
        mode={mode}
        getAllOrderList={getAllOrderList}
        customerViewId={customerViewId}
        setCustomerViewId={setCustomerViewId}
        orderParticularData={orderParticularData}
      />
      <Invoice
        invoiceopen={invoiceopen}
        setInvoiceopen={setInvoiceopen}
        handleInvoiceOpen={handleInvoiceOpen}
        handleInvoiceClose={handleInvoiceClose}
        orderParticularData={orderParticularData}
        // getinvoicelist={getinvoicelist}
        getAllOrderList={getAllOrderList}
      />
    </>
  );
};

export default Order;
