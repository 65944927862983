import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import { TextField, Button, CircularProgress, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import Export from "../export/index";

const CustomTable = ({
  rows,
  columns,
  exportName,
  onSortModelChange,
  onPageChange,
  onPageSizeChange,
  onFilterModelChange,
  onPaginationModelChange,
  paginationModel,
  pageSize,
  pageSizeOptions,
  filterDel,
  filterCus,
  setFilterCus,
  filterOrder,
  setFilterDel,
  setFilterOrder,
  filterCol,
  setFilterCol,
  filterBill,
  setFilterBill,
  filterTax,
  setFilterTax,
  setFiltersApplied,
  filtersApplied,
  resetAlldel,
  resetAllcus,
  resetAllcol,
  resetAllbill,
  resetAlltax,
  rowCount,
  page,
  exportCallback,
  hideMobileNumber,
  resetAllOrder,
}) => {
  const [filters, setFilters] = React.useState({});
  const location = useLocation();

  const filteredRows = rows.filter((row) => {
    return Object.keys(filters).every((field) => {
      const filterValue = filters[field] || "";
      return row[field]
        ?.toString()
        .toLowerCase()
        .includes(filterValue.toLowerCase());
    });
  });

  const handleExport = () => {
    const csvRows = [];
    const filteredColumns = columns.filter(
      (column) => !["action", "internalCopy"].includes(column.field)
    );
    const headers = filteredColumns
      .map((column) => column.headerName)
      .join(",");
    csvRows.push(headers);

    filteredRows.forEach((row) => {
      const values = filteredColumns
        .map((column) => row[column.field] || "")
        .join(",");
      csvRows.push(values);
    });

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${exportName}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  };
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    const newFilters = { ...filters, [name]: value };
    setFilters(newFilters);
    setFiltersApplied(Object.values(newFilters).some((value) => value !== ""));
  };

  const handleResetFilters = () => {
    setFilters({});
    setFiltersApplied(false);
    setFilterDel(false);
    setFilterCus(false);
    setFilterOrder(false);
    setFilterCol(false);
    setFilterBill(false);
    setFilterTax(false);
    resetAllcus();
    resetAlldel();
    resetAllcol();
    resetAllbill();
    resetAlltax();
    resetAllOrder();
  };
  return (
    <>
      <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
        {columns.map((column) => {
          // if (
          //   [
          //     "ids",
          //     // "id",
          //     "action",
          //     "internalCopy",
          //     // "orderdate",
          //     // "paidon",
          //     // "invoiceDate",
          //     "sno",
          //   ].includes(column.field)
          // )
          return null;
          // return (
          //   <TextField
          //     key={column.field}
          //     name={column.field}
          //     label={column.headerName}
          //     onChange={handleFilterChange}
          //     value={filters[column.field] || ""}
          //     size="small"
          //   />
          // );
        })}

        {filtersApplied ||
        filterDel ||
        filterCol ||
        filterBill ||
        filterTax ||
        filterOrder ||
        filterCus ? (
          <Box mb={2}>
            <Export
              fetchData={exportCallback}
              filename={`${exportName}.xlsx`}
              hideMobileNumber={hideMobileNumber}
            />
          </Box>
        ) : (
          <Export
            fetchData={exportCallback}
            filename={`${exportName}.xlsx`}
            hideMobileNumber={hideMobileNumber}
          />
        )}
      </div>
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pagination
          paginationMode="server"
          rowCount={rowCount}
          page={page}
          pageSize={pageSize}
          components={{ Toolbar: GridToolbar }}
          // onPageChange={(params) => onPageChange(params)}
          onPageChange={(params, e) => {
            console.log("wwwwww", params, e);
          }}
          onPaginationModelChange={onPaginationModelChange}
          paginationModel={paginationModel}
          onPageSizeChange={onPageSizeChange}
          pageSizeOptions={pageSizeOptions}
          filterMode="server"
          // filterModel={filterModel}
          onFilterModelChange={onFilterModelChange}
          sortingMode="server"
          // sortModel={sortModel}
          onSortModelChange={onSortModelChange}
        />
      </div>
    </>
  );
};

export default CustomTable;
