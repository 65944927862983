import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import store from "./redux/store/index";
import Routes from "./router/index";
import "./assets/css/style.css";
import NetworkService from "./services/network";
import SnackBar from "./component/snackbar";
import Loader from "./component/loader";
NetworkService.setupInterceptors();
function App() {
  return (
    <>
      <Provider store={store}>
        <Routes />
        <Loader />
        <SnackBar />
      </Provider>
    </>
  );
}

export default App;
