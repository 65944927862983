import React from "react";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#192c54",
      light: "#f06666",
    },
    secondary: {
      main: "#f06666"
    }
  },
  typography: {
    h5: {
      fontSize: "25px",
    },
  },
});

export default theme;
