import React from "react";
import { Dialog, DialogContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import Lottie from "react-lottie";
import LoaderLottieFile from "../lottieloader/index.json";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-scrollPaper": {
      background: "none",
    },
    "& .MuiDialog-paper": {
      background: "none",
      boxShadow: "none",
      opacity: 1,
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: 16,
  },
  loaderTxt: {
    paddingTop: 8,
    fontWeight: 600,
  },
}));

const Loader = () => {
  const classes = useStyles();
  const loader = useSelector((state) => state?.loader);
  const { showLoader, loaderTxt } = loader;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoaderLottieFile,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={showLoader}
      className={classes.root}
      aria-labelledby="Loader Popup"
    >
      <DialogContent>
        <div className={classes.container}>
          <Lottie options={defaultOptions} height={150} width={150} />
        
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Loader;
