import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  List,
  CssBaseline,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Stack,
  Button,
  Chip,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import menuListArray from "../../models/index";
import { makeStyles } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import Storage from "../../component/storage";
import userManual from "../../assets/pdf/LDS_TAGCS-PO00010_User_Manual_v1.pdf";

const drawerWidth = 260;

const useStyles = makeStyles(() => ({
  borderedElement: {
    borderRadius: "0 50px 50px 0 !important",
    fontWeight: "600 !important",
    marginRight: "15px !important",
  },
  activeSidebar: {
    backgroundColor: "#f066668c !important",
    borderLeft: "3px solid #f06666 !important",
    color: "#000 !important",
    marginRight: "10px !important",
  },
  activeColor: {
    color: "#fff !important",
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Sidebar = ({ open, setOpen }) => {
  const location = useLocation();
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const [openSubmenus, setOpenSubmenus] = useState({});
  const [menuList, setMenuList] = useState(menuListArray);

  const handleMenuClick = (title, path, id) => {
    const updatedMenuList = menuList.map((menuItem) =>
      menuItem.id === id && menuItem.path === location.pathname
        ? { ...menuItem, activeSidebar: true }
        : { ...menuItem, activeSidebar: false }
    );
    setMenuList(updatedMenuList);
    setOpenSubmenus((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
    if (path) navigate(path);
  };

  const handleOpenPdf = () => {
    window.open(userManual);
  };

  const refreshMenuList = () => {
    const updatedMenuList = menuList.map((menuItem) =>
      menuItem.path === location.pathname
        ? { ...menuItem, activeSidebar: true }
        : { ...menuItem, activeSidebar: false }
    );
    setMenuList(updatedMenuList);
  };

  useEffect(() => {
    refreshMenuList();
  }, [location.pathname]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader />
        <Divider />
        <Box sx={{ flexGrow: 1, overflow: "auto" }}>
          <List>
            {menuList.map((menuItem, index) => (
              <div key={menuItem.title}>
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  selected={menuItem.index}
                >
                  <ListItemButton
                    onClick={() =>
                      handleMenuClick(
                        menuItem.title,
                        menuItem.path,
                        menuItem.id
                      )
                    }
                    sx={{
                      minHeight: 52,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      mb: 1.5,
                    }}
                    className={`${classes.borderedElement} ${
                      menuItem.activeSidebar ? classes.activeSidebar : null
                    }`}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                      className={
                        menuItem.activeSidebar ? classes.activeColor : null
                      }
                    >
                      {menuItem.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={menuItem.title}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                    {open && menuItem.submenu ? (
                      openSubmenus[menuItem.title] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : null}
                  </ListItemButton>
                  {open && menuItem.submenu && (
                    <Collapse
                      in={openSubmenus[menuItem.title]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {menuItem.submenu.map((submenuItem) => (
                          <ListItemButton
                            key={submenuItem.title}
                            sx={{ pl: 4 }}
                            onClick={() =>
                              handleMenuClick(
                                submenuItem.title,
                                submenuItem.path,
                                submenuItem.id
                              )
                            }
                          >
                            <ListItemIcon>{submenuItem.icon}</ListItemIcon>
                            <ListItemText primary={submenuItem.title} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </ListItem>
              </div>
            ))}
          </List>
        </Box>
        {open && (
          <Box sx={{ mt: "auto" }}>
            <Stack sx={{ m: 2 }}>
              <Storage />
            </Stack>
          </Box>
        )}
        {/* {open && (
          <Box sx={{ mt: "auto", mx: "auto" }}>
            <Chip
              onClick={handleOpenPdf}
              label="User Manual"
              icon={<MenuBookOutlinedIcon />}
              variant="outlined"
              size="small"
              color="primary"
              sx={{ mb: 2 }}
            />
          </Box>
        )} */}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
