
import React, { useEffect, useRef, useState } from 'react';

const CameraCapture = ({ setUploadedFile }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const constraints = {
      video: {
        facingMode: { ideal: 'environment' } 
      }
    };

    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      })
      .catch((err) => {
        setError(err.message);
      });

    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  const capturePhoto = () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
      canvasRef.current.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], 'captured_image.png', { type: 'image/png' });
          setUploadedFile(file);
        }
      }, 'image/png');
    }
  };

  return (
    <div>
      {error ? (
        <p>Error: {error}</p>
      ) : (
        <div>
          <video ref={videoRef} autoPlay playsInline style={{ width: '100%' }} />
          <button onClick={capturePhoto}>Capture Photo</button>
          <canvas ref={canvasRef} style={{ display: 'none' }} width="640" height="480" />
        </div>
      )}
    </div>
  );
};

export default CameraCapture;







