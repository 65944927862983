import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Modal,
  Grid,
  IconButton,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { useFormik, FormikProvider, FieldArray } from "formik";
import * as Yup from "yup";
import orderApi from "../../../services/order";
import invoiceApi from "../../../services/invoice";
import { showLoader, hideLoader } from "../../../redux/loader/actions";
import { showSnackbar } from "../../../redux/snackbar/actions";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Height } from "@mui/icons-material";
import Cookies from "js-cookie";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#f06666 !important",
  },
}));

const Gstinvoice = ({
  handleInvoiceClose,
  invoiceopen,
  orderParticularData,
  getAllOrderList,
  setInvoiceopen,
}) => {
  const classes = useStyles();
  const [parsedOrderData, setParsedOrderData] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [gstData, setGstData] = useState([]);
  const [cgstData, setCgstData] = useState(0);
  const [sgstData, setSgstData] = useState(0);
  const [netAmountData, setNetAmountData] = useState(0);
  const [subTotalData, setSubTotalData] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [shopId, setShopId] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (invoiceopen) {
      setShopId(parseFloat(Cookies.get("shop_id")));
    }
  }, [invoiceopen]);
  const handleClose = () => {
    setInvoiceopen(false);
    setParsedOrderData([]);
    setIsDirty(false);
    setGstData([]);
    setCgstData(0);
    setSgstData(0);
    setNetAmountData(0);
    setSubTotalData(0);
    setTotalAmount(0);
  };
  const handleOrderUpdate = () => {
    dispatch(showLoader());

    const orderData = {
      invoice_generated: true,
      invoice_generated_on: moment().format("YYYY-MM-DD HH:mm:ss.SSS Z"),
    };

    const onSuccessOrder = () => {
      dispatch(hideLoader());
      getAllOrderList();
    };

    const onFailureOrder = (err) => {
      dispatch(hideLoader());
    };

    orderApi
      .updateOrder(orderParticularData?.id, orderData)
      .then(onSuccessOrder)
      .catch(onFailureOrder);
  };
  const total_net_amount =
    subTotalData +
    parseFloat(cgstData)?.toFixed(2) +
    parseFloat(sgstData)?.toFixed(2);
  const handleGenerateinvoice = () => {
    dispatch(showLoader());

    const data = {
      order_id: orderParticularData?.id,
      particulars: gstData,
      sub_total: parseFloat(parseFloat(subTotalData).toFixed(2)),
      c_gst: parseFloat(parseFloat(cgstData).toFixed(2)), // Convert to float
      s_gst: parseFloat(parseFloat(sgstData).toFixed(2)), // Convert to float
      net_amount: totalAmount ? totalAmount : formik?.values?.netAmount,
      order_uuid: orderParticularData?.uuid,
      shop_id: shopId,
    };

    const onSuccess = (res) => {
      dispatch(hideLoader());
      if (res?.data?.status === true) {
        dispatch(
          showSnackbar({
            message: res?.data?.msg || "Invoice created successfully",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "success",
          })
        );
        handleOrderUpdate();
        handleClose();
        getAllOrderList();
        setParsedOrderData([]);
        setIsDirty(false);
        setGstData([]);
        setCgstData(0);
        setSgstData(0);
        setNetAmountData(0);
        setSubTotalData(0);
        setTotalAmount(0);
      }
    };

    const onFailure = (err) => {
      dispatch(hideLoader());
      dispatch(
        showSnackbar({
          message: err?.response?.data?.msg || "Failed to update invoice",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
        })
      );
    };

    invoiceApi.invoiceCreate(data).then(onSuccess).catch(onFailure);
  };

  const handleFinalSubmit = () => {
    handleGenerateinvoice();
  };
  // Formik hook
  const validationSchema = Yup.object().shape({
    amounts: Yup.array().of(
      Yup.object().shape({
        amount: Yup.number()
          .required("Amount is required")
          .min(0, "Amount must be greater than or equal to 0")
          .test(
            "maxDigitsAfterDecimal",
            "Amount must have at most 2 decimal places",
            (value) =>
              value == null
                ? true
                : /^-?\d+(\.\d{1,2})?$/.test(value.toString())
          ),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      amounts: parsedOrderData.map((row) => ({ amount: row.amount })),
      subtotal: 0,
      cgst: 0,
      sgst: 0,
      netAmount: 0,
    },
    validationSchema,
    onSubmit: handleFinalSubmit,
  });

  useEffect(() => {
    if (orderParticularData?.particulars) {
      try {
        const parsedData = orderParticularData.particulars;

        // Calculate reduced amounts (5% reduction)
        const reducedAmounts = parsedData.map((row) => ({
          amount: ((row.amount / 105) * 100).toFixed(2),
        }));

        // Initialize totalAmount
        let totalAmount = 0;

        // Log amounts and calculate total amount
        parsedData.forEach((row) => {
          totalAmount += row.amount;
        });

        // Calculate subtotal based on reduced amounts
        const subtotal = reducedAmounts.reduce(
          (acc, item) => acc + parseFloat(item.amount),
          0
        );

        setParsedOrderData(parsedData);
        formik.setValues({
          ...formik.values,
          amounts: reducedAmounts,
          subtotal: subtotal.toFixed(2),
          cgst: (subtotal * 0.025)?.toFixed(2), // Calculate CGST dynamically based on reduced subtotal
          sgst: (subtotal * 0.025)?.toFixed(2),
          netAmount: Math.round(subtotal + subtotal * 0.025 + subtotal * 0.025),
        });

        setNetAmountData(
          Math.round(subtotal + subtotal * 0.025 + subtotal * 0.025)
        ); // Calculate SGST dynamically based on reduced subtotal
      } catch (error) {
        setParsedOrderData([]);
      }
    } else {
      setParsedOrderData([]);
    }
  }, [orderParticularData]);

  useEffect(() => {
    // Create an array of data objects to store in gstData
    const newData = parsedOrderData.map((row, index) => ({
      id: row.id,
      particulars: row.particular,
      qty: row.qty,
      amount: formik?.values.amounts[index]?.amount,
      subtotal: formik?.values.subtotal,
      cgst: parseFloat(formik?.values.cgst),
      sgst: parseFloat(formik?.values.sgst),
      netAmount: totalAmount,
    }));

    // Update gstData state with new data
    setGstData(newData);
    setCgstData(parseFloat(formik?.values?.cgst).toFixed(2));
    setSgstData(parseFloat(formik?.values?.sgst).toFixed(2));
    // setNetAmountData(totalAmount);
    setSubTotalData(formik?.values.subtotal);
  }, [
    formik?.values.amounts,
    formik?.values.cgst,
    formik?.values?.sgst,
    formik?.values.subtotal,
    parsedOrderData,
    totalAmount,
  ]);
  // Handle amount change in text field
  const handleAmountChange = (index, value) => {
    // Update the amounts array in formik.values
    const updatedAmounts = [...formik.values.amounts];
    updatedAmounts[index].amount = parseFloat(value); // Ensure value is parsed as float if necessary
    formik.setValues({
      ...formik.values,
      amounts: updatedAmounts,
    });
    setIsDirty(true); // Set isDirty to true on change
  };

  // Recalculate totals whenever amounts change
  useEffect(() => {
    if (isDirty) {
      const { amounts } = formik.values;

      // Calculate subtotal based on updated amounts
      const subtotal = amounts.reduce(
        (acc, item) => acc + parseFloat(item.amount),
        0
      );

      // Update CGST and SGST based on the updated subtotal
      formik.setValues({
        ...formik.values,
        subtotal: subtotal?.toFixed(2),
        cgst: (subtotal * 0.025)?.toFixed(2), // Calculate CGST as 2.5% of subtotal
        sgst: (subtotal * 0.025)?.toFixed(2), // Calculate SGST as 2.5% of subtotal
      });

      // Calculate total amount
      const calculatedTotalAmount = Math.round(
        subtotal + subtotal * 0.025 + subtotal * 0.025
      );

      setTotalAmount(Math.round(calculatedTotalAmount));
      setIsDirty(false); // Reset isDirty to false after recalculations
    }
  }, [formik.values.amounts, isDirty]); // Recalculate only when amounts change or isDirty becomes true

  const formatNumber = (value) => {
    return Number(value).toFixed(2);
  };

  return (
    <Modal
      open={invoiceopen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, overflowY: "auto", maxHeight: "80vh" }}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h6" component="h2" gutterBottom>
              Generate Invoice
            </Typography>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={6} lg={4} xs={12}>
            <Grid container>
              <Grid item sm={6}>
                <Typography gutterBottom>
                  <strong> Order ID:</strong>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography>{orderParticularData?.order_id}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} lg={4} xs={12}>
            <Grid container>
              <Grid item sm={6}>
                <Typography gutterBottom>
                  <strong> Booking Date:</strong>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography>
                  {orderParticularData
                    ? new Date(
                        orderParticularData.order_date
                      ).toLocaleDateString("en-GB")
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} lg={4} xs={12}>
            <Grid container>
              <Grid item sm={6}>
                <Typography gutterBottom>
                  <strong>Name:</strong>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography>
                  {" "}
                  {orderParticularData?.customer?.user?.name}
                  {orderParticularData?.customer?.ref_name &&
                    `(${orderParticularData.customer?.ref_name})`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} lg={4} xs={12}>
            <Grid container>
              <Grid item sm={6}>
                <Typography gutterBottom>
                  <strong> Mobile No:</strong>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography>
                  {" "}
                  {orderParticularData?.customer?.user?.mobile_number}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} lg={4} xs={12}>
            <Grid container>
              <Grid item sm={6}>
                <Typography gutterBottom>
                  <strong> Delivery Date:</strong>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography>
                  {orderParticularData &&
                  orderParticularData.is_second_delivery_date_req !== true
                    ? new Date(
                        orderParticularData.delivery_date
                      ).toLocaleDateString("en-GB")
                    : orderParticularData &&
                      orderParticularData.second_delivery_date
                    ? new Date(
                        orderParticularData.second_delivery_date
                      ).toLocaleDateString("en-GB")
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider variant="middle" />
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <FieldArray
              name="amounts"
              render={(arrayHelpers) => (
                <TableContainer component={Paper} sx={{ mt: 1 }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                      <FieldArray
                        name="amounts"
                        render={() =>
                          orderParticularData ? (
                            <TableContainer component={Paper} sx={{ mt: 1 }}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="center">S.No</TableCell>
                                    <TableCell align="center">
                                      Particulars
                                    </TableCell>
                                    <TableCell align="center">Qty</TableCell>
                                    <TableCell align="center">Amount</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {parsedOrderData?.map((row, index) => (
                                    <TableRow key={row.sno}>
                                      <TableCell align="center">
                                        {row.id}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.particular}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.qty}
                                      </TableCell>
                                      <TableCell align="center">
                                        <TextField
                                          name={`amounts.${index}.amount`}
                                          type="number"
                                          value={
                                            formik.values.amounts[index]?.amount
                                          }
                                          onChange={(e) => {
                                            formik.handleChange(e);
                                            setIsDirty(true);
                                          }}
                                          onBlur={formik.handleBlur}
                                          error={
                                            formik.touched.amounts?.[index]
                                              ?.amount &&
                                            Boolean(
                                              formik.errors.amounts?.[index]
                                                ?.amount
                                            )
                                          }
                                          helperText={
                                            formik.touched.amounts?.[index]
                                              ?.amount &&
                                            formik.errors.amounts?.[index]
                                              ?.amount
                                          }
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          ) : (
                            <Typography variant="body1">
                              Loading or no data available
                            </Typography>
                          )
                        }
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            />
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={6}
                  sx={{ marginLeft: "auto", textAlign: "right" }}
                >
                  {/* Display subtotal based on reduced amounts */}
                  <Grid item xs={12}>
                    <Typography
                      sx={{ mt: 1, fontWeight: "bold", textAlign: "right" }}
                      variant="subtitle1"
                      component="h6"
                    >
                      Sub Total: ₹ {formik.values.subtotal}
                    </Typography>
                  </Grid>

                  {/* Display CGST */}
                  <Grid item xs={12}>
                    <Typography
                      sx={{ mt: 1, fontWeight: "bold", textAlign: "right" }}
                      variant="subtitle1"
                      component="h6"
                    >
                      CGST: ₹ {formik.values.sgst}
                    </Typography>
                  </Grid>

                  {/* Display SGST */}
                  <Grid item xs={12}>
                    <Typography
                      sx={{ mt: 1, fontWeight: "bold", textAlign: "right" }}
                      variant="subtitle1"
                      component="h6"
                    >
                      SGST: ₹ {formik.values.sgst}
                    </Typography>
                  </Grid>

                  {/* Calculate and display Net Amount */}
                  <Grid item xs={12}>
                    <Typography
                      sx={{ mt: 1, fontWeight: "bold", textAlign: "right" }}
                      variant="subtitle1"
                      component="h6"
                    >
                      Net Amount: ₹ {totalAmount || formik.values.netAmount}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                size="medium"
                className={classes.btn}
                // disabled={!isDirty || !formik.isValid}
              >
                <DoneOutlinedIcon />
                Submit
              </Button>
            </Box>
          </form>
        </FormikProvider>
      </Box>
    </Modal>
  );
};

export default Gstinvoice;
