import React from "react";
import { Modal, Box, Typography, Grid, IconButton, Chip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { IMG_URL } from "../../../../constants";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#f06666 !important",
  },
  icon: {
    fontSize: "17px !important",
    position: "relative",
    top: "3px",
    cursor: "pointer",
  },
  cardRadius: {
    borderRadius: "0 15px 15px 0 !important",
    borderLeft: "3px solid #f06666",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  maxHeight: "90vh",
  overflowY: "auto",
};

const AttachCopy = ({ data }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Chip label="Attachment" sx={{ mt: 1 }} onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-title"
        disableBackdropClick={true}
        BackdropProps={{
          onClick: (event) => {
            event.stopPropagation();
          },
        }}
      >
        <Box sx={{ ...style, width: { xs: "95%", sm: "50%" } }}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h6" component="h2" gutterBottom>
                Attachments
              </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justify="center" spacing={2}>
            <Grid item xs={12} sm={6} lg={4} align="center">
              <img
                src={`${IMG_URL}/${data}`}
                alt={data}
                style={{ width: "350px" }}
              />
            </Grid>
          </Grid>
          <Box></Box>
        </Box>
      </Modal>
    </>
  );
};

export default AttachCopy;
