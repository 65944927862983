import React from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SignIn from "../pages/signIn/index";
import ForgotPassword from "../pages/forgotPassword";
import Home from "../pages/home/index";
import Customer from "../pages/customer/index";
import Order from "../pages/order/index";
import Billing from "../pages/billing/index";
import Layout from "../layout/index";
import OrderPrint from "../pages/order/workorder/index";
import CustomerPrint from "../pages/order/customerbill/index";
import InvoicePrint from "../pages/order/invoiceprint/index";
import Settings from "../pages/settings/index";
import WhatsappPrint from "../pages/order/whatsappbill/index";
import Cookies from "js-cookie";

const App = () => {
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  const token = Cookies.get("token");
  const isTokenValid = !!token;
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <Navigate to="/home" /> : <SignIn />}
        />
        <Route path="/reset" element={<ForgotPassword />} />
        <Route
          path="/home"
          element={
            isAuthenticated && isTokenValid ? (
              <Layout>
                <Home />
              </Layout>
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/customer"
          element={
            isAuthenticated && isTokenValid ? (
              <Layout>
                <Customer />
              </Layout>
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/billing"
          element={
            isAuthenticated && isTokenValid ? (
              <Layout>
                <Billing />
              </Layout>
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/order"
          element={
            isAuthenticated && isTokenValid ? (
              <Layout>
                <Order />
              </Layout>
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/settings"
          element={
            isAuthenticated && isTokenValid ? (
              <Layout>
                <Settings />
              </Layout>
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route path="/orderprint/:orderId" element={<OrderPrint />} />
        <Route path="/customerprint/:orderId" element={<CustomerPrint />} />
        <Route path="/whatsappprint/:orderId" element={<WhatsappPrint />} />
        <Route path="/invoiceprint/:orderId" element={<InvoicePrint />} />
        <Route path="*" element={<div>404 Not Found</div>} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
