import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { TooltipProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: "17px !important",
    position: "relative",
    top: "3px",
    color: "#000",
  },
}));

const HtmlTooltip = styled((props: TooltipProps) => {
  const classes = useStyles();
  return (
    <Tooltip
      {...props}
      classes={{ popper: props.className, tooltip: classes.tooltip }}
      placement="top"
      arrow
    />
  );
})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 450,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

// Use the HtmlTooltip component within the same file
const Popup = ({ historyDetail }) => {
  const classes = useStyles();
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <Typography color="inherit">{historyDetail}</Typography>
        </React.Fragment>
      }
    >
      <InfoOutlinedIcon size="small" className={classes.tooltip} />
    </HtmlTooltip>
  );
};

export default Popup;
