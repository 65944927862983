import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Typography,
  Grid,
  Box,
  Divider,
  Button,
  Item,
} from "@mui/material";

import Logo from "../../../assets/img/logo1.png";
import QRCode from "../../../assets/img/qrcode.png";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { styled } from "@mui/system";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import { useSelector } from "react-redux";
import orderApi from "../../../services/order";

const PrintButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#f06666",
  marginBottom: theme.spacing(2),
  "@media print": {
    display: "none",
  },
  "&:hover": {
    backgroundColor: "#f06666",
    boxShadow: "none",
  },
}));
const CustomerPrint = () => {
  const [printData, setPrintData] = useState(null);
  const { orderId } = useParams();

  const getOrder = () => {
    const onSuccess = (res) => {
      setPrintData(res?.data?.data);
    };
    const onFailure = () => {};
    orderApi.getOrderCopy(orderId).then(onSuccess).catch(onFailure);
  };

  useEffect(() => {
    getOrder();
  }, [orderId]);

  useEffect(() => {
    // Cleanup function to clear sessionStorage on tab close
    const handleTabClose = (event) => {
      // Clear sessionStorage
      sessionStorage.removeItem("printData");
    };

    // Attach the event listener for beforeunload
    window.addEventListener("beforeunload", handleTabClose);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, [printData]);

  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    const dateObj = new Date(dateStr);
    const formattedDate = `${dateObj.getDate().toString().padStart(2, "0")}-${(
      dateObj.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${dateObj.getFullYear()}`;
    return formattedDate;
  };
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime;
  };

  const orderDate = new Date(printData?.order_date);
  const formattedTime = orderDate.toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  const particulars =
    printData && printData.particulars ? printData.particulars : [];

  const advance =
    printData && printData.payment_details ? printData.payment_details : [];

  const showTrue = advance?.length > 1;
  const navigate = useNavigate();

  const handlePrint = () => {
    window.print();
  };

  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <PrintButton
          variant="contained"
          size="medium"
          onClick={handlePrint}
          sx={{ display: "flex", alignItems: "center", mx: 1 }}
        >
          <LocalPrintshopOutlinedIcon sx={{ marginRight: 1 }} />
          Print
        </PrintButton>
      </Box>
      <Grid container>
        <Grid item sm={6}>
          <Typography variant="h6">Lakshitha The Designer Studio</Typography>
          <Typography sx={{ fontSize: "13px" }}>
            #38, BCB Tower, 2nd floor,
            <Typography sx={{ fontSize: "13px" }}>
              {" "}
              Spartan Avenue Mogappair East,
            </Typography>
          </Typography>
          <Typography sx={{ fontSize: "13px" }}>
            Chennai, TamilNadu 600037 (Next to I-Dent Building),
          </Typography>
          <Typography sx={{ fontSize: "13px" }}>
            Mobile: +91 8148-777-475
          </Typography>
          <Typography sx={{ fontSize: "13px" }}>
            E-Mail: lakshithadesignerstudio@gmail.com
          </Typography>
        </Grid>
        <Grid item sm={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <img
            src={Logo}
            alt="Logo"
            style={{
              width: "116px",
              marginRight: "20px",
              marginTop: "20px",
            }}
          />
        </Grid>
      </Grid>
      <Divider fullwidth sx={{}} />
      <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
        Order Copy
      </Typography>
      <Divider fullwidth sx={{}} />

      <Grid container spacing={2}>
        <Grid
          item
          xs={4}
          style={{
            borderRight: "1px solid #ccc",
            minHeight: "68px",
            padding: "8px",
            marginTop: "15px",
            position: "relative",
          }}
        >
          {" "}
          <Grid item sm={6} md={6} style={{}}>
            <Typography
              gutterBottom
              sx={{
                fontSize: "13px",
                marginLeft: "15px",
              }}
            >
              Name:
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              gutterBottom
              sx={{
                fontSize: "13px",
                position: "absolute",
                left: "25px",
              }}
            >
              <strong>
                {printData?.customer?.user?.name}
                {printData?.customer?.ref_name &&
                  `(${printData?.customer?.ref_name})`}
              </strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container>
            <Grid item sm={6}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography gutterBottom sx={{ fontSize: "13px" }}>
                    <strong> Order ID:</strong>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography sx={{ fontSize: "13px" }}>
                    {printData?.order_id}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography gutterBottom sx={{ fontSize: "13px" }}>
                    <strong> Customer Id:</strong>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography sx={{ fontSize: "13px" }}>
                    {printData?.customer?.cust_id}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography gutterBottom sx={{ fontSize: "13px" }}>
                    <strong>Mobile Number:</strong>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography sx={{ fontSize: "13px" }}>
                    {printData?.customer?.user?.mobile_number}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography gutterBottom sx={{ fontSize: "13px" }}>
                    <strong>Booking Date:</strong>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography sx={{ fontSize: "13px" }}>
                    {formatDate(printData?.order_date)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item sm={6}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography gutterBottom sx={{ fontSize: "13px" }}>
                    <strong> Taken Time:</strong>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography sx={{ fontSize: "13px" }}>
                    {formatTime(printData?.order_date)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid> */}

            <Grid item sm={6}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography gutterBottom sx={{ fontSize: "13px" }}>
                    <strong>1st Delivery Date:</strong>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography sx={{ fontSize: "13px" }}>
                    {printData?.is_second_delivery_date_req
                      ? formatDate(printData?.delivery_date)
                      : formatDate(
                          printData?.second_delivery_date ||
                            printData?.delivery_date
                        )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid container>
                <Grid item sm={6}>
                  <Typography gutterBottom sx={{ fontSize: "13px" }}>
                    <strong>2nd Delivery Date:</strong>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography sx={{ fontSize: "13px" }}>
                    {printData?.second_delivery_date
                      ? formatDate(printData.second_delivery_date)
                      : "NA"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider fullwidth sx={{}} />
      <TableContainer component={Paper} sx={{ mt: 1 }}>
        <Table sx={{ minWidth: 550 }}>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ py: 1 }}>
                S.No
              </TableCell>
              <TableCell align="center" sx={{ py: 1 }}>
                Particulars
              </TableCell>
              <TableCell align="center" sx={{ py: 1 }}>
                Qty
              </TableCell>
              <TableCell align="center" sx={{ py: 1 }}>
                Price
              </TableCell>
              <TableCell align="center" sx={{ py: 1 }}>
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {particulars.map((head, index) => (
              <TableRow key={index}>
                <TableCell sx={{ textAlign: "center", py: 0.5 }}>
                  {head.id}
                </TableCell>
                <TableCell sx={{ textAlign: "center", py: 0.5 }}>
                  {head.particular}
                </TableCell>
                <TableCell sx={{ textAlign: "center", py: 0.5 }}>
                  {head.qty}
                </TableCell>
                <TableCell sx={{ textAlign: "center", py: 0.5 }}>
                  {head.price}{" "}
                  {head.breakupDetails &&
                    head.breakupDetails.map((item, idx) => (
                      <React.Fragment key={idx}>
                        {idx > 0 && " + "} {item.price}
                      </React.Fragment>
                    ))}
                </TableCell>
                <TableCell sx={{ textAlign: "center", py: 0.5 }}>
                  {head.amount}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={6} sx={{ marginLeft: "auto" }}>
            <Grid item xs={12}>
              <Grid container>
                <Grid sm={6}>
                  <Typography>
                    <strong>Total:</strong>
                  </Typography>
                </Grid>
                <Grid sm={6}>
                  <Typography sx={{ textAlign: "right" }}>
                    {" "}
                    ₹{printData?.bill_amount}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid sm={6}>
                  {advance.some((head) => head?.advance !== 0) && (
                    <Typography>
                      <strong>Payment:</strong>
                    </Typography>
                  )}
                </Grid>
                <Grid sm={6}>
                  <Typography sx={{ textAlign: "right" }}>
                    {advance.some((head) => head?.amount !== 0) && (
                      <div>
                        ₹{" "}
                        {advance.reduce(
                          (sum, head) => sum + (head?.amount || 0),
                          0
                        )}
                      </div>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid sm={6}>
                  {advance.some((head) => head?.discount !== 0) && (
                    <Typography>
                      <strong>Discount:</strong>
                    </Typography>
                  )}
                </Grid>
                <Grid sm={6}>
                  <Typography sx={{ textAlign: "right" }}>
                    {advance.some((head) => head?.discount !== 0) && (
                      <div>
                        ₹{" "}
                        {advance.reduce(
                          (sum, head) => sum + (head?.discount || 0),
                          0
                        )}
                      </div>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid sm={6}>
                  <Typography>
                    <strong>Due Amount:</strong>
                  </Typography>
                </Grid>
                <Grid sm={6}>
                  <Typography sx={{ textAlign: "right" }}>
                    {" "}
                    ₹{printData?.due_amount}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider fullwidth />

      <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
        Payment Details
      </Typography>
      <Divider fullwidth />

      <Box
        sx={{
          border: "1px solid #a0a6a8",
          borderRadius: "5px",
          my: 1,
          overflow: "hidden",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            sx={{ textAlign: "center", borderRight: "1px solid #000" }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "14px", mt: 2 }}>
              BANK DETAILS
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>
              Lakshitha The Designer Studio
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>
              A/c : 923020043718087
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>
              IFSC : UTIB0000622
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>
              Branch : Old Washermenpet
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} lg={6} sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              SCAN TO PAY
            </Typography>
            <img
              src={QRCode}
              alt="QR Code"
              style={{ width: "100px", display: "block", margin: "0 auto" }}
            />
            <Typography
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "13px",
              }}
            >
              UPI ID: nishagoyal2007-2@okaxis
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          border: "1px solid #a0a6a8",
          px: 1,
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            sx={{ borderRight: "1px solid #000" }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
              Terms & Conditions
            </Typography>
            <Typography sx={{ mt: 1, fontWeight: "bold", fontSize: "11px" }}>
              1. No alteration will be made after 3 months of delivery.
            </Typography>
            <Typography sx={{ mt: 1, fontWeight: "bold", fontSize: "11px" }}>
              2. Booking order cancellation fee ₹ 300/
            </Typography>
            <Typography sx={{ mt: 1, fontWeight: "bold", fontSize: "11px" }}>
              3. NO CREDITS
            </Typography>
            <Typography sx={{ mt: 1, fontWeight: "bold", fontSize: "11px" }}>
              4. Deliveries only on Mon to Sat
            </Typography>
            <Typography sx={{ mt: 1, fontWeight: "bold", fontSize: "11px" }}>
              5. Delivery Timing : 5pm to 8pm
            </Typography>
            <Typography sx={{ mt: 1, fontWeight: "bold", fontSize: "11px" }}>
              6. SUNDAY HOLIDAY
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            lg={6}
            sx={{ textAlign: "right", marginTop: "auto" }}
          >
            <Typography sx={{ textAlign: "right" }}>
              Authorised Signatory
            </Typography>
            <Typography sx={{ textAlign: "right" }}>
              Lakshitha Designer Studio
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
        Thanks for your order, Welcome soon !
      </Typography>
    </div>
  );
};

export default CustomerPrint;
