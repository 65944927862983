import {
    HomeOutlined as HomeOutlinedIcon,
    HowToRegOutlinedIcon as HowToRegOutlined,
    SettingsOutlined as SettingsOutlinedIcon,
    AccountCircleOutlined as AccountCircleOutlinedIcon,
    SecurityOutlined as SecurityOutlinedIcon,
  } from '@mui/icons-material';
  import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
  import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
  import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
  import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
  import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
  
  const menuListArray = [
    {
      id: 0,
      title: "Home",
      icon: <HomeOutlinedIcon />,
      path: "/home",
      active: false,
    },
    {
      id: 1,
      title: "Customer",
      icon: <HowToRegOutlinedIcon />,
      path: "/customer",
      active: false,
    },
    {
      id: 2,
      title: "Order",
      icon: <EditNoteOutlinedIcon />,
      path: "/order",
    },
    {
      id: 3,
      title: "Reports",
      icon: <DescriptionOutlinedIcon />,
      path: "/billing",
      active: false,
    },
  ];
  
  export default menuListArray;
  